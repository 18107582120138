/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./size.component";
var styles_IconSize = [];
var RenderType_IconSize = i0.ɵcrt({ encapsulation: 2, styles: styles_IconSize, data: {} });
export { RenderType_IconSize as RenderType_IconSize };
export function View_IconSize_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, ":svg:svg", [[":xml:space", "preserve"], [":xmlns:a", "http://ns.adobe.com/AdobeSVGViewerExtensions/3.0/"], [":xmlns:xlink", "http://www.w3.org/1999/xlink"], ["height", "41px"], ["style", "enable-background:new 0 0 48 41;"], ["version", "1.1"], ["viewBox", "0 0 48 41"], ["width", "48px"], ["x", "0px"], ["xmlns", "http://www.w3.org/2000/svg"], ["y", "0px"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n"])), (_l()(), i0.ɵeld(2, 0, null, null, 5, ":svg:g", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t"])), (_l()(), i0.ɵeld(4, 0, null, null, 0, ":svg:path", [["class", "st0"], ["d", "M47,6H7V1c0-0.6-0.4-1-1-1C2.2,0,0,3.1,0,6v29c0,0,0,0,0,0c0,3.3,2.7,6,6,6h41c0.6,0,1-0.4,1-1V7\n\t\tC48,6.4,47.6,6,47,6z M2,6c0-1.7,1.1-3.4,3-3.9v27c-1.2,0.2-2.2,0.7-3,1.4V6z M46,39H6c-2.2,0-4-1.8-4-4c0-2,1.5-4,4-4\n\t\tc0.6,0,1-0.4,1-1V8h39V39z"], ["style", "fill:#75968A;"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t"])), (_l()(), i0.ɵeld(6, 0, null, null, 0, ":svg:path", [["class", "st0"], ["d", "M19,29v6h18V24h4V12H25h-1H12v17H19z M14,14h9v3h2v-3h14v8h-2h-1h-4v2h3v9H21v-4h4v-5h4v-2h-4v-2h-2v7h-9V14z"], ["style", "fill:#75968A;"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n"])), (_l()(), i0.ɵted(-1, null, ["\n"])), (_l()(), i0.ɵted(-1, null, ["\n"]))], null, null); }
export function View_IconSize_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "perch-icons-size", [], null, null, null, View_IconSize_0, RenderType_IconSize)), i0.ɵdid(1, 114688, null, 0, i1.IconSize, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var IconSizeNgFactory = i0.ɵccf("perch-icons-size", i1.IconSize, View_IconSize_Host_0, {}, {}, []);
export { IconSizeNgFactory as IconSizeNgFactory };
