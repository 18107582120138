import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'perch-icons-step-three',
  templateUrl: './step-three.component.html'
})
export class StepThreeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
