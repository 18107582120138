import { OfferType } from 'models';
var ValidThruComponent = /** @class */ (function () {
    function ValidThruComponent() {
    }
    Object.defineProperty(ValidThruComponent.prototype, "date", {
        get: function () {
            if (this.offer.type === OfferType.NET_OF_REPAIRS) {
                return this.offer.optionEndDate || this.offer.expirationDate;
            }
            else {
                return this.offer.expirationDate;
            }
        },
        enumerable: true,
        configurable: true
    });
    return ValidThruComponent;
}());
export { ValidThruComponent };
