import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';

import { Estimate } from 'models';

@Component({
  selector: 'app-estimate-not-found',
  templateUrl: './not-found.component.html'
})
export class EstimateNotFoundComponent implements OnInit {
  public estimate: Estimate;

  constructor(
    private titleService: Title,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.estimate = this.route.snapshot.data.estimate;
    this.estimate.token = this.route.snapshot.params.estimateId;
    this.titleService.setTitle('Orchard Estimate Not Found');
  }
}
