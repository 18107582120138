import { Component, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { filter, takeUntil, distinctUntilKeyChanged } from 'rxjs/operators';

import { OffersService } from '../../providers/offers.service';
import {Offer, OfferType} from 'models';

@Component({
  selector: 'app-offer',
  templateUrl: './offer.component.html',
  styleUrls: ['./offer.component.css']
})
export class OfferComponent implements OnInit, OnDestroy {
  constructor(
    private route: ActivatedRoute,
    private offersService: OffersService,
    private router: Router
  ) {}

  public sectionPath: string;
  public offer: Offer;
  public dataLoaded = false;
  private onDestroyEmitter = new EventEmitter<void>();

  OfferType = OfferType;

  ngOnInit(){
    this.initNavigationEndSubscription();
    this.initOfferIdParamSubscription();
    this.initSectionPathParamSubscription();
  }

  ngOnDestroy(): void {
    this.onDestroyEmitter.emit();
    this.onDestroyEmitter.complete();
  }

  private initNavigationEndSubscription(): void {
    this.router.events
      .pipe(
        filter((evt) => evt instanceof NavigationEnd),
        takeUntil(this.onDestroyEmitter)
      )
      .subscribe(() => window.scroll(0, 0));
  }

  private initOfferIdParamSubscription(): void {
    this.route.params.pipe(
      distinctUntilKeyChanged('offerId'),
      takeUntil(this.onDestroyEmitter)
    )
    .subscribe(({ offerId }) => this.handleOfferIdParamChange(offerId));
  }

  private initSectionPathParamSubscription(): void {
    this.route.params
      .pipe(
        distinctUntilKeyChanged('sectionPath'),
        takeUntil(this.onDestroyEmitter)
      )
      .subscribe(({ sectionPath }) => this.sectionPath = sectionPath);
  }

  private async handleOfferIdParamChange(token: string): Promise<void> {
    this.dataLoaded = false;
    try {
      const offerData = await this.offersService.getOfferData(token);
      this.offer = { ...offerData, token };
    }
    catch (_) {
      this.router.navigateByUrl('/404');
    }
    this.dataLoaded = true;

    if (this.offer.type === OfferType.WILLOW_OFFER) {
      this.router.navigate(['gsp-offer', token]);
      return;
    }

    if (this.offer.expired) {
      this.router.navigate(['offer/' + token + '/expired']);
    }
  }
}
