import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import * as get from 'lodash/fp/get';

import { HeapAnalytics, HeapCustomEvent } from './models';

@Injectable({ providedIn: 'root' })
export class HeapAnalyticsService {

  private heapAnalyticsRef: HeapAnalytics;
  private eventsQueue: HeapCustomEvent[] = [];

  private documentRef: Document;

  constructor(@Inject(DOCUMENT) documentRef: any) {
    this.documentRef = documentRef;
  }

  track(name: string, properties: Record<string, number | string>): void {
    this.ensureInitialized();

    if (this.heapAnalyticsRef != null) {
      this.heapAnalyticsRef.track(name, properties);
    } else {
      this.eventsQueue.push({ name, properties });
    }
  }

  private ensureInitialized(): void {
    if (this.intialized) {
      return;
    }
    this.setHeapAnalyticsRef();
    this.intialized = true;
  }
  // tslint:disable-next-line member-ordering
  private intialized = false;

  private setHeapAnalyticsRef(): void {
    const ref: HeapAnalytics | null = get('defaultView.heap', this.documentRef);
    if (ref == null) {
      setTimeout(() => this.setHeapAnalyticsRef(), 1000);
      return;
    }

    this.heapAnalyticsRef = ref;
    this.flushEventsQueue();
  }

  private flushEventsQueue(): void {
    this.eventsQueue.forEach(({ name, properties }) => this.heapAnalyticsRef.track(name, properties));
    this.eventsQueue = [];
  }
}
