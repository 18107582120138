<div class="estimate-wrapper">
  <a class="header">
    <img src="https://res.cloudinary.com/dmxgostd9/image/upload/web-assets/orchard-logo-dark.svg">
  </a>
  <div class="intro-copy">
    <div class="intro-copy__wrapper">
      <h1 class="text-center text-h3">
        <ng-container *ngIf="isListWithBackstop; else bnsHeader">
          Here's your initial home estimate. Next up, schedule your free home assessment to get your personalized list price recommendation.
        </ng-container>
        <ng-template #bnsHeader>
          Here's your initial home estimate. Next up, meet with an Orchard Home Advisor to get your personalized list price recommendation.
        </ng-template>
      </h1>
    </div>
  </div>

  <div class="truck-route">
    <app-estimate-progress-bar [isListWithBackstop]="isListWithBackstop"></app-estimate-progress-bar>
  </div>

  <div class="estimate-details d-flex flex-column align-items-center">
    <h2 class="text-h4 initial-copy">Our initial list price recommendation:</h2>
    <h1 class="text-h3 estimate-range d-flex align-items-center">
      <span id="estimate-min">${{estimate.min | number}}</span>
      <span class="estimate-separator"></span>
      <span id="estimate-max">${{estimate.max | number}}</span>
    </h1>

    <h2 class="text-body-lg home-valuation">This range is based on an initial Orchard home valuation of ${{estimate.amount | number}}.</h2>

    <div class="valuation-faq" [class.expanded]="showValuationFaq">
      <span class="valuation-question-wrapper" (click)="toggleValuationFaq()">
        <a class="btn btn-link valuation-question text-body">
          How did you create this valuation?
        </a>
      </span>
      <p class="valuation-answer text-body-sm">
        Our valuation is 40% more accurate than other real estate websites. How? First, we account for each detail you shared about your
        home's quality and features. And, because we only offer our services in a select few markets, we've become experts in pricing homes
        in these cities. For now, this estimate only covers what you told us online. Schedule your consultation so we can give you an even
        more accurate assessment for free.
      </p>
    </div>
  </div>

  <div class="schedule-details d-flex flex-column align-items-center">
    <span class="text-label">Next up</span>
    <p class="details-copy text-body">
      <ng-container *ngIf="isListWithBackstop; else bnsNextUp">
        Chat with our team to schedule your assessment. We can also answer any questions about your estimate or working with Orchard.
      </ng-container>
      <ng-template #bnsNextUp>
        Chat with our team to schedule your consultation. We can also answer any questions about your estimate or working with Orchard.
      </ng-template>
    </p>
    <div>
      <a class="home-visit-cta btn btn-sm btn-primary cursor-pointer" *ngIf="olarkService.isLoaded$ | async; else fallbackCTA" (click)="sendMessage()" target="_blank">
        {{scheduleCtaText}}
      </a>
      <ng-template #fallbackCTA>
        <a class="home-visit-cta btn btn-sm btn-primary" [href]="calendlyLink" target="_blank">
          {{scheduleCtaText}}
        </a>
      </ng-template>
    </div>
    <div class="alt-instructions text-body-sm">Or give us a call <a href="tel:8444800931" class="tel-link">844-480-0931</a> <span> (Mon-Fri 9am to 5pm)</span></div>
  </div>
</div>

<div class="orchard-offering">
  <h2 class="orchard-offering__subhead text-h4">
    Orchard gives you:
  </h2>
  <ul class="orchard-offering__list">
    <ng-container *ngIf="isListWithBackstop; else bnsValueProps">
      <li class="orchard-offering__bullet">
        <i class="material-icons orchard-offering__checkmark">done</i>
        <span class="text-body">Personalized attention from a top real estate agent</span>
      </li>
      <li class="orchard-offering__bullet">
        <i class="material-icons orchard-offering__checkmark">done</i>
        <span class="text-body">Flexibility to list your home or sell directly for cash</span>
      </li>
      <li class="orchard-offering__bullet">
        <i class="material-icons orchard-offering__checkmark">done</i>
        <span class="text-body">Free cleaning, home photos, and a 3D tour </span>
      </li>
      <li class="orchard-offering__bullet">
        <i class="material-icons orchard-offering__checkmark">done</i>
        <span class="text-body">Real-time feedback and insights for your listing</span>
      </li>
    </ng-container>
    <ng-template #bnsValueProps>
      <li class="orchard-offering__bullet">
        <i class="material-icons orchard-offering__checkmark">done</i>
        <span class="text-body">Personalized attention from a top real estate agent</span>
      </li>
      <li class="orchard-offering__bullet">
        <i class="material-icons orchard-offering__checkmark">done</i>
        <span class="text-body">Flexibility to buy your next home before you sell</span>
      </li>
      <li class="orchard-offering__bullet">
        <i class="material-icons orchard-offering__checkmark">done</i>
        <span class="text-body">Peace of mind of a guaranteed sale in {{ estimate.listingPeriodInterval }} days</span>
      </li>
      <li class="orchard-offering__bullet">
        <i class="material-icons orchard-offering__checkmark">done</i>
        <span class="text-body">Ability to list after you move and avoid home showings</span>
      </li>
    </ng-template>
  </ul>
</div>

<div class="divider d-none d-lg-block"></div>

<!--
  WHAT HAPPENS NEXT ????
 -->
<section class="what-happens-next">

  <h2 class="what-happens-next__subhead text-h4">
    What happens next?
  </h2>


  <!-- MEET YOUR AGENT -->
  <div class="what-happens-next__item">
    <div class="what-happens-next__item-section">
      <div class="what-happens-next__copy">
        <div class="what-happens-next__numeral sharp-grotesk-bold">
          <span class="text-body-lg">1</span>
        </div>
        <ng-container *ngIf="isListWithBackstop; else bnsStepOne">
          <p class="what-happens-next__heading text-body-lg font-bold">
            Schedule a free home assessment
          </p>
          <p class="what-happens-next__body text-body">
            We’ll take a closer look at your home to finalize our list price recommendation and make you an optional backup offer for your home.
          </p>
        </ng-container>
        <ng-template #bnsStepOne>
          <p class="what-happens-next__heading text-body-lg font-bold">
            Meet your Orchard Home Advisor
          </p>
          <p class="what-happens-next__body text-body">
            We'll match you with one of our experienced local Home Advisors from your area to discuss your buying and selling plan and finalize a list price for your home.
          </p>
        </ng-template>
      </div>
    </div>
    <div class="what-happens-next__item-section what-happens-next__image-wrapper">
      <img
        class="what-happens-next__image"
        src="https://res.cloudinary.com/dmxgostd9/image/upload/v1580485283/web-assets/offer-pack/orchard-agents.png"
      >
    </div>
  </div>


  <!-- BACKUP CASH OFFER -->
  <div class="what-happens-next__item-wrapper">
    <div class="what-happens-next__item what-happens-next__item--reversed">
      <div class="what-happens-next__item-section what-happens-next__image-wrapper">
        <img
          class="what-happens-next__image"
          src="https://res.cloudinary.com/dmxgostd9/image/upload/v1580486369/web-assets/offer-pack/cash-suitcase.png"
        >
      </div>
      <div class="what-happens-next__item-section">
        <div class="what-happens-next__copy">
          <div class="what-happens-next__numeral sharp-grotesk-bold">
            <span class="text-body-lg">2</span>
          </div>
          <ng-container *ngIf="isListWithBackstop; else bnsStepTwo">
            <p class="what-happens-next__heading text-body-lg font-bold">
              Get your recommended list price and guaranteed offer
            </p>
            <p class="what-happens-next__body text-body">
              Meet with your listing agent virtually to review our program and understand your options to sell on-market or directly to Orchard.
            </p>
          </ng-container>
          <ng-template #bnsStepTwo>
            <p class="what-happens-next__heading text-body-lg font-bold">
              Get your guaranteed offer
            </p>
            <p class="what-happens-next__body text-body">
              After your free home assessment, we’ll share the amount Orchard will help you unlock to buy your next home before you list.
            </p>
          </ng-template>
        </div>
      </div>
    </div>
  </div>

  <!-- START HOME SHOPPING -->
  <div class="what-happens-next__item">
    <div class="what-happens-next__item-section">
      <div class="what-happens-next__copy">
        <div class="what-happens-next__numeral sharp-grotesk-bold">
          <span class="text-body-lg">3</span>
        </div>
        <ng-container *ngIf="isListWithBackstop; else bnsHomeShopping">
          <p class="what-happens-next__heading text-body-lg font-bold">
            Sell your way
          </p>
          <p class="what-happens-next__body text-body">
            We’ll clean, photograph, list, and market your home to get you top dollar. But, you also have the option to sell to Orchard for added peace of mind.
          </p>
        </ng-container>
        <ng-template #bnsHomeShopping>
          <p class="what-happens-next__heading text-body-lg font-bold">
            Start home shopping
          </p>
          <p class="what-happens-next__body text-body">
            When you're ready, we can start touring homes with you on your schedule to find the perfect one for you.
          </p>
        </ng-template>
      </div>
    </div>
    <div class="what-happens-next__item-section what-happens-next__image-wrapper">
      <img *ngIf="isListWithBackstop; else homeShoppingImg"
        class="what-happens-next__image"
        src="https://res.cloudinary.com/dmxgostd9/image/upload/v1598997783/web-assets/offer-pack/list-home.png"
      >
      <ng-template #homeShoppingImg>
        <img
          class="what-happens-next__image"
          src="https://res.cloudinary.com/dmxgostd9/image/upload/v1580825773/web-assets/offer-pack/home-shopping.png"
      >
      </ng-template>
    </div>
  </div>

</section>
<div class="d-flex justify-content-center mt-4 mb-lg-5">
<a class="free-btn home-visit-cta btn btn-sm btn-primary cursor-pointer" *ngIf="olarkService.isLoaded$ | async; else fallbackCTA" (click)="sendMessage()" target="_blank">{{scheduleCtaText}}</a>
  <ng-template #fallbackCTA>
    <a class="free-btn home-visit-cta btn btn-sm btn-primary" [href]="calendlyLink" target="_blank">{{scheduleCtaText}}</a>
  </ng-template>
</div>

<div class="step estimate-step-2 pb-0" *ngIf="estimate">

  <section class="section faq">
    <div class="constrained">
      <div class="headline">
        <h1 class="text-h3">Frequently Asked Questions</h1>
      </div>

      <accordion class="text-body">
        <accordion-group heading="Why is my estimate a range?">
          <p class="text-body">
            <ng-container *ngIf="isListWithBackstop; else bnsFaqEstimate">
              Our initial list price recommendation is our best estimate based on the listing price of homes like yours in your area. While this gives us a good starting point of your home’s value, there’s no substitute for having an expert real estate team assess your home in-person to see what makes it unique. Once we see your home, we’ll schedule a virtual consultation to go over your recommended list price and backup cash offer.
            </ng-container>
            <ng-template #bnsFaqEstimate>
              Our initial list price recommendation is our best estimate based on the listing price of homes like yours in your area. While this gives us a good starting point of your home's value, there's no substitute for having an expert real estate agent tour your home in-person to see what makes it unique. Once we see your home, an Orchard Home Advisor, one of our licensed agents, can give you a final recommendation.
            </ng-template>
            
          </p>
        </accordion-group>
        <ng-container *ngIf="!isListWithBackstop">
          <div class="faq-separator"></div>
          <accordion-group heading="What are the benefits of buying with Orchard?">
            <p class="text-body">
              Orchard lets you shop for new homes and make a cash offer as soon as you find the one you love. No need to wait to sell your current home or make a contingent offer. In a hot market, all-cash offers stand out, giving you an edge on other buyers. Orchard’s Home Advisors will work with you on your schedule to find a home that meets your needs. They can show you any home on the market.
            </p>
          </accordion-group>
        </ng-container>
        <div class="faq-separator"></div>
        <accordion-group heading="What are the benefits of listing with Orchard?">
          <p class="text-body">
            When you list with Orchard, you’ll work closely with a licensed Orchard agent from your area. Our team aggressively markets your home both online and offline to help you find a buyer within your {{ estimate.listingPeriodInterval }}-day listing period.
          </p>
        </accordion-group>
        <div class="faq-separator"></div>
        <accordion-group heading="Do I need to sign anything yet?">
          <ng-container *ngIf="isListWithBackstop; else bnsFaqSign">
            Not yet! After you complete your home assessment, our team will schedule your virtual consultation, present your recommended list price and backup cash offer, and answer any questions you may have. If you’re ready to move forward, we’ll send you the documents to look over to work with us.
          </ng-container>
          <ng-template #bnsFaqSign>
            <p class="text-body">
              Not yet! After you receive your recommended list price and guaranteed offer amount, your Orchard Home Advisor can answer any questions. If you're ready to move forward, we'll send you the documents to look over to work with us.
            </p>
          </ng-template>
          
        </accordion-group>
      </accordion>

      <!-- FAQ CTA -->
      <div class="faq-cta text-center mb-2 mt-5">
        <a
          onclick="olark('api.box.expand')"
          class="btn-link btn-faq-cta text-body font-bold"
        >
          Have another question?
        </a>
      </div>

    </div>
  </section>


  <section class="review">
    <div class="review__wrapper">

      <img
        src="https://res.cloudinary.com/dmxgostd9/image/upload/v1580487913/web-assets/offer-pack/stars.png"
        class="review__stars"
      >
      <ng-container *ngIf="isListWithBackstop; else bnsReview">
        <h3 class="review__heading">Simply stellar!</h3>
        <p class="review__copy text-body">
          My home was on the market for less than a week, and <br> immediately had several offers. My Orchard Agent was also a shrewd negotiator, and saved me from having to invest in a lengthy list of repair demands at closing. I couldn't be any happier!
        </p>
        <p class="review__attribution text-body">
          Aimee G.
        </p>
      </ng-container>
      <ng-template #bnsReview>
        <h3 class="review__heading">Stress-free, fair and simple</h3>
        <p class="review__copy text-body">
          Orchard gave us the ability to choose the best option for our family. We didn’t have to settle for a home we didn’t love because our previous home was sold and we were in a rush. It took a lot of the stress out of the buying/selling process.
        </p>
        <p class="review__attribution text-body">
          Jennifer D.
        </p>
      </ng-template>
    </div>
  </section>



  <section class="section ready-to-continue">
    <div class="constrained">
      <div class="headline">
        <h1 class="text-h3">Ready to continue?</h1>
      </div>
      <p class="text-body">
        <ng-container *ngIf="isListWithBackstop; else bnsContinue">
          Click here to request an assessment and we’ll coordinate the rest
        </ng-container>
        <ng-template #bnsContinue>
          Click here to request a consultation and we’ll coordinate the rest
        </ng-template>
      </p>
      <div class="home-visit-cta-container">
        <a class="home-visit-cta btn btn-sm btn-primary cursor-pointer" *ngIf="olarkService.isLoaded$ | async; else fallbackCTA" (click)="sendMessage()" target="_blank">{{scheduleCtaText}}</a>
          <ng-template #fallbackCTA>
            <a class="home-visit-cta btn btn-sm btn-primary" [href]="calendlyLink" target="_blank">{{scheduleCtaText}}</a>
          </ng-template>
      </div>
      <!-- <p class="mt-4">
        Or you can email us at
        <br/>
        or call us at
      </p> -->
    </div>
  </section>

  <section class="contact-info">
    <a
      href="mailto:support@orchard.com"
      class="contact-info__link text-body"
    >support@orchard.com</a>
    <span class="contact-info__separator">|</span>
    <a
      href="tel:18448191373"
      class="contact-info__link text-body"
    >(844) 819-1373</a>
  </section>


  <willow-disclaimer class="transparent">
    <div class="content-block">
      <h5 class="stat-label text-label">Data Sources</h5>
      <p class="text-body-xs">
        Orchard leverages up-to-date data from county records and local MLS'. We combine
        this data with Orchard's proprietary valuation model to provide you with a comprehensive, simple
        and accurate estimate. For questions, please contact Orchard at <a href="mailto:support@orchard.com">support@orchard.com</a>.
      </p>
    </div>
    <div class="content-block">
      <h5 class="stat-label text-label">Disclaimer</h5>
      <p class="text-body-xs">
        Orchard certifies that, to the best of its knowledge, the statements of fact
        contained in this report are true and correct. Orchard is not acting as a real estate
        agent or broker, Orchard is only acting as a prospective purchaser of real estate.
      </p>
      <p class="text-body-xs" *ngIf="isTexas">
        Texas real estate law requires agents provide the
        <a target="_blank" href="https://assets.orchard.com/files/disclosures/trec/CN.pdf">
          TREC Consumer Protection Notice
        </a>
        and
        <a target="_blank" href="https://assets.orchard.com/files/disclosures/trec/IABS.pdf">
          Information About Brokerage Services
        </a>
        to all consumers.
      </p>
      <p class="text-body-xs">
        Orchard is the consumer brand of Orchard Property I.
      </p>
    </div>
  </willow-disclaimer>
</div>
