import { BehaviorSubject, timer as timer$ } from 'rxjs';
import { OlarkCmd } from './models';
import { filter, take, takeUntil } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
var OlarkService = /** @class */ (function () {
    function OlarkService(documentRef) {
        this.isLoaded$ = new BehaviorSubject(false);
        this.documentRef = documentRef;
        this.initialize();
    }
    OlarkService.prototype.expandChatbox = function () {
        if (this.olark == null) {
            throw new Error('expandChatbox called before olark loaded');
        }
        this.olark(OlarkCmd.EXPAND_CHATBOX);
    };
    OlarkService.prototype.sendMessageToUser = function (body) {
        if (this.olark == null) {
            throw new Error('sendMessageToUser called before olark loaded');
        }
        this.olark(OlarkCmd.SEND_MESSAGE_TO_VISITOR, { body: body });
    };
    OlarkService.prototype.initialize = function () {
        var _this = this;
        timer$(0, 1000)
            .pipe(take(15), // poll for 15s
        takeUntil(this.isLoaded$.pipe(filter(function (val) { return val; }))), // stop polling once loaded
        filter(function () {
            return _this.documentRef.defaultView != null
                && _this.documentRef.defaultView['olark'] != null;
        }))
            .subscribe(function () {
            _this.olark = _this.documentRef.defaultView['olark'];
            _this.isLoaded$.next(true);
        });
    };
    OlarkService.ngInjectableDef = i0.defineInjectable({ factory: function OlarkService_Factory() { return new OlarkService(i0.inject(i1.DOCUMENT)); }, token: OlarkService, providedIn: "root" });
    return OlarkService;
}());
export { OlarkService };
