import { Component, Input, OnInit } from '@angular/core';

import { OffersService } from "../../../providers/offers.service";
import { Offer } from 'models';

@Component({
  selector: 'app-offer-expired',
  templateUrl: './expired.component.html'
})
export class ExpiredComponent implements OnInit {

  @Input()
  public offer: Offer;

  public extensionRequested: boolean = false;

  constructor(
    private offersService: OffersService
  ) { }

  ngOnInit(){
    if(this.offer.extensionRequested){
      this.extensionRequested = true;
    }
  }

  resendOfferRequest(){
    this.offersService.sendNewOfferRequest(this.offer.token).then(() => {
      this.extensionRequested = true;
    })
  }

}
