<svg width="48px" height="48px" viewBox="0 0 48 48" version="1.1" xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink">
  <defs>
    <polygon id="checkmark-path-1" points="0 1.23708333 47.7526 1.23708333 47.7526 48.6106944 0 48.6106944"></polygon>
  </defs>
  <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <g id="Artboard-Copy-12" transform="translate(-207.000000, -1347.000000)">
      <g id="Page-1" transform="translate(207.000000, 1346.000000)">
        <path
          d="M22.28646,34.3054167 C21.99386,34.3054167 21.71526,34.1790278 21.52346,33.9581944 L13.84866,25.1359722 C13.48466,24.7193056 13.53086,24.0873611 13.95226,23.72625 C14.37646,23.3665278 15.01066,23.4123611 15.37466,23.8304167 L22.17586,31.6470833 L32.29786,16.30125 C32.60306,15.83875 33.22326,15.7054167 33.69366,16.0109722 C34.15846,16.31375 34.29006,16.9331944 33.98486,17.3956944 L23.13066,33.8526389 C22.95706,34.1151389 22.66866,34.2818056 22.35366,34.3026389 C22.33126,34.3040278 22.30886,34.3054167 22.28646,34.3054167"
          id="Fill-1" fill="#75968A"></path>
        <g id="Group-5" transform="translate(0.000000, 0.152639)">
          <mask id="checkmark-mask-2" fill="white">
            <use xlink:href="#checkmark-path-1"></use>
          </mask>
          <g id="Clip-4"></g>
          <path
            d="M23.87658,3.23708333 C11.82258,3.23708333 2.01558,12.9648611 2.01558,24.9231944 C2.01558,36.8829167 11.82258,46.6106944 23.87658,46.6106944 C35.93058,46.6106944 45.73758,36.8829167 45.73758,24.9231944 C45.73758,12.9648611 35.93058,3.23708333 23.87658,3.23708333 M23.87658,48.6106944 C10.71098,48.6106944 -0.00042,37.9856944 -0.00042,24.9231944 C-0.00042,11.8620833 10.71098,1.23708333 23.87658,1.23708333 C37.04218,1.23708333 47.75358,11.8620833 47.75358,24.9231944 C47.75358,37.9856944 37.04218,48.6106944 23.87658,48.6106944"
            id="Fill-3" fill="#75968A" mask="url(#checkmark-mask-2)"></path>
        </g>
      </g>
    </g>
  </g>
</svg>
