/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./popover-info.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "ngx-bootstrap/popover";
import * as i4 from "ngx-bootstrap/component-loader";
import * as i5 from "./popover-info.component";
var styles_InfoPopoverComponent = [i0.styles];
var RenderType_InfoPopoverComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_InfoPopoverComponent, data: {} });
export { RenderType_InfoPopoverComponent as RenderType_InfoPopoverComponent };
function View_InfoPopoverComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, ["\n  "])), i1.ɵncd(null, 0), (_l()(), i1.ɵted(-1, null, ["\n"]))], null, null); }
function View_InfoPopoverComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(0, null, null, 0))], null, null); }
function View_InfoPopoverComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "span", [["class", "pr-1"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InfoPopoverComponent_3)), i1.ɵdid(3, 540672, null, 0, i2.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null), (_l()(), i1.ɵted(-1, null, ["\n  "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.label.template; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_InfoPopoverComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(0, [["template", 2]], null, 0, null, View_InfoPopoverComponent_1)), (_l()(), i1.ɵted(-1, null, ["\n\n"])), (_l()(), i1.ɵeld(2, 16777216, null, null, 8, "div", [["class", "popover-info js-tooltip-hover-target"], ["container", "body"], ["trigger", "focus"]], null, null, null, null, null)), i1.ɵdid(3, 212992, null, 0, i3.PopoverDirective, [i1.ElementRef, i1.Renderer2, i1.ViewContainerRef, i3.PopoverConfig, i4.ComponentLoaderFactory], { popover: [0, "popover"], placement: [1, "placement"], outsideClick: [2, "outsideClick"], container: [3, "container"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\n  "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InfoPopoverComponent_2)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\n  "])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "span", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["?"])), (_l()(), i1.ɵted(-1, null, ["\n\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 0); var currVal_1 = _co.placement; var currVal_2 = true; var currVal_3 = "body"; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_4 = (_co.label != null); _ck(_v, 6, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_5 = i1.ɵinlineInterpolate(2, "icon icon-bg-", _co.iconBgColor, " icon-size-", _co.iconSize, ""); _ck(_v, 8, 0, currVal_5); }); }
export function View_InfoPopoverComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "popover-info", [], null, null, null, View_InfoPopoverComponent_0, RenderType_InfoPopoverComponent)), i1.ɵdid(1, 49152, null, 1, i5.InfoPopoverComponent, [], null, null), i1.ɵqud(335544320, 1, { label: 0 })], null, null); }
var InfoPopoverComponentNgFactory = i1.ɵccf("popover-info", i5.InfoPopoverComponent, View_InfoPopoverComponent_Host_0, { placement: "placement", iconBgColor: "iconBgColor", iconSize: "iconSize" }, {}, ["*"]);
export { InfoPopoverComponentNgFactory as InfoPopoverComponentNgFactory };
