var BathroomDetailsComponent = /** @class */ (function () {
    function BathroomDetailsComponent() {
        this.fallback = '–';
    }
    Object.defineProperty(BathroomDetailsComponent.prototype, "showFallback", {
        get: function () {
            return !this.half && !this.full;
        },
        enumerable: true,
        configurable: true
    });
    return BathroomDetailsComponent;
}());
export { BathroomDetailsComponent };
