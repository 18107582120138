import { Component, Input, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { HttpParams } from '@angular/common/http';

import { OlarkService } from 'app/providers';
import { Estimate } from 'models';


@Component({
  selector: 'app-estimate-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss']
})
export class EstimateDetailsComponent implements OnInit {

  @Input()
  estimate: Estimate;

  public calendlyLink: string;

  constructor(private titleService: Title) { }

  ngOnInit() {
    this.calendlyLink = this.generateCalendlyLink();
    this.titleService.setTitle(`Orchard Estimate -
      ${this.estimate.subjectProperty.address.streetAddress}
      ${this.estimate.subjectProperty.address.city},
      ${this.estimate.subjectProperty.address.state},
      ${this.estimate.subjectProperty.address.zipcode}`);
  }

  private generateCalendlyLink(): string {
    const address = this.estimate.subjectProperty.address;
    const phoneNumber = this.estimate.contact.phoneNumber ? this.estimate.contact.phoneNumber : undefined;
    const httpParams = new HttpParams({
      fromObject: this.estimate.contact == null ? {} : {
        name: this.estimate.contact.firstName + ' ' + this.estimate.contact.lastName,
        email: this.estimate.contact.email,
        a1: phoneNumber,
        a2: `${address.streetAddress}, ${address.city}, ${address.state} ${address.zipcode}`
      }
    });
    return `https://calendly.com/orchard-cx/orchard-consultation-cx?${httpParams.toString()}`;
  }

  get isTexas(): boolean {
    return this.estimate.subjectProperty.address.state === 'TX';
  }
}
