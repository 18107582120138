import { Component, Input } from '@angular/core';

type Step = 1 | 2 | 3 | 4;

@Component({
  selector: 'app-step-icon',
  templateUrl: './step-icon.template.html'
})
export class StepIconComponent {

  @Input()
  step: Step;

}
