<svg version="1.1"
	 xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:a="http://ns.adobe.com/AdobeSVGViewerExtensions/3.0/"
	 x="0px" y="0px" width="48px" height="41.2px" viewBox="0 0 48 41.2" style="enable-background:new 0 0 48 41.2;"
	 xml:space="preserve">
<g>
	<path class="st0" style="fill:#75968A;" d="M45,21V5c0-2.8-2.2-5-5-5h-1c-2.8,0-5,2.2-5,5v1h-1v2h4V6h-1V5c0-1.7,1.3-3,3-3h1c1.7,0,3,1.3,3,3v16H22v-3H10
		v3H0l0,3.8L0.7,25c0,0,2.3,0.7,2.3,2v5c0,2.9,2,5.3,4.8,5.9L7,40.8L9,41.2L9.8,38h28.4l0.8,3.2l1.9-0.5l-0.7-2.9
		c2.7-0.6,4.8-3,4.8-5.9v-5c0-1.4,2.2-2,2.3-2l0.7-0.2V21H45z M12,20h8v10h-8V20z M46,23.3c-1.4,0.6-3,1.8-3,3.7v5c0,2.2-1.8,4-4,4
		H9c-2.2,0-4-1.8-4-4v-5c0-1.9-1.6-3.1-3-3.7V23h8v9h12v-9h24V23.3z"/>
	<rect x="36" y="10" class="st0" style="fill:#75968A;" width="2" height="3"/>
	<rect x="32" y="10" class="st0" style="fill:#75968A;" width="2" height="3"/>
	<rect x="34" y="15" class="st0" style="fill:#75968A;" width="2" height="3"/>
	<rect x="30" y="15" class="st0" style="fill:#75968A;" width="2" height="3"/>
	<rect x="38" y="15" class="st0" style="fill:#75968A;" width="2" height="3"/>
</g>
</svg>
