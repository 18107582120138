import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { Baths } from './baths/baths.component';
import { IconBedrooms } from './bedrooms/bedrooms.component';
import { IconCalendar } from './calendar/calendar.component';
import { IconCheckmark } from './checkmark/checkmark.component';
import { IconLetterDotted } from './letter-dotted/letter-dotted';
import { IconLetter } from './letter/letter.component';
import { IconSize } from './size/size.component';
import { StepIconComponent } from './step';
import { StepFourComponent } from './step-four/step-four.component';
import { StepOneComponent } from './step-one/step-one.component';
import { StepThreeComponent } from './step-three/step-three.component';
import { StepTwoComponent } from './step-two/step-two.component';
import { IconYear } from './year/year.component';

@NgModule({
  declarations: [
    StepIconComponent,
    StepOneComponent,
    StepTwoComponent,
    StepThreeComponent,
    StepFourComponent,
    Baths,
    IconBedrooms,
    IconSize,
    IconYear,
    IconLetter,
    IconCheckmark,
    IconCalendar,
    IconLetterDotted
  ],
  imports: [
    CommonModule
  ],
  exports: [
    StepIconComponent,
    StepOneComponent,
    StepTwoComponent,
    StepThreeComponent,
    StepFourComponent,
    Baths,
    IconBedrooms,
    IconSize,
    IconYear,
    IconLetter,
    IconCheckmark,
    IconCalendar,
    IconLetterDotted
  ],
  providers: [],
})
export class PerchIconsModule {}
