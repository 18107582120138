import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'perch-icons-step-two',
  templateUrl: './step-two.component.html'
})
export class StepTwoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
