import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Configuration } from 'models';
import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class ConfigService {

  private config: Configuration;

  constructor(private http: HttpClient) {}

  load() {
    return new Promise((resolve) => {
      this.http.get(environment.configFile)
        .subscribe(config => {
          this.config = <Configuration> config;
          resolve(config);
        });
    });
  }

  getConfiguration(): Configuration {
    return this.config;
  }
}
