import { Component, Injectable, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { Offer, ServiceName } from 'models';

@Injectable()
export class BaseWillowOfferComponent implements OnInit {
  offer: Offer;
  ServiceName = ServiceName;

  constructor(
    private titleService: Title,
    route: ActivatedRoute
  ) {
    this.offer = route.snapshot.data.offer;
  }

  ngOnInit() {
    this.titleService.setTitle(`Orchard Offer -
      ${this.offer.subjectProperty.address.streetAddress}
      ${this.offer.subjectProperty.address.city},
      ${this.offer.subjectProperty.address.state},
      ${this.offer.subjectProperty.address.zipcode}`);
  }

  get isTexas(): boolean {
    return this.offer.subjectProperty.address.state === 'TX';
  }
}

@Component({
  templateUrl: './cover-sheet.html'
})
export class GspOfferCoverSheetComponent extends BaseWillowOfferComponent {
}

@Component({
  templateUrl: './gsp-offer.html',
  styleUrls: ['./gsp-offer.scss']
})
export class GspOfferComponent extends BaseWillowOfferComponent {
}

@Component({
  templateUrl: './gsp-offer-not-found.html',
})
export class GspOfferNotFoundComponent extends BaseWillowOfferComponent {

}
