<svg width="72px" height="72px" viewBox="0 0 72 72" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
  <defs>
    <polygon id="path-1" points="0 0.5936 48.05206 0.5936 48.05206 47.59818 0 47.59818"></polygon>
    <polygon id="path-3" points="0.000891836936 0.13433 48.0522 0.13433 48.0522 21 0.000891836936 21"></polygon>
  </defs>
  <g id="step-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <g id="Page-1" transform="translate(12.000000, 12.000000)">
      <g id="Group-3" transform="translate(0.000000, 0.807100)">
        <mask id="mask-2" fill="white">
          <use xlink:href="#path-1"></use>
        </mask>
        <g id="Clip-2"></g>
        <path d="M2.01726,45.58358 L46.03466,45.58358 L46.03466,18.97938 L24.02666,2.85138 L2.01726,18.97938 L2.01726,45.58358 Z M47.04406,47.59818 L1.00786,47.59818 C0.45206,47.59818 -0.00014,47.14738 -0.00014,46.59158 L-0.00014,18.46838 C-0.00014,18.14638 0.15246,17.84538 0.41286,17.65498 L23.43026,0.78778 C23.78586,0.52738 24.26746,0.52738 24.62166,0.78778 L47.64046,17.65498 C47.89806,17.84538 48.05206,18.14638 48.05206,18.46838 L48.05206,46.59158 C48.05206,47.14738 47.59986,47.59818 47.04406,47.59818 Z"
          id="Fill-1" fill="#75968A" mask="url(#mask-2)"></path>
      </g>
      <g id="Group-6" transform="translate(0.000000, 27.407100)">
        <mask id="mask-4" fill="white">
          <use xlink:href="#path-3"></use>
        </mask>
        <g id="Clip-5"></g>
        <path d="M47.04294,20.99888 C46.81894,20.99888 46.59354,20.92468 46.40454,20.77068 L24.02694,2.44608 L1.64654,20.77068 C1.21674,21.12628 0.58114,21.06048 0.22974,20.62928 C-0.12306,20.19948 -0.06146,19.56528 0.36974,19.21248 L23.38854,0.36428 C23.75814,0.05768 24.29434,0.05768 24.66534,0.36428 L47.68134,19.21248 C48.11394,19.56528 48.17694,20.19948 47.82414,20.62928 C47.62394,20.87288 47.33414,20.99888 47.04294,20.99888"
          id="Fill-4" fill="#75968A" mask="url(#mask-4)"></path>
      </g>
      <path d="M18.23766,33.65264 C18.02766,33.65264 17.81206,33.58684 17.63006,33.44824 L0.88326,20.76004 C0.43946,20.42404 0.35266,19.79124 0.68866,19.34744 C1.02466,18.90364 1.65606,18.81404 2.10266,19.15284 L18.84806,31.84104 C19.29186,32.17704 19.37726,32.80984 19.04126,33.25364 C18.84526,33.51544 18.54426,33.65264 18.23766,33.65264"
        id="Fill-7" fill="#75968A"></path>
      <path d="M29.81216,33.65264 C29.50836,33.65264 29.20736,33.51544 29.00856,33.25364 C28.67256,32.80984 28.75936,32.17704 29.20316,31.84104 L45.94996,19.15284 C46.39376,18.81404 47.02656,18.90224 47.36256,19.34744 C47.69856,19.79124 47.61316,20.42404 47.16656,20.76004 L30.42116,33.44824 C30.23916,33.58684 30.02496,33.65264 29.81216,33.65264"
        id="Fill-9" fill="#75968A"></path>
    </g>
  </g>
</svg>