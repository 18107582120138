/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./year.component";
var styles_IconYear = [];
var RenderType_IconYear = i0.ɵcrt({ encapsulation: 2, styles: styles_IconYear, data: {} });
export { RenderType_IconYear as RenderType_IconYear };
export function View_IconYear_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 11, ":svg:svg", [[":xml:space", "preserve"], [":xmlns:a", "http://ns.adobe.com/AdobeSVGViewerExtensions/3.0/"], [":xmlns:xlink", "http://www.w3.org/1999/xlink"], ["height", "42px"], ["style", "enable-background:new 0 0 48 42;"], ["version", "1.1"], ["viewBox", "0 0 48 42"], ["width", "48px"], ["x", "0px"], ["xmlns", "http://www.w3.org/2000/svg"], ["y", "0px"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n"])), (_l()(), i0.ɵeld(2, 0, null, null, 1, ":svg:defs", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n"])), (_l()(), i0.ɵted(-1, null, ["\n"])), (_l()(), i0.ɵeld(5, 0, null, null, 5, ":svg:g", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t"])), (_l()(), i0.ɵeld(7, 0, null, null, 0, ":svg:path", [["class", "st0"], ["d", "M35,25H25c-0.6,0-1,0.4-1,1v8c0,0.6,0.4,1,1,1h10c0.6,0,1-0.4,1-1v-8C36,25.4,35.6,25,35,25z M34,33h-8v-6h8\n\t\tV33z"], ["style", "fill:#75968A;"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t"])), (_l()(), i0.ɵeld(9, 0, null, null, 0, ":svg:path", [["class", "st0"], ["d", "M42,40V28c0.3,0,0.5-0.1,0.7-0.3l4-4c0.4-0.4,0.4-1,0-1.4l-22-22c-0.4-0.4-1-0.4-1.4,0L16,7.6V4\n\t\tc0-0.6-0.4-1-1-1H9C8.4,3,8,3.4,8,4v11.6l-6.7,6.7c-0.4,0.4-0.4,1,0,1.4L5,27.4V40H0v2h6h7h8h20h7v-2H42z M10,5h4v4.6l-4,4V5z\n\t\t M24,2.4L44.6,23L42,25.6L24.7,8.3C24.5,8.1,24.3,8,24,8s-0.5,0.1-0.7,0.3L6,25.6L3.4,23L24,2.4z M14,40V27h6v13H14z M22,40V26\n\t\tc0-0.6-0.4-1-1-1h-8c-0.6,0-1,0.4-1,1v14H7V27.4l17-17l16,16V40H22z"], ["style", "fill:#75968A;"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n"])), (_l()(), i0.ɵted(-1, null, ["\n"])), (_l()(), i0.ɵted(-1, null, ["\n"]))], null, null); }
export function View_IconYear_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "perch-icons-year", [], null, null, null, View_IconYear_0, RenderType_IconYear)), i0.ɵdid(1, 114688, null, 0, i1.IconYear, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var IconYearNgFactory = i0.ɵccf("perch-icons-year", i1.IconYear, View_IconYear_Host_0, {}, {}, []);
export { IconYearNgFactory as IconYearNgFactory };
