/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./valid-thru.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./valid-thru.component";
var styles_ValidThruComponent = [i0.styles];
var RenderType_ValidThruComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ValidThruComponent, data: {} });
export { RenderType_ValidThruComponent as RenderType_ValidThruComponent };
export function View_ValidThruComponent_0(_l) { return i1.ɵvid(2, [i1.ɵpid(0, i2.DatePipe, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(1, 0, null, null, 2, "span", [["id", "expiration-date"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["This offer is valid thru ", ""])), i1.ɵppd(3, 2), (_l()(), i1.ɵted(-1, null, ["\n"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i1.ɵnov(_v, 0), _co.date, "MM.dd.yyyy")); _ck(_v, 2, 0, currVal_0); }); }
export function View_ValidThruComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-valid-thru", [], null, null, null, View_ValidThruComponent_0, RenderType_ValidThruComponent)), i1.ɵdid(1, 49152, null, 0, i3.ValidThruComponent, [], null, null)], null, null); }
var ValidThruComponentNgFactory = i1.ɵccf("app-valid-thru", i3.ValidThruComponent, View_ValidThruComponent_Host_0, { offer: "offer" }, {}, []);
export { ValidThruComponentNgFactory as ValidThruComponentNgFactory };
