<ng-template #template>
  <ng-content></ng-content>
</ng-template>

<div
  class="popover-info js-tooltip-hover-target"
  [popover]="template"
  [outsideClick]="true"
  [placement]="placement"
  container="body"
  trigger="focus">

  <span *ngIf="label != null" class="pr-1">
    <ng-template [ngTemplateOutlet]="label.template"></ng-template>
  </span>

  <span class="icon icon-bg-{{iconBgColor}} icon-size-{{iconSize}}">?</span>

</div>
