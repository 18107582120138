<div class="step">
  <section class="section">
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-8 offset-sm-2 text-center section-body">
          <h1>Comparable home sales</h1>
          <p>Here are the sales of comparable homes in your neighborhood we used to help us develop a fair offer. We factor in key differences in size, floor plan, and upgrades/renovations between your home and comparable homes.</p>
        </div>
      </div>
    </div>

    <div class="comp-wrap">
      <p class="sub-heading label text-center">Recent Similar Sales</p>
      <div id="map">
        <div style="height:inherit"
           leaflet
           [leafletOptions]="leafletMapOptions"
           [leafletLayers]="leafletMapMarkers"
           [leafletFitBoundsOptions]="leafletFitBoundsOptions"
           [leafletFitBounds]="fitBounds"></div>
        </div>
    </div>
    <div class="container-fluid">
      <div>
        <div class="comp-list text-center">
          <div class="d-inline-flex mx-auto">
            <div class="col" *ngFor="let comp of offer?.compList; let index = index;">
              <div class="comp">
                <div class="photo img-overlay">
                  <img class="photo-bg" [src]="comp.image">
                  <div class="index">{{index + 1}}</div>
                  <div class="sold-price text">
                    <div class="col stat-label">Sold Price</div>
                    <div class="col text-right">
                      {{comp.details.adjustedClosePrice | currency: 'USD': 'symbol-narrow': '1.0-0'}}
                    </div>
                  </div>
                </div>
                <div class="list-group">
                  <div class="list-group-item">
                    <div class="col">
                      <div class="address">
                        <span class="street-address text-truncate">{{comp.address.streetAddress}}</span>
                        <span class="city">{{comp.address.city}}</span>,
                        <span class="state">{{comp.address.state}}</span>
                      </div>
                    </div>
                  </div>
                  <div class="distance list-group-item">
                    <div class="col stat-label">Distance</div>
                    <div class="col text-right">
                      {{(comp.details.distance < .1) ? '< 0.1' : (comp.details.distance | number: '1.0-1')}} mi
                    </div>
                  </div>
                  <div class="year-built list-group-item">
                    <div class="col stat-label">Year Built</div>
                    <div class="col text-right">{{comp.details.yearBuilt}}</div>
                  </div>
                  <div class="bedrooms list-group-item">
                    <div class="col stat-label">Bedrooms</div>
                    <div class="col text-right">{{comp.details.bedrooms}}</div>
                  </div>
                  <div class="baths list-group-item">
                    <div class="col stat-label">Baths</div>
                    <div class="col text-right">
                      <app-bathroom-details
                        [full]="comp.details.fullBathrooms"
                        [half]="comp.details.halfBathrooms">
                      </app-bathroom-details>
                    </div>
                  </div>
                  <div class="sqft list-group-item">
                    <div class="col stat-label">Size</div>
                    <div class="col text-right">{{comp.details.sqft}} sqft</div>
                  </div>
                  <div class="lot-size list-group-item">
                    <div class="col stat-label">Lot Size</div>
                    <div class="col text-right">{{comp.details.lotSize | number: '1.0-2'}} ac</div>
                  </div>
                  <div class="sold-date list-group-item">
                    <div class="col stat-label">Date Sold</div>
                    <div class="col text-right">{{comp.details.closeDate | date:'shortDate'}}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-5 justify-content-center">
          <div class="col-sm-8 small text-muted text-center">
            The sale prices listed above are net of seller concessions. This means the price you see is the selling
            price of the home minus any expenses that the home buyer asked the seller to pay for on the buyer's behalf.
            Seller concessions can include, for example, the cost of repairs requested by a buyer or the buyer's closing costs.
          </div>
        </div>
      </div>
    </div>
  </section>

  <div class="steps-footer">
    <div class="container-fluid">
      <a class="btn btn-sm btn-outline-primary" [routerLink]="['/offer', offer.token, previousStep]">Previous Step</a>
      <a class="btn btn-sm btn-primary float-right" [routerLink]="['/offer', offer.token, nextStep]">Next Step</a>
    </div>
  </div>
</div>
