import { Component, Input } from '@angular/core';

import { Offer } from 'models';

@Component({
  selector: 'app-next-steps',
  templateUrl: './next-steps.component.html',
  styleUrls: ['./next-steps.component.css']
})
export class NextStepsComponent {
  @Input()
  public offer: Offer;

  constructor() {}
}
