import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'perch-icons-size',
  templateUrl: './size.component.html'
})
export class IconSize implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
