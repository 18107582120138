<div class="step">
  <section class="section">
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-8 col-md-6 offset-sm-2 offset-md-3 text-center section-body">
          <h1>Your home details</h1>
          <p>We’ve reviewed comparable homes in your area, along with the information you shared with us about the size, condition,
            and upgrades to your home. This helps us identify the attributes of your home that make it attractive to buyers,
            as well as factors that could make it difficult to find the right buyer.</p>

          <br>

          <div class="row" *ngIf="offer">
            <div id="year-built" class="col-5 offset-1 col-md offset-md-0">
              <perch-icons-year></perch-icons-year>
              <p class="stat-label">Year Built</p>
              <p class="stat-value">{{offer.subjectProperty.details.yearBuilt || '-'}}</p>
            </div>
            <div id="sqft" class="col-5 col-md">
              <perch-icons-size></perch-icons-size>
              <p class="stat-label">Size</p>
              <p class="stat-value">{{offer.subjectProperty.details.sqft || '-'}} sqft</p>
            </div>
            <div id="bedrooms" class="col-5 offset-1 col-md offset-md-0">
              <perch-icons-bedrooms></perch-icons-bedrooms>
              <p class="stat-label">Bedrooms</p>
              <p class="stat-value">{{offer.subjectProperty.details.bedrooms || '-'}}</p>
            </div>
            <div id="baths" class="col-5 col-md">
              <perch-icons-baths></perch-icons-baths>
              <p class="stat-label">Baths</p>
              <p class="stat-value">
                <app-bathroom-details
                  [full]="offer.subjectProperty.details.fullBathrooms"
                  [half]="offer.subjectProperty.details.halfBathrooms">
                </app-bathroom-details>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="section section-bg-secondary">
    <div class="container-fluid">
      <div class="col-sm-10 offset-sm-1 text-center">
        <h2 style="margin-bottom:24px;">Factors influencing the
          <br class="d-block d-md-none"> value of your home</h2>
        <p class="stat-label mb-5">Relative to comparable homes in your area</p>

        <div class="row border-between">
          <div id="enhancers" class="col-md-6 mb-5 mb-md-0">
            <p class="sub-heading label" style="margin-bottom:10px;">Enhancing Value</p>
            <p class="value-adjustment" *ngFor="let value of offer?.valueAdjustments.enhancing">{{value}}</p>
          </div>
          <div id="detractors" class="col-md-6">
            <p class="sub-heading label" style="margin-bottom:10px;">Detracting Value</p>
            <p class="value-adjustment" *ngFor="let value of offer?.valueAdjustments.detracting">{{value}}</p>
          </div>
        </div>

      </div>
    </div>
  </section>

  <div class="steps-footer">
    <div class="container-fluid">
      <a class="btn btn-sm btn-outline-primary" [routerLink]="['/offer/' + offer.token + '/comps']">Previous Step</a>
      <a class="btn btn-sm btn-primary float-right" [routerLink]="['/offer/' + offer.token + '/offer']">Next Step</a>
    </div>
  </div>
</div>
