import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { PopoverInfoLabelComponent } from './popover-info-label.component';
import { InfoPopoverComponent } from './popover-info.component';


@NgModule({
  imports: [
    CommonModule,
    PopoverModule.forRoot()
  ],
  declarations: [
    InfoPopoverComponent,
    PopoverInfoLabelComponent
  ],
  exports: [
    InfoPopoverComponent,
    PopoverInfoLabelComponent
  ]
})
export class PopoverInfoModule { }
