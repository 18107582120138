<svg version="1.1"
	 xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:a="http://ns.adobe.com/AdobeSVGViewerExtensions/3.0/"
	 x="0px" y="0px" width="48px" height="41px" viewBox="0 0 48 41" style="enable-background:new 0 0 48 41;" xml:space="preserve">
<g>
	<path class="st0" style="fill:#75968A;" d="M47,6H7V1c0-0.6-0.4-1-1-1C2.2,0,0,3.1,0,6v29c0,0,0,0,0,0c0,3.3,2.7,6,6,6h41c0.6,0,1-0.4,1-1V7
		C48,6.4,47.6,6,47,6z M2,6c0-1.7,1.1-3.4,3-3.9v27c-1.2,0.2-2.2,0.7-3,1.4V6z M46,39H6c-2.2,0-4-1.8-4-4c0-2,1.5-4,4-4
		c0.6,0,1-0.4,1-1V8h39V39z"/>
	<path class="st0" style="fill:#75968A;" d="M19,29v6h18V24h4V12H25h-1H12v17H19z M14,14h9v3h2v-3h14v8h-2h-1h-4v2h3v9H21v-4h4v-5h4v-2h-4v-2h-2v7h-9V14z"
		/>
</g>
</svg>
