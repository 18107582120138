import { Injectable, NgModule } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterModule, Routes } from '@angular/router';
import { EstimatesService, OffersService } from 'app/providers';
import { Estimate, Offer } from 'models';
import { EstimateDetailsWrapperComponent } from './estimates/estimate-details-wrapper';
import { EstimateIntroTabComponent } from './estimates/intro-tab/intro-tab.component';
import { EstimateNotFoundComponent } from './estimates/not-found/not-found.component';
import { GspOfferComponent, GspOfferCoverSheetComponent, GspOfferNotFoundComponent } from './offer/components';



@Injectable({ providedIn: 'root' })
export class OfferResolve implements Resolve<Offer> {
  constructor(private offerService: OffersService) {}

  resolve(route: ActivatedRouteSnapshot) {
    return this.offerService.getOfferData(route.params.offerToken);
  }
}

@Injectable({ providedIn: 'root' })
export class WillowOfferNotFoundResolve implements Resolve<boolean> {
  constructor(private router: Router) {}

  resolve(route: ActivatedRouteSnapshot): boolean {
    if (!route.parent.data.offer.expired) {
      return true;
    }
    else {
      this.router.navigate(['/gsp-offer', route.params.offerToken, 'not-found']);
      return false;
    }
  }
}

@Injectable({ providedIn: 'root' })
export class EstimateResolve implements Resolve<Estimate> {

  constructor(private estimatesService: EstimatesService) {}

  resolve(route: ActivatedRouteSnapshot) {
    return this.estimatesService.getEstimateData(route.params.estimateId);
  }
}

@Injectable({ providedIn: 'root' })
export class NotFoundEstimateResolve implements Resolve<boolean> {
  constructor(private router: Router) {}

  resolve(route: ActivatedRouteSnapshot): boolean {
    if (!route.parent.data.estimate.expired) {
      return true;
    }
    else {
      this.router.navigate(['/estimate', route.params.estimateId, 'not-found']);
      return false;
    }
  }
}

const routes: Routes = [
  // It's going to be difficult to remove this deprecated route's redirect, because we've sent thousands of emails
  // that include this route. Even ancient offer pack links should successfully take the user to an expired offer
  // experience. Also consider that our data-driven email campaigns managed in external providers generate URLs
  // with this route prefix, and they'd all need to be updated for new emails.
  {
    path: 'buy-and-sell-offer/:offerToken',
    redirectTo: 'gsp-offer/:offerToken',
  },
  {
    path: 'gsp-offer/:offerToken',
    resolve: { offer: OfferResolve },
    children: [
      {
        path: 'intro',
        component: GspOfferCoverSheetComponent,
        resolve: { expired: WillowOfferNotFoundResolve }
      },
      {
        path: 'details',
        component: GspOfferComponent,
        resolve: { expired: WillowOfferNotFoundResolve }
      },
      {
        path: 'not-found',
        component: GspOfferNotFoundComponent
      },
      {
        path: '',
        redirectTo: 'intro',
        pathMatch: 'full'
      }
    ]
  },
  {
    path: 'estimate/:estimateId',
    resolve: { estimate: EstimateResolve },
    children: [
      {
        path: 'intro-tab',
        component: EstimateIntroTabComponent,
        resolve: { expired: NotFoundEstimateResolve }
      },
      {
        path: 'details',
        component: EstimateDetailsWrapperComponent,
        resolve: { expired: NotFoundEstimateResolve }
      },
      {
        path: 'not-found',
        component: EstimateNotFoundComponent
      },
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'intro-tab'
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class WillowRoutingModule {}
