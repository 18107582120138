import { OnInit } from '@angular/core';
import { latLng, tileLayer, marker, latLngBounds, MapOptions, LatLngBounds, FitBoundsOptions, Marker } from 'leaflet';
import { MapMarkersIcon } from '../../../classes/map-markers';
import { Configuration } from 'models';
var subjectPropertyIconOptions = {
    iconUrl: '',
    markerColor: 'red',
    iconSize: [36, 36],
    iconAnchor: [18, 36],
    iconClass: 'material-icons',
    iconLabel: 'home'
};
var compIconOptions = {
    iconUrl: '',
    markerColor: 'black',
    iconSize: [36, 36],
    iconAnchor: [18, 36]
};
var CompsComponent = /** @class */ (function () {
    function CompsComponent(configs) {
        this.configs = configs;
        // Get centerpoint and bounds of properties for map
        this.getMapSettings = function (properties) {
            var x = properties.map(function (a) { return a.address.geometry.latitude; });
            var y = properties.map(function (a) { return a.address.geometry.longitude; });
            var minX = Math.min.apply(null, x);
            var maxX = Math.max.apply(null, x);
            var minY = Math.min.apply(null, y);
            var maxY = Math.max.apply(null, y);
            var southWest = latLng(minX, minY);
            var northEast = latLng(maxX, maxY);
            var bounds = latLngBounds(southWest, northEast);
            return {
                centerPoint: {
                    latitude: (minX + maxX) / 2,
                    longitude: (minY + maxY) / 2
                },
                bounds: bounds
            };
        };
    }
    Object.defineProperty(CompsComponent.prototype, "nextStep", {
        get: function () {
            return this.offer.valueAdjustments != null ? 'home-details' : 'offer';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompsComponent.prototype, "previousStep", {
        get: function () {
            return this.offer.marketAnalysis != null ? 'market-analysis' : 'intro-tab';
        },
        enumerable: true,
        configurable: true
    });
    CompsComponent.createMarker = function (geometry, icon) {
        return marker([geometry.latitude, geometry.longitude], { icon: icon });
    };
    CompsComponent.prototype.ngOnInit = function () {
        this.renderMap();
    };
    CompsComponent.prototype.renderMap = function () {
        var mapSettings = this.getMapSettings([this.offer.subjectProperty].concat(this.offer.compList)); // Add Subject Property
        this.leafletMapOptions = {
            layers: [
                tileLayer("https://api.mapbox.com/styles/v1/mapbox/streets-v10/tiles/{z}/{x}/{y}@2x?access_token=" + this.configs.MAPBOX_ACCESS_TOKEN, {
                    maxZoom: 18,
                    minZoom: 1,
                    noWrap: false,
                    tileSize: 512,
                    zoomOffset: -1,
                    attribution: "&copy;\n              <a href=\"https://www.mapbox.com/about/maps/\">Mapbox</a> &copy;\n              <a href=\"https://www.mapbox.com/map-feedback/\" target=\"_blank\">\n                <strong>Improve this map</strong>\n              </a>"
                })
            ],
            zoom: 15,
            scrollWheelZoom: false,
            center: latLng(mapSettings.centerPoint.latitude, mapSettings.centerPoint.longitude)
        };
        this.leafletFitBoundsOptions = {
            padding: [10, 10],
            maxZoom: 17
        };
        this.fitBounds = mapSettings.bounds;
        this.displayMapMarkers();
    };
    CompsComponent.prototype.displayMapMarkers = function () {
        var compMarkers = this.getLeafletMarkers(this.offer.compList, compIconOptions);
        var subjectPropertyMarker = this.getLeafletMarkers([this.offer.subjectProperty], subjectPropertyIconOptions);
        this.leafletMapMarkers = compMarkers.concat(subjectPropertyMarker);
    };
    CompsComponent.prototype.getLeafletMarkers = function (properties, iconSettings) {
        return properties.map(function (property, index) {
            // Add marker label if no icon-class is set
            if (!iconSettings.iconClass) {
                iconSettings.markerLabel = index + 1;
            }
            var iconMarker = new MapMarkersIcon(iconSettings);
            return CompsComponent.createMarker(property.address.geometry, iconMarker);
        });
    };
    return CompsComponent;
}());
export { CompsComponent };
