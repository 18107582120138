import { Component, Input } from '@angular/core';
import { Offer } from 'models';

@Component({
  selector: 'app-buy-and-sell-offer',
  templateUrl: './buy-and-sell-offer.component.html',
  styleUrls: ['./buy-and-sell-offer.component.scss']
})
export class BuyAndSellOfferComponent {
  @Input() offer: Offer;
}
