import { Component, Input } from '@angular/core';
import { Offer } from 'models/offer';


@Component({
  selector: 'app-list-with-backstop-offer',
  templateUrl: './lwb-offer.html',
  styleUrls: ['./lwb-offer.scss']
})
export class ListWithBackstopOfferComponent {
  @Input()
  offer: Offer;
}
