import { OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { HttpParams } from '@angular/common/http';
import { OlarkService } from '../../../../providers/olark';
var EstimateDetailsV3Component = /** @class */ (function () {
    function EstimateDetailsV3Component(titleService, route, olarkService) {
        this.titleService = titleService;
        this.route = route;
        this.olarkService = olarkService;
        this.showValuationFaq = false;
    }
    EstimateDetailsV3Component.prototype.ngOnInit = function () {
        this.calendlyLink = this.generateCalendlyLink();
        var address = this.estimate.subjectProperty.address;
        this.titleService.setTitle("Orchard Estimate - " + address.streetAddress + " " + address.city + ", " + address.state + ", " + address.zipcode);
    };
    EstimateDetailsV3Component.prototype.sendMessage = function () {
        this.olarkService.sendMessageToUser('Let’s get your home visit scheduled! What day/time works best for you?');
        this.olarkService.expandChatbox();
    };
    EstimateDetailsV3Component.prototype.toggleValuationFaq = function () {
        this.showValuationFaq = !this.showValuationFaq;
    };
    EstimateDetailsV3Component.prototype.generateCalendlyLink = function () {
        var address = this.estimate.subjectProperty.address;
        var phoneNumber = this.estimate.contact.phoneNumber ? this.estimate.contact.phoneNumber : undefined;
        var httpParams = new HttpParams({
            fromObject: this.estimate.contact == null ? {} : {
                name: this.estimate.contact.firstName + ' ' + this.estimate.contact.lastName,
                email: this.estimate.contact.email,
                a1: phoneNumber,
                a2: address.streetAddress + ", " + address.city + ", " + address.state + " " + address.zipcode
            }
        });
        return "https://calendly.com/orchard-cx/orchard-consultation-cx?" + httpParams.toString();
    };
    Object.defineProperty(EstimateDetailsV3Component.prototype, "isTexas", {
        get: function () {
            return this.estimate.subjectProperty.address.state === 'TX';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EstimateDetailsV3Component.prototype, "isListWithBackstop", {
        get: function () {
            return this.estimate.subjectProperty.serviceName === 'LIST_WITH_BACKSTOP';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EstimateDetailsV3Component.prototype, "scheduleCtaText", {
        get: function () {
            return this.isListWithBackstop ? 'Schedule Assessment' : 'Schedule Consultation';
        },
        enumerable: true,
        configurable: true
    });
    return EstimateDetailsV3Component;
}());
export { EstimateDetailsV3Component };
