// tslint:disable max-line-length
export const offerComparisonTooltips: Record<string, string> = {
  mortgageRentOverlap: `
    In a traditional home sale, you are usually unable to perfectly line up the sale of your home with your next home purchase/rental.
    This can cost you thousands in double mortgage payments or short-term rental and storage fees.
  `,
  perchFee: `
    The Orchard fee covers our costs of purchasing your home, paying for holding costs like taxes and utilities,
    and re-listing your home for sale. It also helps us give you a fair and accurate offer in today's uncertain
    housing market conditions.
  `,
  sellerConcessions: `
    In a traditional sale, buyers will often request thousands in concessions to cover their closing costs and other expenses.
  `,
  propertyTaxes: `
    Sellers are typically responsible for covering year-to-date property taxes at the closing of any home sale.
    If you already pay your taxes monthly, your mortgage company will be able to refund you the balance.
  `,
  hoaTransferFees: `
    If your house is part of an HOA community,
    the HOA might charge a fee to process your sale to a new owner.
  `,
  titleEscrowNotary: `
    These are costs you can expect to pay at the closing of any home sale transaction.
    The title company will walk you through these costs in detail if you have questions.
  `
};
