<div class="progress-bar-truck-container">
  <img class="progress-bar-truck-container__truck" src="https://res.cloudinary.com/dmxgostd9/image/upload/v1580485282/web-assets/offer-pack/moving-truck.gif">
</div>

<div class="progress-bar-progress-container">
  <div style="position: absolute" class="progress-bar-background">
    <div class="progress-bar-progress"></div>
  </div>

  <div class="progress-bar-dot-container">

    <div class="progress-bar-dot-wrapper d-flex flex-column">
      <div class="progress-bar-dot progress-bar-dot--fulfilled"></div>
      <div class="text-body-sm">Initial estimate</div>
    </div>

    <div class="progress-bar-dot-wrapper d-flex flex-column align-items-center">
      <div class="progress-bar-dot"></div>
      <div class="text-center text-body-sm">
        <ng-container *ngIf="isListWithBackstop else bnsStepTwo">
          Assessment
        </ng-container>
        <ng-template #bnsStepTwo>
          Consultation
        </ng-template>
      </div>
    </div>

    <div class="progress-bar-dot-wrapper d-flex flex-column align-items-end">
      <div class="progress-bar-dot"></div>
      <div class="text-right text-body-sm">Get list price and cash offer</div>
    </div>

  </div>
</div>

<!-- <div class="progress-bar-labels-container">
  <span>e</span>
  <span></span>
  <span></span>
</div> -->


