<section class="section">
  <div class="container">
    <div class="row">
      <header class="col-12">
        <h1 class="text-h3">Your recommended list price is:</h1>
        <div id="suggested-list-price" class="header-amount">
          <span class="text-h1">
            <sup class="dollar text-h3">&#36;</sup><span data-testid="suggested-list-price">{{offer.offerDetails['suggestedListPrice'] | number}}</span>
          </span>
        </div>
      </header>
    </div>
    <div class="row rlp-detail-block">
      <div class="col-md-8 offset-md-2">
        <p class="text-body-lg">
          This reflects our current list price recommendation for your home.
          Prior to listing your home, your Listing Agent will run these numbers again to account for current market conditions.
        </p>
        <p class="text-body-lg">
          You always have the final say on your home’s list price.
        </p>
        <p class="text-body">
          Your Orchard guaranteed offer is <span data-testid="guaranteed-sale-price">{{offer.amount | currency:'USD':'symbol-narrow':'1.0-0'}}</span>.
          Almost all Orchard customers sell their home for more than their guaranteed offer amount.
        </p>
        <p class="text-body">
          This offer price is valid through {{offer.expirationDate | date:'MM.dd.yyyy'}}.
        </p>
      </div>
    </div>
  </div>
</section>
<section id="perch-costs">
  <header>
    <h1 class="text-h3">Costs with Orchard</h1>
    <div class="subheader text-body">
      No Hidden Fees
    </div>
  </header>
  <ul>
    <li class="text-body-lg font-bold">6% of your home sale price, paid at closing</li>
    <li class="text-body-lg font-bold">
      <span>
        Program fee
        <popover-info placement="top" iconSize="md" iconBgColor="light">
          <span class="text-caption">
            A program fee supports teams that assist in your transaction to give you the best home buying and selling experience.
          </span>
        </popover-info>
      </span>
    </li>
    <li class="text-body-lg font-bold">
      <span>
        Standard title and escrow costs
        <popover-info placement="top" iconSize="md" iconBgColor="light">
          <span class="text-caption">
            These are costs you can expect to pay at the closing of any home sale transaction.
            You can estimate these to be around 0.8% of your home sale price.
            The title company will walk you through these costs in detail if you have questions.
          </span>
        </popover-info>
      </span>
    </li>
    <li class="text-body-lg font-bold">
      <span>
        Taxes, HOA, and utility payments while your home is listed.
        <popover-info placement="top" iconSize="md" iconBgColor="light">
          <span class="text-caption">
            While your home is listed, Orchard will open up an escrow account to hold this amount for payment.
            If your home sells before your listing period ends, we’ll refund any unused funds at closing.
          </span>
        </popover-info>
      </span>
    </li>
  </ul>
</section>
<section id="get-started">
  <div class="container">
    <div class="row">
      <header class="col-12">
        <h1 class="text-h3">Ready to get started?</h1>
      </header>
    </div>
    <div class="row">
      <div class="col-md-8 offset-md-2">
        <p class="text-body">
          Contact your Orchard Service Advisor or email us at <a href="mailto:support@orchard.com">support@orchard.com</a>.
          We have a few documents for you to sign,
          then it's time to start searching for your new home.
        </p>
      </div>
    </div>
  </div>
</section>
<section id="contact">
  <div class="container">
    <h1 class="text-h3">Questions? Contact us anytime</h1>
    <div class="row contact-info">
      <div class="col-md-4 offset-md-2 mb-4">
        <p class="contact-label text-label">Call Us</p>
        <p class="contact-detail text-body"><a href="tel:18448191373">(844) 819-1373</a></p>
      </div>
      <div class="col-md-4">
        <p class="contact-label text-label">Email Us</p>
        <p class="contact-detail text-body"><a href="mailto:support@orchard.com">support@orchard.com</a></p>
      </div>
    </div>
  </div>
</section>
