import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { HttpParams } from '@angular/common/http';

import { OlarkService } from '../../../../providers/olark';
import { Estimate } from 'models';


@Component({
  selector: 'app-estimate-details-v3',
  templateUrl: './details-v3.component.html',
  styleUrls: ['./details-v3.component.scss']
})
export class EstimateDetailsV3Component implements OnInit {

  @Input()
  estimate: Estimate;

  calendlyLink: string;
  showValuationFaq = false;

  constructor(
    private titleService: Title,
    private route: ActivatedRoute,
    public olarkService: OlarkService
  ) {
  }

  ngOnInit() {
    this.calendlyLink = this.generateCalendlyLink();

    const address = this.estimate.subjectProperty.address;
    this.titleService.setTitle(`Orchard Estimate - ${address.streetAddress} ${address.city}, ${address.state}, ${address.zipcode}`);
  }

  sendMessage(): void {
    this.olarkService.sendMessageToUser('Let’s get your home visit scheduled! What day/time works best for you?');
    this.olarkService.expandChatbox();
  }

  toggleValuationFaq(): void {
    this.showValuationFaq = !this.showValuationFaq;
  }

  private generateCalendlyLink(): string {
    const address = this.estimate.subjectProperty.address;
    const phoneNumber = this.estimate.contact.phoneNumber ? this.estimate.contact.phoneNumber : undefined;
    const httpParams = new HttpParams({
      fromObject: this.estimate.contact == null ? {} : {
        name: this.estimate.contact.firstName + ' ' + this.estimate.contact.lastName,
        email: this.estimate.contact.email,
        a1: phoneNumber,
        a2: `${address.streetAddress}, ${address.city}, ${address.state} ${address.zipcode}`
      }
    });
    return `https://calendly.com/orchard-cx/orchard-consultation-cx?${httpParams.toString()}`;
  }

  get isTexas(): boolean {
    return this.estimate.subjectProperty.address.state === 'TX';
  }

  get isListWithBackstop(): boolean {
    return this.estimate.subjectProperty.serviceName === 'LIST_WITH_BACKSTOP';
  }

  get scheduleCtaText(): string {
    return this.isListWithBackstop ? 'Schedule Assessment' : 'Schedule Consultation';
  }
}
