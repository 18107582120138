<willow-layout [address]="estimate.subjectProperty?.address">
  <perch-icons-letter role="icon"></perch-icons-letter>

  <div role="header">
    Your initial home sale estimate is ready to review
  </div>

  <a id="next-step" class="btn btn-sm btn-primary" [routerLink]="['../details']">
    View Estimate
  </a>
</willow-layout>
