import { OfferType } from 'models';
import { offerComparisonTooltips } from '../constants';
var OfferComparisonTableV3Component = /** @class */ (function () {
    function OfferComparisonTableV3Component() {
        this.tooltips = offerComparisonTooltips;
        this.offerTypes = OfferType;
    }
    Object.defineProperty(OfferComparisonTableV3Component.prototype, "defectEstimate", {
        get: function () {
            return this.offer.offerDetails.defectEstimate;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OfferComparisonTableV3Component.prototype, "sellOnly", {
        get: function () {
            return this.offer.offerDetails.sellOnly;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OfferComparisonTableV3Component.prototype, "traditional", {
        get: function () {
            return this.offer.offerDetails.traditional;
        },
        enumerable: true,
        configurable: true
    });
    return OfferComparisonTableV3Component;
}());
export { OfferComparisonTableV3Component };
