import { Component, OnInit, Input, Inject } from '@angular/core';
import { latLng, tileLayer, marker, icon, latLngBounds, MapOptions, LatLngBounds, FitBoundsOptions, Marker } from 'leaflet';

import { MapMarkersIcon, MapMarkersIconOptions } from '../../../classes/map-markers';
import { Offer, Property, Configuration } from 'models';

const subjectPropertyIconOptions: MapMarkersIconOptions = {
  iconUrl: '',
  markerColor: 'red',
  iconSize: [36, 36],
  iconAnchor: [18, 36],
  iconClass: 'material-icons',
  iconLabel: 'home'
};

const compIconOptions: MapMarkersIconOptions = {
  iconUrl: '',
  markerColor: 'black',
  iconSize: [36, 36],
  iconAnchor: [18, 36]
};

@Component({
  selector: 'app-comps',
  templateUrl: './comps.component.html',
  styleUrls: ['./comps.component.css']
})
export class CompsComponent implements OnInit {

  get nextStep(): string {
    return this.offer.valueAdjustments != null ? 'home-details' : 'offer';
  }

  get previousStep(): string {
    return this.offer.marketAnalysis != null ? 'market-analysis' : 'intro-tab';
  }

  constructor(
    @Inject('CONFIGS') private configs: Configuration
  ) {}

  @Input()
  public offer: Offer;


  // Leaflet
  public leafletMapOptions: MapOptions;
  public leafletMapMarkers: Array<Marker>;
  public leafletFitBoundsOptions: FitBoundsOptions;
  public fitBounds: LatLngBounds;

  private static createMarker(geometry, icon: MapMarkersIcon): Marker {
    return marker([geometry.latitude, geometry.longitude], {icon: icon});
  }

  ngOnInit() {
    this.renderMap();
  }

  private renderMap() {
    const mapSettings = this.getMapSettings([...[this.offer.subjectProperty], ...this.offer.compList]); // Add Subject Property

    this.leafletMapOptions = {
      layers: [
        tileLayer(`https://api.mapbox.com/styles/v1/mapbox/streets-v10/tiles/{z}/{x}/{y}@2x?access_token=${this.configs.MAPBOX_ACCESS_TOKEN}`,
          {
            maxZoom: 18,
            minZoom: 1,
            noWrap: false,
            tileSize: 512,
            zoomOffset: -1,
            attribution: `&copy;
              <a href="https://www.mapbox.com/about/maps/">Mapbox</a> &copy;
              <a href="https://www.mapbox.com/map-feedback/" target="_blank">
                <strong>Improve this map</strong>
              </a>`
          })
      ],
      zoom: 15,
      scrollWheelZoom: false,
      center: latLng(
        mapSettings.centerPoint.latitude,
        mapSettings.centerPoint.longitude)
    };

    this.leafletFitBoundsOptions = {
      padding: [10, 10],
      maxZoom: 17
    };

    this.fitBounds = mapSettings.bounds;

    this.displayMapMarkers();
  }

  private displayMapMarkers() {
    const compMarkers = this.getLeafletMarkers(this.offer.compList, compIconOptions);
    const subjectPropertyMarker = this.getLeafletMarkers([this.offer.subjectProperty], subjectPropertyIconOptions);

    this.leafletMapMarkers = [...compMarkers, ...subjectPropertyMarker];
  }

  private getLeafletMarkers(properties: Array<Property>, iconSettings: MapMarkersIconOptions): Array<Marker> {
    return properties.map((property, index) => {
      // Add marker label if no icon-class is set
      if (!iconSettings.iconClass){
        iconSettings.markerLabel = index + 1;
      }

      const iconMarker = new MapMarkersIcon(iconSettings);

      return CompsComponent.createMarker(property.address.geometry, iconMarker);
    });
  }

  // Get centerpoint and bounds of properties for map
  private getMapSettings = function(properties: Array<Property>){
    const x = properties.map(function(a){ return a.address.geometry.latitude; });
    const y = properties.map(function(a){ return a.address.geometry.longitude; });
    const minX = Math.min.apply(null, x);
    const maxX = Math.max.apply(null, x);
    const minY = Math.min.apply(null, y);
    const maxY = Math.max.apply(null, y);

    const southWest = latLng(minX, minY);
    const northEast = latLng(maxX, maxY);
    const bounds = latLngBounds(southWest, northEast);

    return {
      centerPoint: {
        latitude: (minX + maxX) / 2,
        longitude: (minY + maxY) / 2
      },
      bounds: bounds
    };
  };
}
