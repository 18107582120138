<perch-logo></perch-logo>
<div class="step estimate-step-2 pb-0" *ngIf="estimate">
  <section class="section estimate-details">
    <div class="constrained">
      <div class="subhead">
        <h5>Estimate before home visit</h5>
      </div>
      <div class="estimate-range pb-2">
        <h1 id="estimate-min">${{estimate.min | number}}</h1>
        <h1>&nbsp;&ndash;&nbsp;</h1>
        <h1 id="estimate-max">${{estimate.max | number}}</h1>
      </div>
      <p>To get you a more exact sale price, we need to visit your home in-person and account for all the details
        that make it unique.</p>
    </div>
  </section>
  <section class="section next-up">
    <div class="constrained">
      <div class="subhead">
        <h5>Next up</h5>
      </div>
      <div class="headline">
        <h1>Schedule your consultation</h1>
      </div>
      <p>Pick a time for us to give you a quick call. We'll review your goals and set up a free home visit in order to finalize your list price.</p>
      <p>(If you've already scheduled this, you don't have to do it again)</p>
      <div class="home-visit-cta mt-5">
        <a class="btn btn-sm btn-primary" [href]="calendlyLink" target="_blank">Request my consultation</a>
        <p class="call-us">Or just call us at <a href="tel:8444800931" class="tel-link">844-480-0931</a> <span> (Mon-Fri 9am to 5pm)</span></p>
      </div>
    </div>
  </section>
  <section class="section what-happens-next">
    <div class="constrained">
      <div class="subhead">
        <h5>Refining your estimate</h5>
      </div>
      <div class="headline pb-3">
        <h1>After your home visit you'll receive your:</h1>
      </div>
      <div class="happens-next-step">
        <div class="icon">
          <perch-icons-letter class="icon"></perch-icons-letter>
        </div>
        <div class="details">
          <h4>Recommended list price</h4>
          <p>The suggested price for your listing, determined by your Orchard Home Advisor with your input.</p>
        </div>
      </div>
      <div class="happens-next-step">
        <div class="icon">
          <perch-icons-calendar></perch-icons-calendar>
        </div>
        <div class="details">
          <h4>Guaranteed sale price</h4>
          <p>The amount Orchard will give you for your home, so that you can buy your next home before selling.</p>
        </div>
      </div>
      <div class="happens-next-step">
        <div class="icon">
          <perch-icons-checkmark></perch-icons-checkmark>
        </div>
        <div class="details">
          <h4>Assessment report</h4>
          <p>
            A closer look into your home's condition to help educate you as you
            prep for your sale. (Yours for free, a $500 value)
          </p>
        </div>
      </div>
    </div>
  </section>
  <section class="section faq">
    <div class="constrained">
      <div class="subhead">
        <h5>FAQ</h5>
      </div>
      <div class="headline">
        <h1>Any questions?</h1>
      </div>
      <accordion>
        <accordion-group heading="How was my initial estimate calculated?">
          <p>
            Our initial list price recommendation for your home is based on the information you shared with us about your home's condition as well as recent sale data from your neighborhood. The range represents the price where most homes like yours have listed, but once we see your home, an Orchard Home Advisor, one of our licensed agents, can give you a personalized recommendation that factors in upgrades you've made, the time of year, and how quickly homes have sold lately in your area.
          </p>
        </accordion-group>
        <accordion-group heading="What happens during the home visit?">
          <p>
            During your home visit, you'll meet with an Orchard Home Advisor, the Home Advisor who will
            guide you through your home listing and new home purchase. They will answer any questions
            you have about the Buy & Sell service and take a closer look at your home to recommend a list
            price. Once you meet your Orchard Home Advisor, you can schedule your free home assessment, which
            helps Orchard calculate your guaranteed sale price.
          </p>
        </accordion-group>
        <accordion-group heading="When will I receive my guaranteed sale price?">
          <p>
            Your guaranteed sale price will be ready within 3 business days of your home assessment.
            Once we've calculated it, we'll reach out and let you know.
          </p>
        </accordion-group>
        <accordion-group heading="Do I need to sign anything yet?">
          <p>
            Not yet! After you receive your recommended list price and guaranteed offer amount, your
            Orchard Home Advisor can answer any questions. If you're ready to move forward, we'll send
            you the documents to look over to work with us.
          </p>
        </accordion-group>
      </accordion>
      <div class="text-center mb-2 mt-5">
        <a class="btn btn-sm btn-primary" onclick="olark('api.box.expand')">Ask another question</a>
      </div>
    </div>
  </section>
  <section class="section ready-to-continue">
    <div class="constrained">
      <div class="headline">
        <h1>Ready to continue?</h1>
      </div>
      <p>Click here to request a home visit and we’ll coordinate the rest</p>
      <div class="home-visit-cta mt-4">
        <div><a class="btn btn-sm btn-primary" [href]="calendlyLink" target="_blank">Request my home visit</a></div>
        <div>Complimentary and commitment&ndash;free.</div>
      </div>
      <p class="mt-4">
        Or you can email us at <a href="mailto:support@orchard.com">support@orchard.com</a>
        <br/>
        or call us at <a href="tel:18448191373">(844) 819-1373</a>
      </p>
    </div>
  </section>
  <willow-disclaimer>
    <div class="content-block">
      <h5 class="stat-label">Data Sources</h5>
      <p>
        Orchard leverages up-to-date data from county records and local MLS'. We combine
        this data with Orchard's proprietary valuation model to provide you with a comprehensive, simple
        and accurate estimate. For questions, please contact Orchard at <a href="mailto:support@orchard.com">support@orchard.com</a>.
      </p>
    </div>
    <div class="content-block">
      <h5 class="stat-label">Disclaimer</h5>
      <p>
        Orchard certifies that, to the best of its knowledge, the statements of fact
        contained in this report are true and correct. Orchard is not acting as a real estate
        agent or broker, Orchard is only acting as a prospective purchaser of real estate.
      </p>
      <p *ngIf="isTexas">
        Texas real estate law requires agents provide the
        <a target="_blank" href="https://s3.amazonaws.com/perch-website-assets/files/disclosures/trec/CN.pdf">
          TREC Consumer Protection Notice
        </a>
        and
        <a target="_blank" href="https://s3.amazonaws.com/perch-website-assets/files/disclosures/trec/IABS.pdf">
          Information About Brokerage Services
        </a>
        to all consumers.
      </p>
      <p>
        Orchard is the consumer brand of Orchard Property I.
      </p>
    </div>
  </willow-disclaimer>
</div>
