import { Component, Input } from '@angular/core';

import { Offer, OfferType } from 'models';

@Component({
  selector: 'app-intro-tab',
  templateUrl: './intro-tab.component.html',
  styleUrls: ['./intro-tab.component.css']
})
export class IntroTabComponent {
  OfferType = OfferType;

  @Input() public offer: Offer;

  constructor() {}
}
