/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../details-v3/details-v3.component.ngfactory";
import * as i2 from "../details-v3/details-v3.component";
import * as i3 from "@angular/platform-browser";
import * as i4 from "@angular/router";
import * as i5 from "../../../../providers/olark/olark.service";
import * as i6 from "../details-v2/details-v2.component.ngfactory";
import * as i7 from "../details-v2/details-v2.component";
import * as i8 from "../details/details.component.ngfactory";
import * as i9 from "../details/details.component";
import * as i10 from "@angular/common";
import * as i11 from "./estimate-details-wrapper.component";
import * as i12 from "../../../../providers/heap-analytics/heap-analytics.service";
var styles_EstimateDetailsWrapperComponent = [];
var RenderType_EstimateDetailsWrapperComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_EstimateDetailsWrapperComponent, data: {} });
export { RenderType_EstimateDetailsWrapperComponent as RenderType_EstimateDetailsWrapperComponent };
function View_EstimateDetailsWrapperComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n    "])), (_l()(), i0.ɵeld(1, 0, null, null, 1, "app-estimate-details-v3", [], null, null, null, i1.View_EstimateDetailsV3Component_0, i1.RenderType_EstimateDetailsV3Component)), i0.ɵdid(2, 114688, null, 0, i2.EstimateDetailsV3Component, [i3.Title, i4.ActivatedRoute, i5.OlarkService], { estimate: [0, "estimate"] }, null), (_l()(), i0.ɵted(-1, null, ["\n  "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.estimate; _ck(_v, 2, 0, currVal_0); }, null); }
function View_EstimateDetailsWrapperComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n        "])), (_l()(), i0.ɵeld(2, 0, null, null, 1, "app-estimate-details-v2", [], null, null, null, i6.View_EstimateDetailsV2Component_0, i6.RenderType_EstimateDetailsV2Component)), i0.ɵdid(3, 114688, null, 0, i7.EstimateDetailsV2Component, [i3.Title, i5.OlarkService], { estimate: [0, "estimate"] }, null), (_l()(), i0.ɵted(-1, null, ["\n      "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.estimate; _ck(_v, 3, 0, currVal_0); }, null); }
function View_EstimateDetailsWrapperComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n        "])), (_l()(), i0.ɵeld(2, 0, null, null, 1, "app-estimate-details", [], null, null, null, i8.View_EstimateDetailsComponent_0, i8.RenderType_EstimateDetailsComponent)), i0.ɵdid(3, 114688, null, 0, i9.EstimateDetailsComponent, [i3.Title], { estimate: [0, "estimate"] }, null), (_l()(), i0.ɵted(-1, null, ["\n      "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.estimate; _ck(_v, 3, 0, currVal_0); }, null); }
function View_EstimateDetailsWrapperComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n    "])), (_l()(), i0.ɵeld(1, 0, null, null, 8, null, null, null, null, null, null, null)), i0.ɵdid(2, 16384, null, 0, i10.NgSwitch, [], { ngSwitch: [0, "ngSwitch"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\n      "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EstimateDetailsWrapperComponent_3)), i0.ɵdid(5, 278528, null, 0, i10.NgSwitchCase, [i0.ViewContainerRef, i0.TemplateRef, i10.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\n      "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EstimateDetailsWrapperComponent_4)), i0.ɵdid(8, 16384, null, 0, i10.NgSwitchDefault, [i0.ViewContainerRef, i0.TemplateRef, i10.NgSwitch], null, null), (_l()(), i0.ɵted(-1, null, ["\n\n    "])), (_l()(), i0.ɵted(-1, null, ["\n  "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.testGroup == null) ? null : _co.testGroup.name); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.TestGroupName.EXPERIMENT; _ck(_v, 5, 0, currVal_1); }, null); }
export function View_EstimateDetailsWrapperComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, null, null, null, null, null, null, null)), i0.ɵdid(1, 16384, null, 0, i10.NgSwitch, [], { ngSwitch: [0, "ngSwitch"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\n  "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EstimateDetailsWrapperComponent_1)), i0.ɵdid(4, 278528, null, 0, i10.NgSwitchCase, [i0.ViewContainerRef, i0.TemplateRef, i10.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\n  "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EstimateDetailsWrapperComponent_2)), i0.ɵdid(7, 16384, null, 0, i10.NgSwitchDefault, [i0.ViewContainerRef, i0.TemplateRef, i10.NgSwitch], null, null), (_l()(), i0.ɵted(-1, null, ["\n\n"])), (_l()(), i0.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.estimate.version; _ck(_v, 1, 0, currVal_0); var currVal_1 = 3; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_EstimateDetailsWrapperComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-estimate-details-wrapper", [], null, null, null, View_EstimateDetailsWrapperComponent_0, RenderType_EstimateDetailsWrapperComponent)), i0.ɵdid(1, 114688, null, 0, i11.EstimateDetailsWrapperComponent, [i4.ActivatedRoute, i12.HeapAnalyticsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EstimateDetailsWrapperComponentNgFactory = i0.ɵccf("app-estimate-details-wrapper", i11.EstimateDetailsWrapperComponent, View_EstimateDetailsWrapperComponent_Host_0, {}, {}, []);
export { EstimateDetailsWrapperComponentNgFactory as EstimateDetailsWrapperComponentNgFactory };
