<a class="brand d-none d-sm-block"><img src="https://res.cloudinary.com/dmxgostd9/image/upload/web-assets/orchard-logo-dark.svg"></a>

<loading *ngIf="!dataLoaded"></loading>

<div class="main" *ngIf="dataLoaded && offer != null" [ngSwitch]="sectionPath">
  <!-- Expired -->
  <app-offer-expired id="expired" *ngSwitchCase="'expired'" [offer]="offer"></app-offer-expired>

  <!-- Active -->
  <app-intro-tab id="introTab" *ngSwitchCase="'intro-tab'" [offer]="offer"></app-intro-tab>
  <app-market-analysis id="marketAnalysisTab" *ngSwitchCase="'market-analysis'" [offer]="offer"></app-market-analysis>
  <app-comps id="compsTab" *ngSwitchCase="'comps'" [offer]="offer"></app-comps>
  <app-home-details id="homeDetailsTab" *ngSwitchCase="'home-details'" [offer]="offer"></app-home-details>
  <app-offer-details id="offerTab" *ngSwitchCase="'offer'" [offer]="offer"></app-offer-details>
  <ng-container *ngSwitchCase="'next-steps'">
      <app-next-steps id="nextSteps" *ngIf="offer.type !== OfferType.NET_OF_REPAIRS" [offer]="offer"></app-next-steps>
      <app-next-steps-net-of-repairs id="nextSteps" *ngIf="offer.type === OfferType.NET_OF_REPAIRS" [offer]="offer"></app-next-steps-net-of-repairs>
  </ng-container>
  <app-intro-tab *ngSwitchDefault [offer]="offer"></app-intro-tab>
</div>
