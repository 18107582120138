<svg width="72px" height="72px" viewBox="0 0 72 72" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
  <title>step 4</title>
  <defs>
    <polygon id="path-1" points="0 0.765945946 49.5065278 0.765945946 49.5065278 50.5945946 0 50.5945946"></polygon>
  </defs>
  <g id="step-4" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <g id="Page-1" transform="translate(11.000000, 10.000000)">
      <path d="M45.3802778,42.3076216 L24.0066667,42.3076216 C23.4552778,42.3076216 23.0080556,41.8550811 23.0080556,41.2957297 C23.0080556,40.7377838 23.4552778,40.2852432 24.0066667,40.2852432 L45.3802778,40.2852432 C46.4830556,40.2852432 47.415,39.598 47.415,38.7870811 L47.415,14.3695676 C47.415,13.5586486 46.4830556,12.8728108 45.3802778,12.8728108 L41.6788889,12.8728108 C41.1261111,12.8728108 40.6788889,12.4188649 40.6788889,11.8609189 C40.6788889,11.3015676 41.1261111,10.849027 41.6788889,10.849027 L45.3802778,10.849027 C47.6052778,10.849027 49.4136111,12.4272973 49.4136111,14.3695676 L49.4136111,38.7870811 C49.4136111,40.7279459 47.6052778,42.3076216 45.3802778,42.3076216"
        id="Fill-1" fill="#75968A"></path>
      <path d="M14.3297222,42.3076216 L10.4880556,42.3076216 C8.26444444,42.3076216 6.45472222,40.7279459 6.45472222,38.7870811 L6.45472222,14.3695676 C6.45472222,12.4272973 8.26444444,10.849027 10.4880556,10.849027 L33.3936111,10.849027 C33.945,10.849027 34.3922222,11.3015676 34.3922222,11.8609189 C34.3922222,12.4188649 33.945,12.8728108 33.3936111,12.8728108 L10.4880556,12.8728108 C9.38527778,12.8728108 8.45472222,13.5586486 8.45472222,14.3695676 L8.45472222,38.7870811 C8.45472222,39.598 9.38527778,40.2852432 10.4880556,40.2852432 L14.3297222,40.2852432 C14.8811111,40.2852432 15.3297222,40.7377838 15.3297222,41.2957297 C15.3297222,41.8550811 14.8811111,42.3076216 14.3297222,42.3076216"
        id="Fill-3" fill="#75968A"></path>
      <g id="Group-7" transform="translate(0.000000, 0.639600)">
        <mask id="mask-2" fill="white">
          <use xlink:href="#path-1"></use>
        </mask>
        <g id="Clip-6"></g>
        <path d="M0.999583333,50.5945946 C0.448194444,50.5945946 -0.000416666667,50.1420541 -0.000416666667,49.5827027 L-0.000416666667,1.77783784 C-0.000416666667,1.21848649 0.448194444,0.765945946 0.999583333,0.765945946 L48.5065278,0.765945946 C49.0593056,0.765945946 49.5065278,1.21848649 49.5065278,1.77783784 C49.5065278,2.33578378 49.0593056,2.78832432 48.5065278,2.78832432 L1.99958333,2.78832432 L1.99958333,49.5827027 C1.99958333,50.1420541 1.55097222,50.5945946 0.999583333,50.5945946"
          id="Fill-5" fill="#75968A" mask="url(#mask-2)"></path>
      </g>
      <path d="M22.0119444,11.8759568 C21.4605556,11.8759568 21.0119444,11.4234162 21.0119444,10.8640649 L21.0119444,2.97271351 C21.0119444,2.41476757 21.4605556,1.96082162 22.0119444,1.96082162 C22.5633333,1.96082162 23.0119444,2.41476757 23.0119444,2.97271351 L23.0119444,10.8640649 C23.0119444,11.4234162 22.5633333,11.8759568 22.0119444,11.8759568"
        id="Fill-8" fill="#75968A"></path>
      <path d="M33.8555556,11.8759568 C33.3041667,11.8759568 32.8555556,11.4234162 32.8555556,10.8640649 L32.8555556,2.97271351 C32.8555556,2.41476757 33.3041667,1.96082162 33.8555556,1.96082162 C34.4069444,1.96082162 34.8555556,2.41476757 34.8555556,2.97271351 L34.8555556,10.8640649 C34.8555556,11.4234162 34.4069444,11.8759568 33.8555556,11.8759568"
        id="Fill-10" fill="#75968A"></path>
      <path d="M8.91388889,26.1221297 L8.92777778,26.1221297 L8.91388889,26.1221297 Z M38.2138889,10.4364 C37.9263889,10.4364 37.6347222,10.5108865 37.3680556,10.6654811 L9.41388889,26.9976973 C9.41388889,26.9991027 9.41388889,26.9991027 9.4125,26.9991027 C8.60277778,27.4713189 8.325,28.5239676 8.79305556,29.3461297 L16.1819444,42.2969405 C16.6486111,43.1162919 17.6916667,43.3987784 18.5,42.9265622 L46.4555556,26.5943459 C47.2666667,26.1207243 47.5458333,25.0680757 47.0791667,24.2487243 L39.6861111,11.2951027 C39.3736111,10.7455892 38.8013889,10.4364 38.2138889,10.4364 Z M17.6597222,45.179427 C16.3805556,45.179427 15.1347222,44.5076432 14.45,43.3088324 L7.05972222,30.3580216 C6.04166667,28.5689405 6.64861111,26.2767243 8.41388889,25.2465622 L36.3680556,8.91434595 C38.1319444,7.87715676 40.4,8.49694054 41.4180556,10.2818054 L48.8111111,23.235427 C49.3027778,24.0997514 49.4319444,25.1060216 49.175,26.0743459 C48.9194444,27.0412649 48.3083333,27.849373 47.4569444,28.3468865 L19.5013889,44.6791027 C18.9208333,45.0178054 18.2861111,45.179427 17.6597222,45.179427 Z"
        id="Fill-12" fill="#75968A"></path>
      <path d="M21.6009722,25.1656108 L21.6148611,25.1656108 L21.6009722,25.1656108 Z M23.3856944,25.4087459 C23.2551389,25.4087459 23.1273611,25.4424757 23.0134722,25.5085297 L22.1009722,26.0411784 L22.0995833,26.0425838 C21.9259722,26.1423676 21.80375,26.3068 21.7495833,26.5049622 C21.6981944,26.7045297 21.7245833,26.9097189 21.8231944,27.0839892 L24.8440278,32.3781514 C25.0523611,32.7435568 25.51625,32.8672324 25.8773611,32.6564216 L26.7856944,32.1251784 C26.9606944,32.0239892 27.0843056,31.8595568 27.1370833,31.6613946 C27.1884722,31.461827 27.1634722,31.2566378 27.0648611,31.0823676 L24.0440278,25.7882054 C23.9440278,25.6125297 23.7801389,25.4874486 23.5856944,25.4340432 C23.5190278,25.4171784 23.4523611,25.4087459 23.3856944,25.4087459 Z M25.50375,34.7828 C24.5495833,34.7828 23.6218056,34.2824757 23.1134722,33.3914486 L20.0926389,28.0972865 C19.7245833,27.4536108 19.6273611,26.7017189 19.8190278,25.9807459 C20.0093056,25.259773 20.46625,24.6596649 21.1009722,24.2900432 L22.0120833,23.7573946 C22.6481944,23.3835568 23.3898611,23.2879892 24.10375,23.4805297 C24.8148611,23.6730703 25.4093056,24.1340432 25.7759722,24.7777189 L28.7954167,30.0690703 C29.1620833,30.7127459 29.2606944,31.4646378 29.0690278,32.1856108 C28.8773611,32.9065838 28.4218056,33.5080973 27.7856944,33.8777189 L26.8773611,34.4089622 C26.4440278,34.6633405 25.9704167,34.7828 25.50375,34.7828 Z"
        id="Fill-14" fill="#75968A"></path>
      <path d="M38.9255556,27.2146919 C38.5797222,27.2146919 38.2436111,27.0333946 38.0588889,26.7101514 L33.2838889,18.3409622 C33.0061111,17.8560973 33.17,17.2363135 33.6477778,16.9580432 C34.1255556,16.6741514 34.7380556,16.8442054 35.0144444,17.3276649 L39.7894444,25.6968541 C40.0658333,26.1817189 39.9019444,26.8000973 39.4241667,27.0783676 C39.2672222,27.1725297 39.095,27.2146919 38.9255556,27.2146919"
        id="Fill-16" fill="#75968A"></path>
      <path d="M38.9065278,27.2009189 C38.5606944,27.2009189 38.2245833,27.0196216 38.0384722,26.694973 C37.7634722,26.2115135 37.9273611,25.5917297 38.4065278,25.3120541 L41.1190278,23.7281622 C41.4273611,23.5468649 41.5398611,23.1280541 41.3606944,22.8132432 L38.2468056,17.3588649 C38.0676389,17.0454595 37.6565278,16.9344324 37.3426389,17.1143243 L34.6301389,18.6982162 C34.1523611,18.9764865 33.54125,18.8106486 33.2648611,18.3271892 C32.9884722,17.8437297 33.1523611,17.2239459 33.6315278,16.9442703 L36.3440278,15.3603784 C37.6093056,14.6239459 39.2440278,15.061027 39.97875,16.3441622 L43.0926389,21.7999459 C43.8259722,23.0858919 43.3898611,24.7372432 42.1190278,25.4807027 L39.40375,27.066 C39.2468056,27.1587568 39.0759722,27.2009189 38.9065278,27.2009189"
        id="Fill-18" fill="#75968A"></path>
      <path d="M32.2998611,31.0850378 C31.9540278,31.0850378 31.6179167,30.9037405 31.4331944,30.5804973 L26.6581944,22.2113081 C26.3804167,21.7264432 26.5443056,21.1066595 27.0220833,20.8283892 C27.50125,20.5444973 28.1123611,20.7159568 28.38875,21.1980108 L33.16375,29.5672 C33.4401389,30.0520649 33.27625,30.6704432 32.7984722,30.9487135 C32.6415278,31.0428757 32.4693056,31.0850378 32.2998611,31.0850378"
        id="Fill-20" fill="#75968A"></path>
      <path d="M32.2984722,31.0850378 C31.9526389,31.0850378 31.6165278,30.9037405 31.4304167,30.5804973 C31.1554167,30.0956324 31.3193056,29.4758486 31.7970833,29.196173 L35.3943056,27.0950919 C35.8706944,26.8154162 36.4845833,26.9826595 36.7609722,27.4661189 C37.0359722,27.9509838 36.8720833,28.5693622 36.3943056,28.8490378 L32.7970833,30.9501189 C32.6401389,31.0428757 32.4679167,31.0850378 32.2984722,31.0850378"
        id="Fill-22" fill="#75968A"></path>
      <path d="M17.6594444,39.3925297 C17.2761111,39.3925297 16.9108333,39.1690703 16.7455556,38.7896108 C16.5219444,38.2794486 16.7497222,37.6807459 17.2552778,37.4558811 L18.7025,36.8079892 C19.8969444,36.2725297 20.4997222,35.5613946 20.2733333,34.9514486 C20.0552778,34.3583676 19.0219444,33.7652865 17.6497222,34.1911243 C15.2011111,34.9500432 13.1469444,33.7807459 12.5636111,32.2052865 C12.09,30.9249622 12.4969444,28.9981514 15.1997222,27.7880973 L16.6469444,27.1416108 C17.1511111,26.9167459 17.7427778,27.1444216 17.965,27.6573946 C18.1886111,28.1689622 17.9594444,28.7662595 17.4552778,28.9925297 L16.0094444,29.6390162 C14.8136111,30.1730703 14.2122222,30.8856108 14.4386111,31.4969622 C14.6580556,32.0914486 15.6955556,32.6859351 17.0608333,32.2572865 C19.5052778,31.4927459 21.5663889,32.6690703 22.1469444,34.2431243 C22.6191667,35.5248541 22.2136111,37.4488541 19.5108333,38.6589081 L18.0636111,39.3053946 C17.9316667,39.3644216 17.7955556,39.3925297 17.6594444,39.3925297"
        id="Fill-24" fill="#75968A"></path>
    </g>
  </g>
</svg>