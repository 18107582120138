<ng-container [ngSwitch]="estimate.version">

  <ng-template [ngSwitchCase]="3">
    <app-estimate-details-v3 [estimate]="estimate"></app-estimate-details-v3>
  </ng-template>

  <ng-template ngSwitchDefault>
    <ng-container [ngSwitch]="testGroup?.name">

      <ng-container *ngSwitchCase="TestGroupName.EXPERIMENT">
        <app-estimate-details-v2 [estimate]="estimate"></app-estimate-details-v2>
      </ng-container>

      <ng-container *ngSwitchDefault>
        <app-estimate-details [estimate]="estimate"></app-estimate-details>
      </ng-container>

    </ng-container>
  </ng-template>

</ng-container>
