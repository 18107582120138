import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Configuration, Offer } from 'models';
import { map } from 'rxjs/operators';
import { camelCaseKeys } from 'utils';


@Injectable({ providedIn: 'root' })
export class OffersService {

  constructor(
    private http: HttpClient,
    @Inject('CONFIGS') private configs: Configuration
  ) { }

  async getOfferData(offerToken: string): Promise<Offer> {
    return this.http
      .get(`${this.configs.ENDPOINT_URL}/api/v1/seller_offer/by_token/${offerToken}`)
      .pipe(map((it) => camelCaseKeys(it) as Offer))
      .toPromise();
  }

  sendNewOfferRequest(offerToken: string) {
    return this.http
      .post(`${this.configs.ENDPOINT_URL}/api/v1/seller_offer/${offerToken}/request_extension`, {})
      .toPromise();
  }
}
