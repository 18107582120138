<willow-layout [address]="offer.subjectProperty?.address">
  <perch-icons-letter role="icon"></perch-icons-letter>

  <div role="header">
    We&rsquo;re excited to share our recommended list price and your guaranteed offer amount.
  </div>

  <a id="next-step" class="btn btn-sm btn-primary" [routerLink]="['../details']">
    Next Step
  </a>
</willow-layout>
