/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./popover-info-label.component";
var styles_PopoverInfoLabelComponent = [];
var RenderType_PopoverInfoLabelComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PopoverInfoLabelComponent, data: {} });
export { RenderType_PopoverInfoLabelComponent as RenderType_PopoverInfoLabelComponent };
function View_PopoverInfoLabelComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n      "])), i0.ɵncd(null, 0), (_l()(), i0.ɵted(-1, null, ["\n    "]))], null, null); }
export function View_PopoverInfoLabelComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(402653184, 1, { template: 0 }), (_l()(), i0.ɵted(-1, null, ["\n    "])), (_l()(), i0.ɵand(0, [[1, 2], ["labelTemplate", 2]], null, 0, null, View_PopoverInfoLabelComponent_1)), (_l()(), i0.ɵted(-1, null, ["\n  "]))], null, null); }
export function View_PopoverInfoLabelComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "popover-info-label", [], null, null, null, View_PopoverInfoLabelComponent_0, RenderType_PopoverInfoLabelComponent)), i0.ɵdid(1, 49152, null, 0, i1.PopoverInfoLabelComponent, [], null, null)], null, null); }
var PopoverInfoLabelComponentNgFactory = i0.ɵccf("popover-info-label", i1.PopoverInfoLabelComponent, View_PopoverInfoLabelComponent_Host_0, {}, {}, ["*"]);
export { PopoverInfoLabelComponentNgFactory as PopoverInfoLabelComponentNgFactory };
