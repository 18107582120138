export enum ServiceName {
  LIST_WITH_BACKSTOP = 'LIST_WITH_BACKSTOP',
  SELL_ONLY = 'SELL_ONLY',
  HOMEBREEZE = 'HOMEBREEZE',
  BUY_AND_SELL_V2 = 'BUY_AND_SELL_V2',
  BUY_AND_SELL_V3 = 'BUY_AND_SELL_V3',
  BUY_AND_LIST = 'BUY_AND_LIST',
  BUY_ONLY = 'BUY_ONLY',
  LIST_ONLY = 'LIST_ONLY'
}
