<perch-logo></perch-logo>

<ng-container [ngSwitch]="offer.subjectProperty?.details?.serviceName">
  <ng-container *ngSwitchCase="ServiceName.LIST_WITH_BACKSTOP">
    <app-list-with-backstop-offer [offer]="offer"></app-list-with-backstop-offer>
  </ng-container>
  <ng-container *ngSwitchDefault>
    <app-buy-and-sell-offer [offer]="offer"></app-buy-and-sell-offer>
  </ng-container>
</ng-container>

<willow-disclaimer>
  <div class="content-block">
    <h5 class="stat-label text-label">Data Sources</h5>
    <p class="text-body-xs">
      Orchard leverages up-to-date data from county records and local MLS'. We combine this data with Orchard's proprietary
      valuation model to provide you with a comprehensive, simple and accurate estimate. For questions, please contact Orchard
      at <a href="mailto:support@orchard.com">support@orchard.com</a>.
    </p>
  </div>
  <div class="content-block">
    <h5 class="stat-label text-label">Disclaimer</h5>
    <p class="text-body-xs">
      Orchard certifies that, to the best of its knowledge, the statements of fact contained in this report are true and
      correct. Orchard is not acting as a real estate agent or broker, Orchard is only acting as a prospective purchaser of real
      estate. It is the seller's responsibility to independently evaluate and decide whether to accept the Orchard offer.
    </p>
    <p class="text-body-xs">
      This represents an estimated sale price for this property. It is not the same as the opinion of value in an appraisal
      developed by a licensed appraiser under the Uniform Standards of Professional Appraisal Practice. The guaranteed offer amount is the purchase price Orchard is willing to pay for your home.
    </p>
    <p class="text-body-xs">
      The Orchard fee is intended to cover the expenses Orchard incurs purchasing and reselling your home. Home repairs or
      structural issues not identified in the information you provided Orchard are not included in those costs.
    </p>
    <p *ngIf="isTexas" class="text-body-xs">
      Texas real estate law requires agents provide the
      <a target="_blank" href="https://s3.amazonaws.com/perch-website-assets/files/disclosures/trec/CN.pdf">
        TREC Consumer Protection Notice
      </a>
      and
      <a target="_blank" href="https://s3.amazonaws.com/perch-website-assets/files/disclosures/trec/IABS.pdf">
        Information About Brokerage Services
      </a> to all consumers.
    </p>
    <p class="text-body-xs">
      Orchard is the consumer brand of Orchard Property I.
    </p>
  </div>
</willow-disclaimer>
