/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./bedrooms.component";
var styles_IconBedrooms = [];
var RenderType_IconBedrooms = i0.ɵcrt({ encapsulation: 2, styles: styles_IconBedrooms, data: {} });
export { RenderType_IconBedrooms as RenderType_IconBedrooms };
export function View_IconBedrooms_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, ":svg:svg", [[":xml:space", "preserve"], [":xmlns:a", "http://ns.adobe.com/AdobeSVGViewerExtensions/3.0/"], [":xmlns:xlink", "http://www.w3.org/1999/xlink"], ["height", "41px"], ["style", "enable-background:new 0 0 46 41;"], ["version", "1.1"], ["viewBox", "0 0 46 41"], ["width", "46px"], ["x", "0px"], ["xmlns", "http://www.w3.org/2000/svg"], ["y", "0px"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n"])), (_l()(), i0.ɵeld(2, 0, null, null, 5, ":svg:g", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t"])), (_l()(), i0.ɵeld(4, 0, null, null, 0, ":svg:path", [["class", "st0"], ["d", "M42.8,22l-3-13H36c0-1.7-1.3-3-3-3h-8c-0.8,0-1.5,0.3-2,0.8C22.5,6.3,21.8,6,21,6h-8c-1.7,0-3,1.3-3,3H6.2\n\t\tl-3,13H0v19h4.5l2-3h32.9l2,3H46V22H42.8z M25,8h8c0.6,0,1,0.4,1,1v2c0,0.6-0.4,1-1,1h-8c-0.6,0-1-0.4-1-1V9C24,8.4,24.4,8,25,8z\n\t\t M12,9c0-0.6,0.4-1,1-1h8c0.6,0,1,0.4,1,1v2c0,0.6-0.4,1-1,1h-8c-0.6,0-1-0.4-1-1V9z M7.8,11H10c0,1.7,1.3,3,3,3h8\n\t\tc0.8,0,1.5-0.3,2-0.8c0.5,0.5,1.2,0.8,2,0.8h8c1.7,0,3-1.3,3-3h2.2l2.5,11H5.3L7.8,11z M44,39h-1.5l-2-3H5.5l-2,3H2V24h42V39z"], ["style", "fill:#75968A;"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t"])), (_l()(), i0.ɵeld(6, 0, null, null, 0, ":svg:polygon", [["class", "st0"], ["points", "4,2 23,2 42,2 42,15 44,15 44,0 23,0 2,0 2,15 4,15 \t"], ["style", "fill:#75968A;"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n"])), (_l()(), i0.ɵted(-1, null, ["\n"])), (_l()(), i0.ɵted(-1, null, ["\n"]))], null, null); }
export function View_IconBedrooms_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "perch-icons-bedrooms", [], null, null, null, View_IconBedrooms_0, RenderType_IconBedrooms)), i0.ɵdid(1, 114688, null, 0, i1.IconBedrooms, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var IconBedroomsNgFactory = i0.ɵccf("perch-icons-bedrooms", i1.IconBedrooms, View_IconBedrooms_Host_0, {}, {}, []);
export { IconBedroomsNgFactory as IconBedroomsNgFactory };
