<div class="step" *ngIf="offer">
  <section class="section py-0">
    <div class="container-fluid px-0">

      <div class="col-sm-8 offset-sm-2 text-center section-body">
        <div class="px-2">
          <h1>Your Orchard offer</h1>
          <div id="offer-amount" class="stat-number mb-3">
            <sup>$</sup>{{offer.amount | number: '1.0-0'}}
          </div>
        </div>
      </div>

      <div class="col-sm-8 offset-sm-2 text-center mb-2 pb-2">
        <p *ngIf="testGroup?.name == 'EXPERIMENT'; else offerValid" class="text sell-for-more-tagline">
          Want to sell for even more?<br/>
          <a (click)="promotion.scrollIntoView({
              behavior: 'smooth',
              block: 'start'
            })" class="underlined-link">
            Learn more
          </a>
        </p>
        <ng-template #offerValid>
          <p class="text-muted stat-label" >
            <app-valid-thru [offer]="offer"></app-valid-thru>
          </p>
        </ng-template>
      </div>

      <div class="col-sm-12 px-0">

        <div class="px-2">
          <p class="sub-heading sentence text-center mb-3" style="font-size: 28px; line-height: 1.2">
            See how Orchard compares to a traditional sale process
          </p>
        </div>

        <app-offer-comparison-table [offer]="offer"></app-offer-comparison-table>

      </div>
      <div #promotion>
        <div class="pt-4 align-items-center d-flex flex-column justify-content-center" *ngIf="testGroup?.name == 'EXPERIMENT'">
          <p class="text-muted stat-label app-valid-wrapper">
            <app-valid-thru [offer]="offer"></app-valid-thru>
          </p>
          <div class="text-center cross-promote-box">
            <h3>Want to get the highest sale price?</h3>
            <p class="cross-promote-description">
              Our new Buy & Sell service lets you buy before you sell, then list your home for top dollar.
              If your home doesn't sell in {{ offer.listingPeriodInterval }} days, fall back on your Orchard cash offer.
            </p>
            <p class="cross-promote-link">
              Click here to
              <a class="underlined-link" target="_blank" href="https://calendly.com/orchard-cx/orchard-buy-sell-consult">
                request a free phone consultation
              </a>
              to learn more
            </p>
          </div>
        </div>
      </div>

    </div>
  </section>


  <section class="section">
    <div class="container-fluid">
      <div class="row justify-content-center">
        <div class="col-sm-9 col-md-6 text-center small text-muted">
          <div class="content-block" style="margin-bottom:48px;">
            <h5 class="stat-label">Data Sources</h5>
            <p>
              Orchard leverages up-to-date data from county records and local MLS'.
              We combine this data with Orchard's proprietary valuation model to provide you with a comprehensive,
              simple and accurate offer. For questions, please contact Orchard at
              <a target="_blank" href="mailto:support@orchard.com">support@orchard.com</a>.
            </p>
          </div>
          <div class="content-block">
            <h5 class="stat-label">Disclaimer</h5>
            <p>
              Orchard certifies that, to the best of its knowledge, the statements of fact contained in this
              report are true and correct. Orchard is not acting as a real estate agent or broker, Orchard is only
              acting as a prospective purchaser of real estate. It is the seller's responsibility to independently
              evaluate and decide whether to accept the Orchard offer.
            </p>
            <p>
              The offer price presented is not an appraisal, and does not necessarily represent the "market value"
              of your home. The offer price is the purchase price Orchard is willing to pay for your home.
            </p>
            <p>
              The Orchard fee is intended to cover the expenses Orchard incurs purchasing and reselling your home.
              Home repairs or structural issues not identified in the information you provided Orchard are not included in those costs.
            </p>
            <p *ngIf="isTexas">
              Texas real estate law requires agents provide the
              <a target="_blank" href="https://assets.orchardhomes.com/files/disclosures/trec/CN.pdf">
                TREC Consumer Protection Notice
              </a>
              and
              <a target="_blank" href="https://assets.orchardhomes.com/files/disclosures/trec/IABS.pdf">
                Information About Brokerage Services
              </a>
              to all consumers.
            </p>
            <p>Orchard is the consumer brand of Orchard Property I.</p>
          </div>
        </div>
      </div>

    </div>
  </section>

  <div class="steps-footer">
    <div class="container-fluid">
      <a class="btn btn-sm btn-outline-primary" [routerLink]="['/offer', offer.token, previousStep]">Previous Step</a>
      <a class="btn btn-sm btn-primary float-right" [routerLink]="['/offer/' + offer.token + '/next-steps']">Next Step</a>
    </div>
  </div>
</div>
