<svg version="1.1"
	 xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:a="http://ns.adobe.com/AdobeSVGViewerExtensions/3.0/"
	 x="0px" y="0px" width="48px" height="42px" viewBox="0 0 48 42" style="enable-background:new 0 0 48 42;" xml:space="preserve">
<defs>
</defs>
<g>
	<path class="st0" style="fill:#75968A;" d="M35,25H25c-0.6,0-1,0.4-1,1v8c0,0.6,0.4,1,1,1h10c0.6,0,1-0.4,1-1v-8C36,25.4,35.6,25,35,25z M34,33h-8v-6h8
		V33z"/>
	<path class="st0" style="fill:#75968A;" d="M42,40V28c0.3,0,0.5-0.1,0.7-0.3l4-4c0.4-0.4,0.4-1,0-1.4l-22-22c-0.4-0.4-1-0.4-1.4,0L16,7.6V4
		c0-0.6-0.4-1-1-1H9C8.4,3,8,3.4,8,4v11.6l-6.7,6.7c-0.4,0.4-0.4,1,0,1.4L5,27.4V40H0v2h6h7h8h20h7v-2H42z M10,5h4v4.6l-4,4V5z
		 M24,2.4L44.6,23L42,25.6L24.7,8.3C24.5,8.1,24.3,8,24,8s-0.5,0.1-0.7,0.3L6,25.6L3.4,23L24,2.4z M14,40V27h6v13H14z M22,40V26
		c0-0.6-0.4-1-1-1h-8c-0.6,0-1,0.4-1,1v14H7V27.4l17-17l16,16V40H22z"/>
</g>
</svg>
