import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { HeapAnalyticsService } from 'app/providers';
import { Estimate, TestGroup } from 'models';

import { HEAP_EXPERIMENT_NAME, TEST_NAME } from './constants';
import { TestGroupName } from './models';

@Component({
  selector: 'app-estimate-details-wrapper',
  templateUrl: './estimate-details-wrapper.component.html'
})
export class EstimateDetailsWrapperComponent implements OnInit {

  estimate: Estimate;
  testGroup?: TestGroup;
  TestGroupName = TestGroupName;

  constructor(
    private route: ActivatedRoute,
    private heapAnalyticsService: HeapAnalyticsService
  ) { }

  ngOnInit() {
    this.estimate = this.route.snapshot.data.estimate;
    this.setTestGroup();
    this.reportTestGroup();
  }

  private setTestGroup(): void {
    if (this.estimate.testGroup == null || this.estimate.testGroup.testName !== TEST_NAME) {
      this.testGroup = undefined;
      return;
    }

    this.testGroup = this.estimate.testGroup;
  }

  private reportTestGroup(): void {
    if (this.testGroup != null) {
      this.heapAnalyticsService.track(HEAP_EXPERIMENT_NAME, { 'group': this.testGroup.name });
    }
  }

}
