import { Component, TemplateRef, ViewChild } from '@angular/core';

@Component({
  selector: 'popover-info-label',
  template: `
    <ng-template #labelTemplate>
      <ng-content></ng-content>
    </ng-template>
  `
})
export class PopoverInfoLabelComponent {

  @ViewChild('labelTemplate') template: TemplateRef<never>;

}
