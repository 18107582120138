import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Offer, OfferType } from 'models';

@Component({
  selector: 'app-valid-thru',
  templateUrl: './valid-thru.component.html',
  styleUrls: ['./valid-thru.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ValidThruComponent {
  @Input()
  public offer: Offer;

  get date(): string {
    if (this.offer.type === OfferType.NET_OF_REPAIRS) {
      return this.offer.optionEndDate || this.offer.expirationDate;
    } else {
      return this.offer.expirationDate;
    }
  }
}
