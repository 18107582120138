<div class="step" *ngIf="offer">
  <section class="section" *ngIf="offer.marketAnalysis.onMarketSummary as onMarketSummary">
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-8 offset-sm-2 text-center section-body" style="margin-bottom:30px;">
          <h1>Market trends in {{offer.marketAnalysis.zipcode}}</h1>
          <p>Here are the home sale trends in your area</p>
        </div>

        <div class="col-sm-10 offset-sm-1 text-center">
          <div class="row">
            <div class="col-md">
              <p class="stat-label">Number of new listings<br> last month</p>
              <div class="stat-number">{{onMarketSummary.newListingsLastMonth}}</div>
              <p>Last month, {{onMarketSummary.newListingsLastMonth}} homes were listed for sale in your area</p>
            </div>
            <div class="col-md">
              <p class="stat-label">Average days<br> on market</p>
              <div class="stat-number">{{onMarketSummary.avgDom | number: '1.0-0'}}</div>
              <p>Homes that sold in your zip code last month were on the market for an average of {{onMarketSummary.avgDom}} days</p>
            </div>
            <div class="col-md">
              <p class="stat-label">Percent of homes<br> on market over 180 days</p>
              <div class="stat-number">{{(onMarketSummary.pctDomOverThreshold * 100).toFixed(0)}}<sup>%</sup></div>
              <p>{{(onMarketSummary.pctDomOverThreshold * 100).toFixed(0)}}% of homes listed in your zip code have been on the market for over 180 days</p>
            </div>
          </div>
        </div>

      </div>
    </div>
  </section>

  <section class="section section-bg-secondary" *ngIf="offer.marketAnalysis.avgSoldPriceChart as chart">
    <div class="container-fluid">
      <div class="col-sm-8 offset-sm-2 text-center section-body">
        <p class="sub-heading">
          Over the last 3 months, The average selling price for homes in your zipcode is {{chart.avgClosePrice | currency: 'USD': 'currency-narrow':'1.0-0'}}.</p>
        <p>
          This is {{chart.closePriceChangePct < 0 ? "a decrease" : "an increase"}}
          of {{abs(chart.closePriceChangePct * 100).toFixed(2)}}% compared to the same period of last year
        </p>
      </div>
    </div>
  </section>

  <div class="steps-footer">
    <div class="container-fluid">
      <a class="btn btn-sm btn-outline-primary" [routerLink]="['/offer/' + offer.token + '/intro-tab']">Previous Step</a>
      <a class="btn btn-sm btn-primary float-right" [routerLink]="['/offer/' + offer.token + '/comps']">Next Step</a>
    </div>
  </div>
</div>
