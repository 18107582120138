import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NotFoundComponent, OfferComponent } from './views';

const routes: Routes = [
  { path: 'offer/:offerId', redirectTo: 'offer/:offerId/intro-tab'},
  { path: 'offer/:offerId/:sectionPath', component: OfferComponent },
  { path: '404', component: NotFoundComponent },
  { path: '**', redirectTo: '/404' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    anchorScrolling: 'enabled'
  })],
  exports: [RouterModule],
  providers: []
})
export class PerchOffersRoutingModule {}
