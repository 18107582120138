<div class="d-flex justify-content-center">
  <div class="table-wrapper">

    <!-- logo -->
    <div class="logo-row d-flex">
      <div class="spacer-left d-none d-sm-block"></div>
      <div class="logo-cell d-flex justify-content-center align-items-center py-2">
        <img
          class="logo"
          src="https://res.cloudinary.com/dmxgostd9/image/upload/web-assets/orchard-logo-dark.svg"
          alt="Orchard">
      </div>
      <div class="spacer-right">
        Traditional Process
      </div>
    </div>

    <!-- price -->
    <div class="offer-row" id="comparison-offer-price">
      <div class="offer-cell offer-cell--label">
        Offer Price
      </div>
      <div class="offer-cell offer-cell--data offer-cell--perch js-sell-only">
        <span class="cell-value">
          {{ offer.amount | currency:"USD":"symbol-narrow":"1.0-0" }}
        </span>
      </div>
      <div class="offer-cell offer-cell--data offer-cell--traditional js-traditional">
        <span class="cell-value">
          {{ offer.amount | currency:"USD":"symbol-narrow":"1.0-0" }}
        </span>
      </div>
    </div>

    <div class="divider"></div>

    <!-- timeline rows -->
    <div class="offer-row" id="days-to-close">
      <div class="offer-cell offer-cell--label">
        Average Days to Close
      </div>
      <div class="offer-cell offer-cell--data offer-cell--perch js-sell-only">
        <span class="cell-annotation js-cell-annotation">Your Choice</span>
        <span>14–60 days</span>
      </div>
      <div class="offer-cell offer-cell--data offer-cell--traditional js-traditional">
        90 days
      </div>
    </div>
    <div class="offer-row" id="days-to-prep">
      <div class="offer-cell offer-cell--label">
        Average Days to Prep Home
      </div>
      <div class="offer-cell offer-cell--data offer-cell--perch js-sell-only">
        0 days
      </div>
      <div class="offer-cell offer-cell--data offer-cell--traditional js-traditional">
        10 days
      </div>
    </div>
    <div class="offer-row">
      <div class="offer-cell offer-cell--label">
        Average Number of Showings
      </div>
      <div class="offer-cell offer-cell--data offer-cell--perch js-sell-only">
        0 showings
      </div>
      <div class="offer-cell offer-cell--data offer-cell--traditional js-traditional">
        10 showings
      </div>
    </div>

    <div class="divider"></div>

    <!-- selling costs -->
    <div class="offer-row" id="perch-fee">
      <div class="offer-cell offer-cell--label position-relative pt-sm-5">
        <div class="section-label">Selling Costs</div>
        <popover-info placement="top">
          <popover-info-label>Orchard Fee</popover-info-label>
          <span [innerHTML]="tooltips.perchFee"></span>
        </popover-info>
      </div>
      <div class="offer-cell offer-cell--data offer-cell--perch js-sell-only flex-row-md pt-sm-5">
        <span class="cell-value">{{sellOnly.offerFee.pct | percent:'1.0-1'}}</span>
        <span class="cell-annotation js-cell-annotation">({{sellOnly.offerFee.dollars | currency:'USD':'symbol-narrow':'1.0-0' }})</span>
      </div>
      <div class="offer-cell offer-cell--data offer-cell--traditional js-traditional pt-sm-5">
        –
      </div>
    </div>
    <div class="offer-row" id="agent-fee">
      <div class="offer-cell offer-cell--label">
        Real Estate Agent Fees
      </div>
      <div class="offer-cell offer-cell--data offer-cell--perch">
        <span class="cell-value">0%</span>
      </div>
      <div class="offer-cell offer-cell--data offer-cell--traditional js-traditional flex-row-md">
        <span class="cell-value">{{traditional.agentFees.pct | percent:'1.0-1'}}</span>
        <span class="cell-annotation">({{traditional.agentFees.dollars | currency:'USD':'symbol-narrow':'1.0-0' }})</span>
      </div>
    </div>
    <div class="offer-row" id="housing-overlap-costs">
      <div class="offer-cell offer-cell--label">
        <popover-info placement="top">
          <popover-info-label>Housing Overlap Costs</popover-info-label>
          <span [innerHTML]="tooltips.mortgageRentOverlap"></span>
        </popover-info>
      </div>
      <div class="offer-cell offer-cell--data offer-cell--perch">
        <span class="cell-value">0%</span>
      </div>
      <div class="offer-cell offer-cell--data offer-cell--traditional js-traditional flex-row-md">
        <span class="cell-value">{{traditional.mortgageRentOverlap.pct | percent:'1.0-1'}}</span>
        <span class="cell-annotation js-cell-annotation">({{traditional.mortgageRentOverlap.dollars | currency:'USD':'symbol-narrow':'1.0-0' }})</span>
      </div>
    </div>
    <div class="offer-row" id="seller-concessions">
      <div class="offer-cell offer-cell--label">
        <popover-info placement="top">
          <popover-info-label>Seller Concessions</popover-info-label>
          <span [innerHTML]="tooltips.sellerConcessions"></span>
        </popover-info>
      </div>
      <div class="offer-cell offer-cell--data offer-cell--perch">
        <span class="cell-value">0%</span>
      </div>
      <div class="offer-cell offer-cell--data offer-cell--traditional js-traditional flex-row-md">
        <span class="cell-value">{{traditional.sellerConcession.pct | percent:'1.0-1'}}</span>
        <span class="cell-annotation js-cell-annotation">({{traditional.sellerConcession.dollars | currency:'USD':'symbol-narrow':'1.0-0' }})</span>
      </div>
    </div>
    <div
        *ngIf="offer.type === offerTypes.GROSS_OF_REPAIRS"
        class="offer-row"
        id="repairs-costs"
        [attr.data-repairs-range-low]="defectEstimate.low"
        [attr.data-repairs-range-high]="defectEstimate.high">
      <div class="offer-cell offer-cell--label">
        <popover-info placement="top">
          <popover-info-label>Repair Costs</popover-info-label>
          <ng-template [ngTemplateOutlet]="repairsTooltipTemplate"></ng-template>
        </popover-info>
      </div>
      <div class="offer-cell offer-cell--data offer-cell--perch">
        TBD
      </div>
      <div class="offer-cell offer-cell--data offer-cell--traditional js-traditional">
        TBD
      </div>
    </div>

    <div class="divider"></div>

    <!-- closing costs -->
    <div class="offer-row" id="title-escrow-notary">
      <div class="offer-cell offer-cell--label position-relative pt-sm-5">
        <div class="section-label">Closing Costs</div>
        <popover-info placement="top">
          <popover-info-label>Title, Escrow, and Notary</popover-info-label>
          <span [innerHTML]="tooltips.titleEscrowNotary"></span>
        </popover-info>
      </div>
      <div class="offer-cell offer-cell--data offer-cell--perch js-sell-only flex-row-md pt-sm-5">
        <span class="cell-value">
          {{(sellOnly.titleEscrowNotary.pct || 0.01) | percent:'1.0-0'}}
        </span>
        <span class="cell-annotation js-cell-annotation">
          ({{sellOnly.titleEscrowNotary.dollars | currency:'USD':'symbol-narrow':'1.0-0'}})
        </span>
      </div>
      <div class="offer-cell offer-cell--data offer-cell--traditional js-traditional flex-row-md pt-sm-5">
        <span class="cell-value">
          {{(sellOnly.titleEscrowNotary.pct || 0.01) | percent:'1.0-0'}}
        </span>
        <span class="cell-annotation js-cell-annotation">
          ({{sellOnly.titleEscrowNotary.dollars | currency:'USD':'symbol-narrow':'1.0-0'}})
        </span>
      </div>
    </div>
    <div class="offer-row" id="hoa-transfer-fees">
      <div class="offer-cell offer-cell--label">
        <popover-info placement="top">
          <popover-info-label>HOA Transfer Fees</popover-info-label>
          <span [innerHTML]="tooltips.hoaTransferFees"></span>
        </popover-info>
      </div>
      <div class="offer-cell offer-cell--data offer-cell--perch">
        TBD
      </div>
      <div class="offer-cell offer-cell--data offer-cell--traditional js-traditional">
        TBD
      </div>
    </div>
    <div class="offer-row" id="hoa-transfer-fees">
      <div class="offer-cell offer-cell--label">
        <popover-info placement="top">
          <popover-info-label>Property Taxes</popover-info-label>
          <span [innerHTML]="tooltips.propertyTaxes"></span>
        </popover-info>
      </div>
      <div class="offer-cell offer-cell--data offer-cell--perch">
        TBD
      </div>
      <div class="offer-cell offer-cell--data offer-cell--traditional js-traditional">
        TBD
      </div>
    </div>
    <div class="divider"></div>

    <!-- cash at closing -->
    <div class="offer-row" id="cash-at-closing">
      <div class="offer-cell offer-cell--label">
        Estimated cash before paying off mortgage
      </div>
      <div class="offer-cell offer-cell--data offer-cell--perch js-sell-only">
        <span class="cell-value">
          {{sellOnly.cashAtClosing | currency:'USD':'symbol-narrow':'1.0-0'}}
        </span>
      </div>
      <div class="offer-cell offer-cell--data offer-cell--traditional js-traditional">
        <span class="cell-value">
          {{traditional.cashAtClosing | currency:'USD':'symbol-narrow':'1.0-0'}}
        </span>
      </div>
    </div>
  </div>
</div>

<ng-template #repairsTooltipTemplate>
  <div id="repairs-costs-tooltip">
    <p>
      If you choose to accept your Orchard offer, we will conduct a complimentary home assessment to determine your home's condition.
      We will provide you with a summary of repairs required and the cost for Orchard to perform them after you move. This amount would be deducted from your offer price. You have the option to then accept or decline that updated offer amount.
    </p>
    <p>
      Based on the information you provided about your home,
      repairs typically range from
      <span id="repairs-range-low">
        {{ defectEstimate.low | currency:'USD':'symbol-narrow':'1.0-0' }}
      </span>
      to
      <span id="repairs-range-high">
        {{ defectEstimate.high | currency:'USD':'symbol-narrow':'1.0-0' }}
      </span>.
      This is just an estimate and doesn't assume any repair costs that could only be estimated by seeing your home in-person.
    </p>
  </div>
</ng-template>
