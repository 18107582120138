<!-- Generator: Adobe Illustrator 22.1.0, SVG Export Plug-In  -->
<svg version="1.1"
	 xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:a="http://ns.adobe.com/AdobeSVGViewerExtensions/3.0/"
	 x="0px" y="0px" width="40.3px" height="48.4px" viewBox="0 0 40.3 48.4" style="enable-background:new 0 0 40.3 48.4;"
	 xml:space="preserve">
<defs>
</defs>
<g>
	<path class="st0" d="M14.1,13.1h1v3c0,0.6,0.5,1,1,1h8.1c0.6,0,1-0.5,1-1v-3h1c0.4,0,0.8-0.2,0.9-0.6c0.2-0.4,0.1-0.8-0.2-1.1l-6-6
		c-0.4-0.4-1-0.4-1.4,0l-6,6c-0.3,0.3-0.4,0.7-0.2,1.1C13.3,12.9,13.7,13.1,14.1,13.1z M20.2,7.5l3.7,3.7c-0.4,0.1-0.7,0.5-0.7,0.9
		v3h-2v-2c0-0.6-0.5-1-1-1s-1,0.5-1,1v2h-2v-3c0-0.4-0.3-0.8-0.7-0.9L20.2,7.5z"/>
	<path class="st0" d="M31.2,20.2H9.1c-0.6,0-1,0.5-1,1c0,0.6,0.5,1,1,1h22.2c0.6,0,1-0.5,1-1C32.3,20.6,31.8,20.2,31.2,20.2z"/>
	<path class="st0" d="M31.2,25.2H9.1c-0.6,0-1,0.5-1,1c0,0.6,0.5,1,1,1h22.2c0.6,0,1-0.5,1-1C32.3,25.7,31.8,25.2,31.2,25.2z"/>
	<path class="st0" d="M31.2,30.2H9.1c-0.6,0-1,0.5-1,1s0.5,1,1,1h22.2c0.6,0,1-0.5,1-1S31.8,30.2,31.2,30.2z"/>
	<path class="st0" d="M23.2,35.3H9.1c-0.6,0-1,0.5-1,1c0,0.6,0.5,1,1,1h14.1c0.6,0,1-0.5,1-1C24.2,35.7,23.7,35.3,23.2,35.3z"/>
	<g>
		<rect y="24.8" class="st0" width="2" height="2.8"/>
		<rect y="19" class="st0" width="2" height="2.8"/>
		<rect y="30.6" class="st0" width="2" height="3.4"/>
		<path class="st0" d="M36,2h0.3c1.1,0,2,0.9,2,2v0.4h2V4c0-2.2-1.8-4-4-4H36V2z"/>
		<rect y="37" class="st0" width="2" height="2.8"/>
		<rect x="12.8" class="st0" width="2.8" height="2"/>
		<rect x="7" y="46.4" class="st0" width="2.8" height="2"/>
		<rect x="18.6" class="st0" width="2.8" height="2"/>
		<rect x="7" class="st0" width="2.8" height="2"/>
		<rect y="7.4" class="st0" width="2" height="2.8"/>
		<rect y="13.2" class="st0" width="2" height="2.8"/>
		<path class="st0" d="M2,4.4V4c0-1.1,0.9-2,1.9-2V0C1.8,0,0,1.8,0,4v0.4H2z"/>
		<rect x="38.3" y="7.4" class="st0" width="2" height="2.8"/>
		<rect x="38.3" y="13.2" class="st0" width="2" height="2.8"/>
		<path class="st0" d="M4,46.4c-1.1,0-1.9-0.9-1.9-2v-1.5H0v1.5c0,2.2,1.8,4,4,4V46.4z"/>
		<path class="st0" d="M38.3,30.6v4.7h-6c-2.8,0-5,2.3-5,5v6h-2.9v2h4.3l11.7-11.7v-6.1H38.3z M29.2,44.9v-4.6c0-1.7,1.4-3,3-3h4.6
			L29.2,44.9z"/>
		<rect x="38.3" y="24.8" class="st0" width="2" height="2.8"/>
		<rect x="18.6" y="46.4" class="st0" width="2.8" height="2"/>
		<rect x="38.3" y="19" class="st0" width="2" height="2.8"/>
		<rect x="24.4" class="st0" width="2.8" height="2"/>
		<rect x="12.8" y="46.4" class="st0" width="2.8" height="2"/>
		<rect x="30.2" class="st0" width="2.8" height="2"/>
	</g>
</g>
</svg>
