import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, timer as timer$ } from 'rxjs';

import { Olark, OlarkCmd } from './models';
import { filter, take, takeUntil, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class OlarkService {

  isLoaded$ = new BehaviorSubject<boolean>(false);

  private olark: Olark;
  private documentRef: Document;

  constructor(@Inject(DOCUMENT) documentRef: any) {
    this.documentRef = documentRef;
    this.initialize();
  }

  expandChatbox(): void {
    if (this.olark == null) {
      throw new Error('expandChatbox called before olark loaded');
    }

    this.olark(OlarkCmd.EXPAND_CHATBOX);
  }

  sendMessageToUser(body: string): void {
    if (this.olark == null) {
      throw new Error('sendMessageToUser called before olark loaded');
    }

    this.olark(OlarkCmd.SEND_MESSAGE_TO_VISITOR, { body });
  }

  private initialize(): void {
    timer$(0, 1000)
      .pipe(
        take(15), // poll for 15s
        takeUntil(
          this.isLoaded$.pipe(filter((val) => val))
        ), // stop polling once loaded
        filter(() => {
          return this.documentRef.defaultView != null
            && this.documentRef.defaultView['olark'] != null;
        })
      )
      .subscribe(() => {
        this.olark = this.documentRef.defaultView['olark'] as Olark;
        this.isLoaded$.next(true);
      });
  }
}
