import * as tslib_1 from "tslib";
import { EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { filter, takeUntil, distinctUntilKeyChanged } from 'rxjs/operators';
import { OffersService } from '../../providers/offers.service';
import { OfferType } from 'models';
var OfferComponent = /** @class */ (function () {
    function OfferComponent(route, offersService, router) {
        this.route = route;
        this.offersService = offersService;
        this.router = router;
        this.dataLoaded = false;
        this.onDestroyEmitter = new EventEmitter();
        this.OfferType = OfferType;
    }
    OfferComponent.prototype.ngOnInit = function () {
        this.initNavigationEndSubscription();
        this.initOfferIdParamSubscription();
        this.initSectionPathParamSubscription();
    };
    OfferComponent.prototype.ngOnDestroy = function () {
        this.onDestroyEmitter.emit();
        this.onDestroyEmitter.complete();
    };
    OfferComponent.prototype.initNavigationEndSubscription = function () {
        this.router.events
            .pipe(filter(function (evt) { return evt instanceof NavigationEnd; }), takeUntil(this.onDestroyEmitter))
            .subscribe(function () { return window.scroll(0, 0); });
    };
    OfferComponent.prototype.initOfferIdParamSubscription = function () {
        var _this = this;
        this.route.params.pipe(distinctUntilKeyChanged('offerId'), takeUntil(this.onDestroyEmitter))
            .subscribe(function (_a) {
            var offerId = _a.offerId;
            return _this.handleOfferIdParamChange(offerId);
        });
    };
    OfferComponent.prototype.initSectionPathParamSubscription = function () {
        var _this = this;
        this.route.params
            .pipe(distinctUntilKeyChanged('sectionPath'), takeUntil(this.onDestroyEmitter))
            .subscribe(function (_a) {
            var sectionPath = _a.sectionPath;
            return _this.sectionPath = sectionPath;
        });
    };
    OfferComponent.prototype.handleOfferIdParamChange = function (token) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var offerData, _1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.dataLoaded = false;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.offersService.getOfferData(token)];
                    case 2:
                        offerData = _a.sent();
                        this.offer = tslib_1.__assign({}, offerData, { token: token });
                        return [3 /*break*/, 4];
                    case 3:
                        _1 = _a.sent();
                        this.router.navigateByUrl('/404');
                        return [3 /*break*/, 4];
                    case 4:
                        this.dataLoaded = true;
                        if (this.offer.type === OfferType.WILLOW_OFFER) {
                            this.router.navigate(['gsp-offer', token]);
                            return [2 /*return*/];
                        }
                        if (this.offer.expired) {
                            this.router.navigate(['offer/' + token + '/expired']);
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    return OfferComponent;
}());
export { OfferComponent };
