<svg version="1.1"
	 xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:a="http://ns.adobe.com/AdobeSVGViewerExtensions/3.0/"
	 x="0px" y="0px" width="46px" height="41px" viewBox="0 0 46 41" style="enable-background:new 0 0 46 41;" xml:space="preserve">
<g>
	<path class="st0" style="fill:#75968A;" d="M42.8,22l-3-13H36c0-1.7-1.3-3-3-3h-8c-0.8,0-1.5,0.3-2,0.8C22.5,6.3,21.8,6,21,6h-8c-1.7,0-3,1.3-3,3H6.2
		l-3,13H0v19h4.5l2-3h32.9l2,3H46V22H42.8z M25,8h8c0.6,0,1,0.4,1,1v2c0,0.6-0.4,1-1,1h-8c-0.6,0-1-0.4-1-1V9C24,8.4,24.4,8,25,8z
		 M12,9c0-0.6,0.4-1,1-1h8c0.6,0,1,0.4,1,1v2c0,0.6-0.4,1-1,1h-8c-0.6,0-1-0.4-1-1V9z M7.8,11H10c0,1.7,1.3,3,3,3h8
		c0.8,0,1.5-0.3,2-0.8c0.5,0.5,1.2,0.8,2,0.8h8c1.7,0,3-1.3,3-3h2.2l2.5,11H5.3L7.8,11z M44,39h-1.5l-2-3H5.5l-2,3H2V24h42V39z"/>
	<polygon class="st0" style="fill:#75968A;" points="4,2 23,2 42,2 42,15 44,15 44,0 23,0 2,0 2,15 4,15 	"/>
</g>
</svg>
