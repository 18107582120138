<span class="text-label">
  <span data-testid="streetAddress">{{address.streetAddress}}</span>, <!-- Do not move to newlines! If placed on new line, will create spacing issues -->
</span>
<span class="d-block text-label">
  <span data-testid="city">{{address.city}}</span>, <!-- Also do not move this, for same reason -->
  <span data-testid="state">
    {{address.state}}
  </span>
  <span data-testid="zipcode">
    {{address.zipcode}}
  </span>
</span>
