import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';

import { Estimate } from 'models';

@Component({
  selector: 'app-estimate-intro-tab',
  templateUrl: './intro-tab.component.html',
})
export class EstimateIntroTabComponent implements OnInit {
  public estimate: Estimate;

  constructor(
    private titleService: Title,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.estimate = this.route.snapshot.data.estimate;
    this.estimate.token = this.route.snapshot.params.estimateId;
    this.titleService.setTitle(`Orchard Estimate -
      ${this.estimate.subjectProperty.address.streetAddress}
      ${this.estimate.subjectProperty.address.city},
      ${this.estimate.subjectProperty.address.state},
      ${this.estimate.subjectProperty.address.zipcode}`);
  }
}
