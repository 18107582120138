import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { PopoverInfoModule } from 'popover-info';
import { PerchIconsModule } from '../icons';
import { BathroomDetailsComponent } from './bathroom-details';
import { CompsComponent } from './comps/comps.component';
import { ExpiredComponent } from './expired/expired.component';
import { HomeDetailsComponent } from './home-details/home-details.component';
import { IntroTabComponent } from './intro-tab/intro-tab.component';
import { MarketAnalysisComponent } from './market-analysis/market-analysis.component';
import { NextStepsNetOfRepairsComponent } from './next-steps-net-of-repairs/next-steps-net-of-repairs.component';
import { NextStepsComponent } from './next-steps/next-steps.component';
import { OfferComparisonTableV3Component } from './offer-comparison-tables';
import { OfferDetailsComponent } from './offer-details/offer-details.component';
import { ValidThruComponent } from './valid-thru/valid-thru.component';

@NgModule({
  declarations: [
    IntroTabComponent,
    MarketAnalysisComponent,
    CompsComponent,
    HomeDetailsComponent,
    OfferDetailsComponent,
    NextStepsComponent,
    NextStepsNetOfRepairsComponent,
    ExpiredComponent,
    OfferComparisonTableV3Component,
    BathroomDetailsComponent,
    ValidThruComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    LeafletModule,
    PerchIconsModule,
    PopoverInfoModule
  ],
  exports: [
    IntroTabComponent,
    MarketAnalysisComponent,
    CompsComponent,
    HomeDetailsComponent,
    OfferDetailsComponent,
    NextStepsComponent,
    NextStepsNetOfRepairsComponent,
    ExpiredComponent,
    OfferComparisonTableV3Component,
    BathroomDetailsComponent,
    ValidThruComponent
  ],
  providers: [],
})
export class SellOnlyOffersModule {}
