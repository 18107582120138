import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { ServiceName } from 'models';
var BaseWillowOfferComponent = /** @class */ (function () {
    function BaseWillowOfferComponent(titleService, route) {
        this.titleService = titleService;
        this.ServiceName = ServiceName;
        this.offer = route.snapshot.data.offer;
    }
    BaseWillowOfferComponent.prototype.ngOnInit = function () {
        this.titleService.setTitle("Orchard Offer -\n      " + this.offer.subjectProperty.address.streetAddress + "\n      " + this.offer.subjectProperty.address.city + ",\n      " + this.offer.subjectProperty.address.state + ",\n      " + this.offer.subjectProperty.address.zipcode);
    };
    Object.defineProperty(BaseWillowOfferComponent.prototype, "isTexas", {
        get: function () {
            return this.offer.subjectProperty.address.state === 'TX';
        },
        enumerable: true,
        configurable: true
    });
    return BaseWillowOfferComponent;
}());
export { BaseWillowOfferComponent };
var GspOfferCoverSheetComponent = /** @class */ (function (_super) {
    tslib_1.__extends(GspOfferCoverSheetComponent, _super);
    function GspOfferCoverSheetComponent() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return GspOfferCoverSheetComponent;
}(BaseWillowOfferComponent));
export { GspOfferCoverSheetComponent };
var GspOfferComponent = /** @class */ (function (_super) {
    tslib_1.__extends(GspOfferComponent, _super);
    function GspOfferComponent() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return GspOfferComponent;
}(BaseWillowOfferComponent));
export { GspOfferComponent };
var GspOfferNotFoundComponent = /** @class */ (function (_super) {
    tslib_1.__extends(GspOfferNotFoundComponent, _super);
    function GspOfferNotFoundComponent() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return GspOfferNotFoundComponent;
}(BaseWillowOfferComponent));
export { GspOfferNotFoundComponent };
