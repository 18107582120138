<svg width="72px" height="72px" viewBox="0 0 72 72" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
  <title>step 2</title>
  <defs>
      <polygon id="path-1" points="0.000989655172 0.611111111 40.7168172 0.611111111 40.7168172 48.6108333 0.000989655172 48.6108333"></polygon>
  </defs>
  <g id="step-2" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <g id="Page-1" transform="translate(16.000000, 11.000000)">
      <path d="M20.3583379,8.80333333 L24.0723724,12.4519444 C23.6864069,12.5936111 23.412131,12.9602778 23.412131,13.3894444 L23.412131,16.3894444 L21.3776828,16.3894444 L21.3776828,14.3880556 C21.3776828,13.8366667 20.9210276,13.3894444 20.3583379,13.3894444 C19.7970621,13.3894444 19.3404069,13.8366667 19.3404069,14.3880556 L19.3404069,16.3894444 L17.3045448,16.3894444 L17.3045448,13.3894444 C17.3045448,12.9602778 17.030269,12.5936111 16.6443034,12.4519444 L20.3583379,8.80333333 Z M14.2521655,14.3880556 L15.2686828,14.3880556 L15.2686828,17.3894444 C15.2686828,17.9408333 15.7253379,18.3880556 16.2866138,18.3880556 L24.4300621,18.3880556 C24.9927517,18.3880556 25.4494069,17.9408333 25.4494069,17.3894444 L25.4494069,14.3880556 L26.4659241,14.3880556 C26.8773379,14.3880556 27.2491655,14.145 27.4060966,13.7713889 C27.5658552,13.3977778 27.4767862,12.9672222 27.1855448,12.6811111 L21.0779586,6.6825 C20.6948207,6.30611111 20.0204414,6.3075 19.6373034,6.6825 L13.531131,12.6811111 C13.2398897,12.9672222 13.1522345,13.3977778 13.3105793,13.7713889 C13.4675103,14.1463889 13.8393379,14.3880556 14.2521655,14.3880556 Z" id="Fill-1" fill="#75968A"></path>
      <path d="M31.5554379,21.3888889 L9.16095517,21.3888889 C8.59967931,21.3888889 8.14302414,21.8361111 8.14302414,22.3888889 C8.14302414,22.9402778 8.59967931,23.3888889 9.16095517,23.3888889 L31.5554379,23.3888889 C32.1181276,23.3888889 32.5747828,22.9402778 32.5747828,22.3888889 C32.5747828,21.8361111 32.1181276,21.3888889 31.5554379,21.3888889" id="Fill-3" fill="#75968A"></path>
      <path d="M31.5554379,26.3883333 L9.16095517,26.3883333 C8.59967931,26.3883333 8.14302414,26.8355556 8.14302414,27.3897222 C8.14302414,27.9411111 8.59967931,28.3883333 9.16095517,28.3883333 L31.5554379,28.3883333 C32.1181276,28.3883333 32.5747828,27.9411111 32.5747828,27.3897222 C32.5747828,26.8355556 32.1181276,26.3883333 31.5554379,26.3883333" id="Fill-5" fill="#75968A"></path>
      <path d="M31.5554379,31.3891667 L9.16095517,31.3891667 C8.59967931,31.3891667 8.14302414,31.8363889 8.14302414,32.3891667 C8.14302414,32.9405556 8.59967931,33.3877778 9.16095517,33.3877778 L31.5554379,33.3877778 C32.1181276,33.3877778 32.5747828,32.9405556 32.5747828,32.3891667 C32.5747828,31.8363889 32.1181276,31.3891667 31.5554379,31.3891667" id="Fill-7" fill="#75968A"></path>
      <path d="M23.4122724,36.3886111 L9.16123793,36.3886111 C8.59854828,36.3886111 8.1433069,36.8358333 8.1433069,37.3886111 C8.1433069,37.9413889 8.59854828,38.3886111 9.16123793,38.3886111 L23.4122724,38.3886111 C23.9735483,38.3886111 24.4302034,37.9413889 24.4302034,37.3886111 C24.4302034,36.8358333 23.9735483,36.3886111 23.4122724,36.3886111" id="Fill-9" fill="#75968A"></path>
      <g id="Group-13" transform="translate(0.000000, 0.777778)">
        <g id="Clip-12"></g>
        <path d="M38.6809552,35.6108333 L32.5747828,35.6108333 C29.7669897,35.6108333 27.4837138,37.8538889 27.4837138,40.6122222 L27.4837138,46.6108333 L4.07271379,46.6108333 C2.95016207,46.6108333 2.03543793,45.7136111 2.03543793,44.6108333 L2.03543793,4.61222222 C2.03543793,3.50944444 2.95016207,2.61083333 4.07271379,2.61083333 L36.6465069,2.61083333 C37.7676448,2.61083333 38.6809552,3.50944444 38.6809552,4.61222222 L38.6809552,35.6108333 Z M29.5209897,45.1969444 L29.5209897,40.6122222 C29.5209897,38.9566667 30.8895414,37.6108333 32.5747828,37.6108333 L37.2417138,37.6108333 L29.5209897,45.1969444 Z M36.6465069,0.610833333 L4.07271379,0.610833333 C1.82619655,0.610833333 0.000989655172,2.40527778 0.000989655172,4.61222222 L0.000989655172,44.6108333 C0.000989655172,46.8163889 1.82619655,48.6108333 4.07271379,48.6108333 L28.924369,48.6108333 L40.7168172,37.0261111 L40.7168172,4.61222222 C40.7168172,2.40527778 38.8901966,0.610833333 36.6465069,0.610833333 Z" id="Fill-11" fill="#75968A"></path>
      </g>
    </g>
  </g>
</svg>