import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Configuration, Estimate } from 'models';
import { map } from 'rxjs/operators';
import { camelCaseKeys } from 'utils';


@Injectable({ providedIn: 'root' })
export class EstimatesService {

  constructor(
    private http: HttpClient,
    @Inject('CONFIGS') private configs: Configuration
  ) { }

  async getEstimateData(estimateToken: string): Promise<Estimate> {
    return this.http
      .get(`${this.configs.ENDPOINT_URL}/api/v1/seller_estimate/by_token/${estimateToken}`)
      .pipe(map((it) => camelCaseKeys(it) as Estimate))
      .toPromise();
  }
}
