<a class="brand d-sm-none"><img src="https://res.cloudinary.com/dmxgostd9/image/upload/web-assets/orchard-logo-dark.svg"></a>

<div class="step step-1" *ngIf="offer">
  <section class="section">
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-8 offset-sm-2 text-center section-body">
          <div class="offer-meta">
            <p class="sub-heading">Orchard Offer</p>
            <p class="lead address">{{offer.subjectProperty?.address.streetAddress}}, <br class="d-block d-sm-none">{{offer.subjectProperty?.address.city}}, {{offer.subjectProperty?.address.state}} {{offer.subjectProperty?.address.zipcode}}</p>
          </div>

          <div class="offer-description" *ngIf="!extensionRequested">
            <h1>This offer has expired</h1>
            <p>If you would like to receive a new offer for your home click below<br class="d-none d-sm-block"> and we'll send you an updated offer within 24 hours.</p>

            <div class="cta">
              <a class="btn btn-sm btn-primary" tabindex="0" (click)="resendOfferRequest()">Request a New Offer</a>
            </div>
          </div>

          <div *ngIf="extensionRequested">
            <div class="alert alert-success" style="display:inline-block;"><strong>Thank You.</strong> We'll follow-up within 1 business day with your offer.</div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
