import * as get from 'lodash/fp/get';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
var HeapAnalyticsService = /** @class */ (function () {
    function HeapAnalyticsService(documentRef) {
        this.eventsQueue = [];
        // tslint:disable-next-line member-ordering
        this.intialized = false;
        this.documentRef = documentRef;
    }
    HeapAnalyticsService.prototype.track = function (name, properties) {
        this.ensureInitialized();
        if (this.heapAnalyticsRef != null) {
            this.heapAnalyticsRef.track(name, properties);
        }
        else {
            this.eventsQueue.push({ name: name, properties: properties });
        }
    };
    HeapAnalyticsService.prototype.ensureInitialized = function () {
        if (this.intialized) {
            return;
        }
        this.setHeapAnalyticsRef();
        this.intialized = true;
    };
    HeapAnalyticsService.prototype.setHeapAnalyticsRef = function () {
        var _this = this;
        var ref = get('defaultView.heap', this.documentRef);
        if (ref == null) {
            setTimeout(function () { return _this.setHeapAnalyticsRef(); }, 1000);
            return;
        }
        this.heapAnalyticsRef = ref;
        this.flushEventsQueue();
    };
    HeapAnalyticsService.prototype.flushEventsQueue = function () {
        var _this = this;
        this.eventsQueue.forEach(function (_a) {
            var name = _a.name, properties = _a.properties;
            return _this.heapAnalyticsRef.track(name, properties);
        });
        this.eventsQueue = [];
    };
    HeapAnalyticsService.ngInjectableDef = i0.defineInjectable({ factory: function HeapAnalyticsService_Factory() { return new HeapAnalyticsService(i0.inject(i1.DOCUMENT)); }, token: HeapAnalyticsService, providedIn: "root" });
    return HeapAnalyticsService;
}());
export { HeapAnalyticsService };
