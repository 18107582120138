import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { Configuration } from 'models';
import { map } from 'rxjs/operators';
import { camelCaseKeys } from 'utils';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var OffersService = /** @class */ (function () {
    function OffersService(http, configs) {
        this.http = http;
        this.configs = configs;
    }
    OffersService.prototype.getOfferData = function (offerToken) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .get(this.configs.ENDPOINT_URL + "/api/v1/seller_offer/by_token/" + offerToken)
                        .pipe(map(function (it) { return camelCaseKeys(it); }))
                        .toPromise()];
            });
        });
    };
    OffersService.prototype.sendNewOfferRequest = function (offerToken) {
        return this.http
            .post(this.configs.ENDPOINT_URL + "/api/v1/seller_offer/" + offerToken + "/request_extension", {})
            .toPromise();
    };
    OffersService.ngInjectableDef = i0.defineInjectable({ factory: function OffersService_Factory() { return new OffersService(i0.inject(i1.HttpClient), i0.inject("CONFIGS")); }, token: OffersService, providedIn: "root" });
    return OffersService;
}());
export { OffersService };
