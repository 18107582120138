import { Component } from '@angular/core';

@Component({
  selector: 'perch-logo',
  template: `
    <a class="brand">
      <img src="https://res.cloudinary.com/dmxgostd9/image/upload/web-assets/orchard-logo-dark.svg">
    </a>
  `,
  styleUrls: ['./logo.scss']
})
export class PerchLogoComponent { }
