import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Offer, OfferType } from 'models';
import { offerComparisonTooltips } from '../constants';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-offer-comparison-table',
  templateUrl: './offer-comparison-table.component.html',
  styleUrls: ['./offer-comparison-table.component.scss']
})
export class OfferComparisonTableV3Component {

  tooltips = offerComparisonTooltips;
  offerTypes = OfferType;

  @Input()
  offer: Offer;

  get defectEstimate() {
    return this.offer.offerDetails.defectEstimate;
  }

  get sellOnly() {
    return this.offer.offerDetails.sellOnly;
  }

  get traditional() {
    return this.offer.offerDetails.traditional;
  }

}
