/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../willow-layout/willow-layout.ngfactory";
import * as i2 from "../../willow-layout/willow-layout";
import * as i3 from "../../../icons/letter/letter.component.ngfactory";
import * as i4 from "../../../icons/letter/letter.component";
import * as i5 from "@angular/router";
import * as i6 from "@angular/common";
import * as i7 from "./intro-tab.component";
import * as i8 from "@angular/platform-browser";
var styles_EstimateIntroTabComponent = [];
var RenderType_EstimateIntroTabComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_EstimateIntroTabComponent, data: {} });
export { RenderType_EstimateIntroTabComponent as RenderType_EstimateIntroTabComponent };
export function View_EstimateIntroTabComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 13, "willow-layout", [], null, null, null, i1.View_WillowLayoutComponent_0, i1.RenderType_WillowLayoutComponent)), i0.ɵdid(1, 49152, null, 0, i2.WillowLayoutComponent, [], { address: [0, "address"] }, null), (_l()(), i0.ɵted(-1, 2, ["\n  "])), (_l()(), i0.ɵeld(3, 0, null, 0, 1, "perch-icons-letter", [["role", "icon"]], null, null, null, i3.View_IconLetter_0, i3.RenderType_IconLetter)), i0.ɵdid(4, 49152, null, 0, i4.IconLetter, [], null, null), (_l()(), i0.ɵted(-1, 2, ["\n\n  "])), (_l()(), i0.ɵeld(6, 0, null, 1, 1, "div", [["role", "header"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n    Your initial home sale estimate is ready to review\n  "])), (_l()(), i0.ɵted(-1, 2, ["\n\n  "])), (_l()(), i0.ɵeld(9, 0, null, 2, 3, "a", [["class", "btn btn-sm btn-primary"], ["id", "next-step"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 10).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(10, 671744, null, 0, i5.RouterLinkWithHref, [i5.Router, i5.ActivatedRoute, i6.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i0.ɵpad(11, 1), (_l()(), i0.ɵted(-1, null, ["\n    View Estimate\n  "])), (_l()(), i0.ɵted(-1, 2, ["\n"])), (_l()(), i0.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.estimate.subjectProperty == null) ? null : _co.estimate.subjectProperty.address); _ck(_v, 1, 0, currVal_0); var currVal_3 = _ck(_v, 11, 0, "../details"); _ck(_v, 10, 0, currVal_3); }, function (_ck, _v) { var currVal_1 = i0.ɵnov(_v, 10).target; var currVal_2 = i0.ɵnov(_v, 10).href; _ck(_v, 9, 0, currVal_1, currVal_2); }); }
export function View_EstimateIntroTabComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-estimate-intro-tab", [], null, null, null, View_EstimateIntroTabComponent_0, RenderType_EstimateIntroTabComponent)), i0.ɵdid(1, 114688, null, 0, i7.EstimateIntroTabComponent, [i8.Title, i5.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EstimateIntroTabComponentNgFactory = i0.ɵccf("app-estimate-intro-tab", i7.EstimateIntroTabComponent, View_EstimateIntroTabComponent_Host_0, {}, {}, []);
export { EstimateIntroTabComponentNgFactory as EstimateIntroTabComponentNgFactory };
