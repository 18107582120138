import { Component, Input } from '@angular/core';

import { Offer } from 'models';

@Component({
  selector: 'app-market-analysis',
  templateUrl: './market-analysis.component.html',
  styleUrls: ['./market-analysis.component.css']
})
export class MarketAnalysisComponent {

  @Input()
  public offer: Offer;

  constructor() {}

  public abs(input: number) {
    return Math.abs(input);
  }
}
