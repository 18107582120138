<div class="estimate-wrapper">
  <a class="header">
    <img src="https://res.cloudinary.com/dmxgostd9/image/upload/web-assets/orchard-logo-dark.svg">
  </a>
  <div class="intro-copy">
    <div class="intro-copy__wrapper">
    <p>Hi! Here's your initial list price estimate. The next step is to schedule a home visit with an Orchard Home Advisor
          so we can give you a more accurate assessment.</p>
    </div>
  </div>

  <div class="truck-route">
    <app-estimate-progress-bar></app-estimate-progress-bar>
  </div>

  <div class="estimate-details d-flex flex-column align-items-center">
    <h2 class="cheltenham-light initial-copy">Your initial list price estimate:</h2>
    <h1 class="cheltenham-light estimate-range d-flex align-items-center">
      <span id="estimate-min">${{estimate.min | number}}</span>
      <span class="estimate-separator"></span>
      <span id="estimate-max">${{estimate.max | number}}</span>
    </h1>
    <p class="details-copy">Next up: Chat with our team to schedule your home visit. We can also answer any questions about your estimate
      or working with Orchard.</p>
      <div>
        <a class="home-visit-cta btn btn-sm btn-primary cursor-pointer" *ngIf="olarkService.isLoaded$ | async; else fallbackCTA" (click)="sendMessage()" target="_blank">Schedule home visit</a>
        <ng-template #fallbackCTA>
          <a class="home-visit-cta btn btn-sm btn-primary" [href]="calendlyLink" target="_blank">Schedule home visit</a>
        </ng-template>
      </div>
      <div class="alt-instructions">Or give us a call <a href="tel:8444800931" class="tel-link">844-480-0931</a> <span> (Mon-Fri 9am to 5pm)</span></div>
  </div>

  <div class="orchard-offering">
    <h2 class="orchard-offering__subhead cheltenham-light">
      Orchard gives you:
    </h2>
    <ul class="orchard-offering__list">
      <li class="orchard-offering__bullet">
        <i class="material-icons orchard-offering__checkmark">done</i>
        <span>Personalized attention from a top real estate agent</span>
      </li>
      <li class="orchard-offering__bullet">
        <i class="material-icons orchard-offering__checkmark">done</i>
        <span>Flexibility to buy your next home before you sell</span>
      </li>
      <li class="orchard-offering__bullet">
        <i class="material-icons orchard-offering__checkmark">done</i>
        <span>Peace of mind of a guaranteed sale in {{ estimate.listingPeriodInterval }} days</span>
      </li>
      <li class="orchard-offering__bullet">
        <i class="material-icons orchard-offering__checkmark">done</i>
        <span>Ability to list after you move and avoid home showings</span>
      </li>
    </ul>
  </div>
</div>



<div class="divider"></div>


<!--
  WHAT HAPPENS NEXT ????
 -->
<section class="what-happens-next">

  <h2 class="what-happens-next__subhead cheltenham-light">
    What happens next
  </h2>


  <!-- MEET YOUR AGENT -->
  <div class="what-happens-next__item">
    <div class="what-happens-next__item-section">
      <div class="what-happens-next__copy">
        <div class="what-happens-next__numeral sharp-grotesk-bold">
          <span>1</span>
        </div>
        <p class="what-happens-next__heading sharp-grotesk-bold">
          Meet your Orchard Home Advisor
        </p>
        <p class="what-happens-next__body">
          We'll match you with one of our experienced local Home Advisors from your area to discuss your buying and selling plan and finalize a list price for your home.
        </p>
      </div>
    </div>
    <div class="what-happens-next__item-section what-happens-next__image-wrapper">
      <img
        class="what-happens-next__image"
        src="https://res.cloudinary.com/dmxgostd9/image/upload/v1580485283/web-assets/offer-pack/orchard-agents.png"
      >
    </div>
  </div>


  <!-- BACKUP CASH OFFER -->
  <div class="what-happens-next__item-wrapper">
    <div class="what-happens-next__item what-happens-next__item--reversed">
      <div class="what-happens-next__item-section what-happens-next__image-wrapper">
        <img
          class="what-happens-next__image"
          src="https://res.cloudinary.com/dmxgostd9/image/upload/v1580486369/web-assets/offer-pack/cash-suitcase.png"
        >
      </div>
      <div class="what-happens-next__item-section">
        <div class="what-happens-next__copy">
          <div class="what-happens-next__numeral sharp-grotesk-bold">
            <span>2</span>
          </div>
          <p class="what-happens-next__heading sharp-grotesk-bold">
            Get your backup cash offer
          </p>
          <p class="what-happens-next__body">
            After a free home assessment, we'll share the price Orchard will buy your home for if it doesn't sell within {{ estimate.listingPeriodInterval }} days. You'll also get a copy of your assessment report
          </p>
        </div>
      </div>
    </div>
  </div>

  <!-- START HOME SHOPPING -->
  <div class="what-happens-next__item">
    <div class="what-happens-next__item-section">
      <div class="what-happens-next__copy">
        <div class="what-happens-next__numeral sharp-grotesk-bold">
          <span>3</span>
        </div>
        <p class="what-happens-next__heading sharp-grotesk-bold">
          Start home shopping
        </p>
        <p class="what-happens-next__body">
          When you're ready, we can start touring homes with you on your schedule to find the perfect one for you.
        </p>
      </div>
    </div>
    <div class="what-happens-next__item-section what-happens-next__image-wrapper">
      <img
        class="what-happens-next__image"
        src="https://res.cloudinary.com/dmxgostd9/image/upload/v1580825773/web-assets/offer-pack/home-shopping.png"
      >
    </div>
  </div>

</section>
<div class="d-flex justify-content-center mt-4 mb-lg-5">
<a class="free-btn home-visit-cta btn btn-sm btn-primary cursor-pointer" *ngIf="olarkService.isLoaded$ | async; else fallbackCTA" (click)="sendMessage()" target="_blank">Schedule my home visit</a>
  <ng-template #fallbackCTA>
    <a class="free-btn home-visit-cta btn btn-sm btn-primary" [href]="calendlyLink" target="_blank">Schedule my home visit</a>
  </ng-template>
</div>

<div class="step estimate-step-2 pb-0" *ngIf="estimate">

  <section class="section faq">
    <div class="constrained">
      <div class="headline">
        <h1 class="cheltenham-light">Frequently Asked Questions</h1>
      </div>

      <accordion class="cheltenham-light">
        <accordion-group heading="Why is my estimate a range?">
          <p>
            Our initial list price recommendation is our best estimate based on the listing price of homes like yours in your area. While this gives us a good starting point of your home's value, there's no substitute for having an expert real estate agent tour your home in-person to see what makes it unique. Once we see your home, an Orchard Home Advisor, one of our licensed agents, can give you a final recommendation.
          </p>
        </accordion-group>
        <div class="faq-separator"></div>
        <accordion-group heading="What are the benefits of buying with Orchard?">
          <p>
            Orchard lets you shop for new homes and make a cash offer as soon as you find the one you love. No need to wait to sell your current home or make a contingent offer. In a hot market, all-cash offers stand out, giving you an edge on other buyers. Orchard’s Home Advisors will work with you on your schedule to find a home that meets your needs. They can show you any home on the market.
          </p>
        </accordion-group>
        <div class="faq-separator"></div>
        <accordion-group heading="What are the benefits of listing with Orchard?">
          <p>
            When you list with Orchard, you’ll work closely with a licensed Orchard agent from your area. Our team aggressively markets your home both online and offline to help you find a buyer within your {{ estimate.listingPeriodInterval }}-day listing period. You’ll also receive a free pre-listing assessment report to help you understand the details of your home condition and proactively address any issues, saving you valuable time in the closing process.
          </p>
        </accordion-group>
        <div class="faq-separator"></div>
        <accordion-group heading="Do I need to sign anything yet?">
          <p>
            Not yet! After you receive your recommended list price and guaranteed offer amount, your
            Orchard Home Advisor can answer any questions. If you're ready to move forward, we'll send
            you the documents to look over to work with us.
          </p>
        </accordion-group>
      </accordion>

      <!-- FAQ CTA -->
      <div class="faq-cta text-center mb-2 mt-5">
        <a
          onclick="olark('api.box.expand')"
          class="btn btn-link btn-faq-cta"
        >
          Have another question?
        </a>
      </div>

    </div>
  </section>


  <section class="review">
    <div class="review__wrapper">

      <img
        src="https://res.cloudinary.com/dmxgostd9/image/upload/v1580487913/web-assets/offer-pack/stars.png"
        class="review__stars"
      >
      <h3 class="review__heading">Stress-free, fair and simple</h3>
      <p class="review__copy">
        Orchard gave us the ability to choose the best option for our family. We didn’t have to settle for a home we didn’t love because our previous home was sold and we were in a rush. It took a lot of the stress out of the buying/selling process.
      </p>
      <p class="review__attribution">
        Jennifer D.
      </p>
    </div>
  </section>



  <section class="section ready-to-continue">
    <div class="constrained">
      <div class="headline">
        <h1>Ready to continue?</h1>
      </div>
      <p>Click here to request a home visit and we’ll coordinate the rest</p>
      <div class="home-visit-cta mt-4">
        <div>
          <a class="home-visit-cta btn btn-sm btn-primary cursor-pointer d-inline" *ngIf="olarkService.isLoaded$ | async; else fallbackCTA" (click)="sendMessage()" target="_blank">Schedule home visit</a>
            <ng-template #fallbackCTA>
              <a class="home-visit-cta btn btn-sm btn-primary d-inline" [href]="calendlyLink" target="_blank">Schedule my home visit</a>
            </ng-template>
        </div>
      </div>
      <!-- <p class="mt-4">
        Or you can email us at
        <br/>
        or call us at
      </p> -->
    </div>
  </section>

  <section class="contact-info">
    <a
      href="mailto:support@orchard.com"
      class="contact-info__link"
    >support@orchard.com</a>
    <span class="contact-info__separator">|</span>
    <a
      href="tel:18448191373"
      class="contact-info__link"
    >(844) 819-1373</a>
  </section>


  <willow-disclaimer class="transparent">
    <div class="content-block">
      <h5 class="stat-label">Data Sources</h5>
      <p>
        Orchard leverages up-to-date data from county records and local MLS'. We combine
        this data with Orchard's proprietary valuation model to provide you with a comprehensive, simple
        and accurate estimate. For questions, please contact Orchard at <a href="mailto:support@orchard.com">support@orchard.com</a>.
      </p>
    </div>
    <div class="content-block">
      <h5 class="stat-label">Disclaimer</h5>
      <p>
        Orchard certifies that, to the best of its knowledge, the statements of fact
        contained in this report are true and correct. Orchard is not acting as a real estate
        agent or broker, Orchard is only acting as a prospective purchaser of real estate.
      </p>
      <p *ngIf="isTexas">
        Texas real estate law requires agents provide the
        <a target="_blank" href="https://s3.amazonaws.com/perch-website-assets/files/disclosures/trec/CN.pdf">
          TREC Consumer Protection Notice
        </a>
        and
        <a target="_blank" href="https://s3.amazonaws.com/perch-website-assets/files/disclosures/trec/IABS.pdf">
          Information About Brokerage Services
        </a>
        to all consumers.
      </p>
      <p>
        Orchard is the consumer brand of Orchard Property I.
      </p>
    </div>
  </willow-disclaimer>
</div>
