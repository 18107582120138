import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PerchLogoComponent } from './logo';

@NgModule({
  declarations: [PerchLogoComponent],
  imports: [CommonModule],
  exports: [PerchLogoComponent],
})
export class PerchLogoModule {}
