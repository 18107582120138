import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

import { Offer } from 'models';

@Component({
  selector: 'app-home-details',
  templateUrl: './home-details.component.html',
  styleUrls: ['./home-details.component.css']
})
export class HomeDetailsComponent {

  @Input()
  set offer(val: Offer) {
    if (val.valueAdjustments == null) {
      this.redirectToOfferStep(val.token);
      return;
    }
    this._offer = val;
  }
  get offer(): Offer {
    return this._offer;
  }
  private _offer: Offer;

  constructor(private router: Router) {}

  private redirectToOfferStep(token: string): void {
    this.router.navigate(['offer', token, 'offer']);
  }
}
