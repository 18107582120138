// Map Markers

import { Icon, IconOptions } from 'leaflet';
import * as L from 'leaflet';

export interface MapMarkersIconOptions extends IconOptions {
  markerLabel?,
  markerColor?,
  iconClass?,
  iconColor?,
  iconLabel?
}

export class MapMarkersIcon extends Icon {
  options: MapMarkersIconOptions;

  constructor(options: IconOptions) {
    super(options);
  }

  createIcon() {
    const div = document.createElement('div');

    // Add Marker
    div.appendChild(this.createMarker());

    div.className = "leaflet-marker";

    return div;
  }

  private createMarkerLabel() {
    const iconLabel = L.DomUtil.create('span', 'icon-label');
    iconLabel.innerHTML = this.options.markerLabel;
    iconLabel.style.zIndex = "1";
    iconLabel.style.position = "absolute";
    iconLabel.style.color = "#fff";
    iconLabel.style.textAlign = "center";
    iconLabel.style.fontSize = "15px";

    return iconLabel;
  }

  private createIconElement() {

    const iconElement = L.DomUtil.create('i', 'icon');
    iconElement.className = this.options.iconClass;
    iconElement.innerHTML = this.options.iconLabel;
    iconElement.style.zIndex = "1";
    iconElement.style.position = "absolute";
    iconElement.style.color = "#fff";
    iconElement.style.textAlign = "center";
    iconElement.style.lineHeight = "inherit";
    iconElement.style.left = "0";
    iconElement.style.height = "100%";
    iconElement.style.width = "100%";

    return iconElement;
  }

  private createMarker() {

    // container div
    const iconDiv = L.DomUtil.create('div', 'map-marker-wrap');
    iconDiv.style.position = "absolute";
    iconDiv.style.textAlign = "center";

    this.setIconStyles(iconDiv);

    // Set Marker Color
    if(this.options.markerColor){
      iconDiv.classList.add('marker-' + this.options.markerColor);
    }

    // Add Label
    if(this.options.markerLabel) {
      iconDiv.appendChild(this.createMarkerLabel())
    }

    // Add Icon
    if(this.options.iconClass) {
      iconDiv.appendChild(this.createIconElement())
    }

    return iconDiv;
  }

  private setIconStyles(img) {
    var size = L.point(this.options['iconSize']),
        anchor;

    anchor = L.point(this.options.iconAnchor);

    if (!anchor && size) {
      anchor = size.divideBy(2);
    }

    if (anchor) {
      img.style.marginLeft = (-anchor.x) + 'px';
      img.style.marginTop  = (-anchor.y) + 'px';
    }

    if (size) {
      img.style.width  = size.x + 'px';
      img.style.height = size.y + 'px';
    }
  }


}
