import {Component, Input, OnInit} from '@angular/core';

import { HeapAnalyticsService } from "app/providers";
import {Offer, TestGroup} from 'models';
import {HEAP_CROSS_PROMOTE_EXPERIMENT_NAME, TEST_NAME} from "./constants";

@Component({
  selector: 'app-offer-details',
  templateUrl: './offer-details.component.html',
  styleUrls: ['./offer-details.component.css']
})
export class OfferDetailsComponent implements OnInit {

  @Input()
  public offer: Offer;
  public testGroup?: TestGroup;

  constructor(
    private heapAnalyticsService: HeapAnalyticsService
  ) {}

  ngOnInit() {
    this.setTestGroup();
    this.reportTestGroup();
  }

  get previousStep(): string {
    return this.offer.valueAdjustments != null  ? 'home-details' : 'comps';
  }

  get isTexas(): boolean {
    return this.offer.subjectProperty.address.state === 'TX';
  }

  private setTestGroup(): void {
    if (this.offer.testGroup == null || this.offer.testGroup.testName !== TEST_NAME) {
      this.testGroup = undefined;
      return;
    }
    this.testGroup = this.offer.testGroup;
  }

  private reportTestGroup(): void {
    if (this.testGroup != null) {
      this.heapAnalyticsService.track(HEAP_CROSS_PROMOTE_EXPERIMENT_NAME, { 'group': this.testGroup.name });
    }
  }
}
