import { Input, Component } from '@angular/core';
import { Address } from 'models/address';

@Component({
  selector: 'willow-layout',
  templateUrl: './willow-layout.html',
  styleUrls: ['willow-layout.scss']
})
export class WillowLayoutComponent {
  @Input() address?: Address;
}
