<perch-logo></perch-logo>
<div class="page">
  <section class="section">
    <div class="container-fluid d-flex flex-column align-items-center">
      <div class="text-center section-body">
        <div class="card">
          <div class="icon-container mb-3">
            <ng-content select="[role=icon]"></ng-content>
          </div>

          <div class="subhead" *ngIf="address">
            <address id="address" [address]="address"></address>
          </div>

          <div class="headline">
            <h1 class="text-h3"><ng-content select="[role=header]"></ng-content></h1>
          </div>

          <ng-content></ng-content>
        </div>
      </div>
    </div>
  </section>
</div>
