import { OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { HttpParams } from '@angular/common/http';
var EstimateDetailsComponent = /** @class */ (function () {
    function EstimateDetailsComponent(titleService) {
        this.titleService = titleService;
    }
    EstimateDetailsComponent.prototype.ngOnInit = function () {
        this.calendlyLink = this.generateCalendlyLink();
        this.titleService.setTitle("Orchard Estimate -\n      " + this.estimate.subjectProperty.address.streetAddress + "\n      " + this.estimate.subjectProperty.address.city + ",\n      " + this.estimate.subjectProperty.address.state + ",\n      " + this.estimate.subjectProperty.address.zipcode);
    };
    EstimateDetailsComponent.prototype.generateCalendlyLink = function () {
        var address = this.estimate.subjectProperty.address;
        var phoneNumber = this.estimate.contact.phoneNumber ? this.estimate.contact.phoneNumber : undefined;
        var httpParams = new HttpParams({
            fromObject: this.estimate.contact == null ? {} : {
                name: this.estimate.contact.firstName + ' ' + this.estimate.contact.lastName,
                email: this.estimate.contact.email,
                a1: phoneNumber,
                a2: address.streetAddress + ", " + address.city + ", " + address.state + " " + address.zipcode
            }
        });
        return "https://calendly.com/orchard-cx/orchard-consultation-cx?" + httpParams.toString();
    };
    Object.defineProperty(EstimateDetailsComponent.prototype, "isTexas", {
        get: function () {
            return this.estimate.subjectProperty.address.state === 'TX';
        },
        enumerable: true,
        configurable: true
    });
    return EstimateDetailsComponent;
}());
export { EstimateDetailsComponent };
