import { camelCase, snakeCase } from 'change-case';

export function camelCaseKeys(obj: object): object {
  return changeCaseKeys(obj, camelCase);
}

export function snakeCaseKeys(obj: object): object {
  return changeCaseKeys(obj, snakeCase);
}

function changeCaseKeys(value: any, format: (key: string) => string): any {
  if (Array.isArray(value)) {
    return value.map((item) => changeCaseKeys(item, format));
  }
  if (value != null && typeof value === 'object') {
    return Object.keys(value).reduce((memo, key) => {
      const formattedKey = format(key);
      memo[formattedKey] = changeCaseKeys(value[key], format);
      return memo;
    }, {} as object);
  }
  return value;
}
