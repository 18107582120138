<div class="step" *ngIf="offer" id="next-steps">
  <section class="section">
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-8 offset-sm-2 text-center section-body pb-4">
          <h1>Your home sale is almost official.</h1>
          <h2>Ready to move forward?</h2>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-12 col-md-8 offset-md-2">
          <div class="row next-step-step mb-4 no-gutters">
            <div class="col-md-2 text-md-center text-left">
              <app-step-icon [step]="1"></app-step-icon>
            </div>
            <div class="col-md-10 mt-3">
              <div class="row no-gutters">
                <div class="col-md-12">
                  <h4 class="mb-md-3">1. Get in touch</h4>
                </div>
              </div>
              <div class="row no-gutters">
                <div class="col-md-12">
                  <p>
                    Let our team know you're ready to finalize the details. You can reply directly to your offer
                    email or send us a note to <a href="mailto:support@orchard.com">support@orchard.com</a>.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="row next-step-step mb-4 no-gutters">
            <div class="col-md-2 text-md-center text-left">
              <app-step-icon [step]="2"></app-step-icon>
            </div>
            <div class="col-md-10 mt-3">
              <div class="row no-gutters">
                <div class="col-md-12">
                  <h4 class="mb-md-3">2. Confirm the purchase price</h4>
                </div>
              </div>
              <div class="row no-gutters">
                <div class="col-md-12">
                  <p>
                    We'll email you an amendment to our purchase agreement reflecting the updated purchase
                    price. Review and sign and you'll be on your way to closing on the day of your choice.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="row next-step-step mb-4 no-gutters">
            <div class="col-md-2 text-md-center text-left">
              <app-step-icon [step]="3"></app-step-icon>
            </div>
            <div class="col-md-10 mt-3">
              <div class="row no-gutters">
                <div class="col-md-12">
                  <h4 class="mb-md-3">3. Start the closing process</h4>
                </div>
              </div>
              <div class="row no-gutters">
                <div class="col-md-12">
                  <p>
                    Your Customer Experience Manager will reach out to let you
                    know what to prepare to ensure a smooth and on-time closing.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="section section-bg-secondary">
    <div class="container-fluid">
      <div class="col-sm-10 offset-sm-1 text-center section-body">
        <p class="sub-heading sentence text-center">Have questions?</p>
        <div class="row justify-content-center">
          <div class="col-md-8">
            <p class="lead">If you'd like more information about your offer or next steps, drop us a line at
              <a href="mailto:support@orchard.com">support@orchard.com</a> to reach our Customer Experience team.</p>
          </div>
        </div>
      </div>
    </div>
  </section>

  <div class="steps-footer">
    <div class="container-fluid">
      <a class="btn btn-sm btn-outline-primary" [routerLink]="['/offer/' + offer.token + '/offer']">Previous Step</a>
    </div>
  </div>
</div>
