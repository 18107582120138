/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./willow-disclaimer.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./willow-disclaimer";
var styles_WillowDisclaimerComponent = [i0.styles];
var RenderType_WillowDisclaimerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_WillowDisclaimerComponent, data: {} });
export { RenderType_WillowDisclaimerComponent as RenderType_WillowDisclaimerComponent };
export function View_WillowDisclaimerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "section", [["class", "disclaimer"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(2, 0, null, null, 9, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(4, 0, null, null, 6, "div", [["class", "row justify-content-center text-center small text-muted"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(6, 0, null, null, 3, "div", [["class", "col-sm-9 col-md-6"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), i1.ɵncd(null, 0), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], null, null); }
export function View_WillowDisclaimerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "willow-disclaimer", [], null, null, null, View_WillowDisclaimerComponent_0, RenderType_WillowDisclaimerComponent)), i1.ɵdid(1, 49152, null, 0, i2.WillowDisclaimerComponent, [], null, null)], null, null); }
var WillowDisclaimerComponentNgFactory = i1.ɵccf("willow-disclaimer", i2.WillowDisclaimerComponent, View_WillowDisclaimerComponent_Host_0, {}, {}, ["*"]);
export { WillowDisclaimerComponentNgFactory as WillowDisclaimerComponentNgFactory };
