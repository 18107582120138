/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./bathroom-details.component";
var styles_BathroomDetailsComponent = [];
var RenderType_BathroomDetailsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_BathroomDetailsComponent, data: {} });
export { RenderType_BathroomDetailsComponent as RenderType_BathroomDetailsComponent };
function View_BathroomDetailsComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", " full"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.full; _ck(_v, 1, 0, currVal_0); }); }
function View_BathroomDetailsComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [", "]))], null, null); }
function View_BathroomDetailsComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", " half"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.half; _ck(_v, 1, 0, currVal_0); }); }
function View_BathroomDetailsComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 10, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n  "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_BathroomDetailsComponent_2)), i0.ɵdid(3, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n  "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_BathroomDetailsComponent_3)), i0.ɵdid(6, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n  "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_BathroomDetailsComponent_4)), i0.ɵdid(9, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !!_co.full; _ck(_v, 3, 0, currVal_0); var currVal_1 = (!!_co.full && !!_co.half); _ck(_v, 6, 0, currVal_1); var currVal_2 = !!_co.half; _ck(_v, 9, 0, currVal_2); }, null); }
function View_BathroomDetailsComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(0, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.fallback; _ck(_v, 0, 0, currVal_0); }); }
export function View_BathroomDetailsComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_BathroomDetailsComponent_1)), i0.ɵdid(1, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\n"])), (_l()(), i0.ɵand(0, [["fallbackTemplate", 2]], null, 0, null, View_BathroomDetailsComponent_5)), (_l()(), i0.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.showFallback; var currVal_1 = i0.ɵnov(_v, 3); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_BathroomDetailsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-bathroom-details", [], null, null, null, View_BathroomDetailsComponent_0, RenderType_BathroomDetailsComponent)), i0.ɵdid(1, 49152, null, 0, i2.BathroomDetailsComponent, [], null, null)], null, null); }
var BathroomDetailsComponentNgFactory = i0.ɵccf("app-bathroom-details", i2.BathroomDetailsComponent, View_BathroomDetailsComponent_Host_0, { half: "half", full: "full", fallback: "fallback" }, {}, []);
export { BathroomDetailsComponentNgFactory as BathroomDetailsComponentNgFactory };
