import { ActivatedRouteSnapshot, Resolve, Router, Routes } from '@angular/router';
import { EstimatesService, OffersService } from 'app/providers';
import { EstimateDetailsWrapperComponent } from './estimates/estimate-details-wrapper';
import { EstimateIntroTabComponent } from './estimates/intro-tab/intro-tab.component';
import { EstimateNotFoundComponent } from './estimates/not-found/not-found.component';
import { GspOfferComponent, GspOfferCoverSheetComponent, GspOfferNotFoundComponent } from './offer/components';
import * as i0 from "@angular/core";
import * as i1 from "../../providers/offers.service";
import * as i2 from "@angular/router";
import * as i3 from "../../providers/estimates.service";
var OfferResolve = /** @class */ (function () {
    function OfferResolve(offerService) {
        this.offerService = offerService;
    }
    OfferResolve.prototype.resolve = function (route) {
        return this.offerService.getOfferData(route.params.offerToken);
    };
    OfferResolve.ngInjectableDef = i0.defineInjectable({ factory: function OfferResolve_Factory() { return new OfferResolve(i0.inject(i1.OffersService)); }, token: OfferResolve, providedIn: "root" });
    return OfferResolve;
}());
export { OfferResolve };
var WillowOfferNotFoundResolve = /** @class */ (function () {
    function WillowOfferNotFoundResolve(router) {
        this.router = router;
    }
    WillowOfferNotFoundResolve.prototype.resolve = function (route) {
        if (!route.parent.data.offer.expired) {
            return true;
        }
        else {
            this.router.navigate(['/gsp-offer', route.params.offerToken, 'not-found']);
            return false;
        }
    };
    WillowOfferNotFoundResolve.ngInjectableDef = i0.defineInjectable({ factory: function WillowOfferNotFoundResolve_Factory() { return new WillowOfferNotFoundResolve(i0.inject(i2.Router)); }, token: WillowOfferNotFoundResolve, providedIn: "root" });
    return WillowOfferNotFoundResolve;
}());
export { WillowOfferNotFoundResolve };
var EstimateResolve = /** @class */ (function () {
    function EstimateResolve(estimatesService) {
        this.estimatesService = estimatesService;
    }
    EstimateResolve.prototype.resolve = function (route) {
        return this.estimatesService.getEstimateData(route.params.estimateId);
    };
    EstimateResolve.ngInjectableDef = i0.defineInjectable({ factory: function EstimateResolve_Factory() { return new EstimateResolve(i0.inject(i3.EstimatesService)); }, token: EstimateResolve, providedIn: "root" });
    return EstimateResolve;
}());
export { EstimateResolve };
var NotFoundEstimateResolve = /** @class */ (function () {
    function NotFoundEstimateResolve(router) {
        this.router = router;
    }
    NotFoundEstimateResolve.prototype.resolve = function (route) {
        if (!route.parent.data.estimate.expired) {
            return true;
        }
        else {
            this.router.navigate(['/estimate', route.params.estimateId, 'not-found']);
            return false;
        }
    };
    NotFoundEstimateResolve.ngInjectableDef = i0.defineInjectable({ factory: function NotFoundEstimateResolve_Factory() { return new NotFoundEstimateResolve(i0.inject(i2.Router)); }, token: NotFoundEstimateResolve, providedIn: "root" });
    return NotFoundEstimateResolve;
}());
export { NotFoundEstimateResolve };
var routes = [
    // It's going to be difficult to remove this deprecated route's redirect, because we've sent thousands of emails
    // that include this route. Even ancient offer pack links should successfully take the user to an expired offer
    // experience. Also consider that our data-driven email campaigns managed in external providers generate URLs
    // with this route prefix, and they'd all need to be updated for new emails.
    {
        path: 'buy-and-sell-offer/:offerToken',
        redirectTo: 'gsp-offer/:offerToken',
    },
    {
        path: 'gsp-offer/:offerToken',
        resolve: { offer: OfferResolve },
        children: [
            {
                path: 'intro',
                component: GspOfferCoverSheetComponent,
                resolve: { expired: WillowOfferNotFoundResolve }
            },
            {
                path: 'details',
                component: GspOfferComponent,
                resolve: { expired: WillowOfferNotFoundResolve }
            },
            {
                path: 'not-found',
                component: GspOfferNotFoundComponent
            },
            {
                path: '',
                redirectTo: 'intro',
                pathMatch: 'full'
            }
        ]
    },
    {
        path: 'estimate/:estimateId',
        resolve: { estimate: EstimateResolve },
        children: [
            {
                path: 'intro-tab',
                component: EstimateIntroTabComponent,
                resolve: { expired: NotFoundEstimateResolve }
            },
            {
                path: 'details',
                component: EstimateDetailsWrapperComponent,
                resolve: { expired: NotFoundEstimateResolve }
            },
            {
                path: 'not-found',
                component: EstimateNotFoundComponent
            },
            {
                path: '',
                pathMatch: 'full',
                redirectTo: 'intro-tab'
            },
        ]
    }
];
var WillowRoutingModule = /** @class */ (function () {
    function WillowRoutingModule() {
    }
    return WillowRoutingModule;
}());
export { WillowRoutingModule };
