<a class="brand d-sm-none"><img src="https://res.cloudinary.com/dmxgostd9/image/upload/web-assets/orchard-logo-dark.svg"></a>

<div class="step step-1" *ngIf="offer">
  <section class="section">
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-8 offset-sm-2 text-center section-body">
          <div class="offer-meta">
            <p class="sub-heading label">Orchard Offer</p>
            <p id="address" class="lead address">
              {{offer.subjectProperty?.address.streetAddress}},
              <span class="d-block">
                {{offer.subjectProperty?.address.city}},
                {{offer.subjectProperty?.address.state}}
                {{offer.subjectProperty?.address.zipcode}}
              </span>
            </p>
            <p class="stat-label text-muted">
              <app-valid-thru [offer]="offer"></app-valid-thru>
            </p>
          </div>

          <div class="offer-description" [ngSwitch]="offer.type">
            <ng-container *ngSwitchCase="OfferType.NET_OF_REPAIRS">
              <h1>Your revised offer is ready</h1>
              <p class="lead">Thanks for welcoming us into your home to take a closer look. This offer is updated to reflect the findings from our walkthrough and assessment.</p>
            </ng-container>
            <ng-container *ngSwitchDefault>
              <h1>We’re excited to present you with<div class="d-none d-xl-block"></div> an offer to purchase <span class="d-inline-block">your home</span></h1>
              <p class="lead">We considered a number of factors including: your local housing market, the unique characteristics of your home, and recently sold homes in <span class="d-inline-block">your area</span>.</p>
            </ng-container>
          </div>

          <div class="cta">
            <a *ngIf="!offer?.marketAnalysis" class="btn btn-sm btn-primary" [routerLink]="['/offer/' + offer.token + '/comps']">Next Step</a>
            <a *ngIf="offer?.marketAnalysis" class="btn btn-sm btn-primary" [routerLink]="['/offer/' + offer.token + '/market-analysis']">Next Step</a>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
