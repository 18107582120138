<svg width="72px" height="72px" viewBox="0 0 72 72" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
  <title>step 3</title>
  <defs></defs>
  <g id="step-3" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <g id="Page-1" transform="translate(12.000000, 15.000000)" fill="#75968A">
      <path d="M10,17 L8,17 L8,5 C8,4.448 8.448,4 9,4 L15,4 C15.552,4 16,4.448 16,5 L16,11 L14,11 L14,6 L10,6 L10,17 Z" id="Fill-1"></path>
      <path d="M42,29 C41.744,29 41.488,28.902 41.292,28.708 L24,11.414 L6.708,28.708 C6.316,29.098 5.684,29.098 5.292,28.708 L1.292,24.708 C0.902,24.316 0.902,23.684 1.292,23.292 L23.292,1.292 C23.684,0.902 24.316,0.902 24.708,1.292 L46.708,23.292 C47.098,23.684 47.098,24.316 46.708,24.708 L42.708,28.708 C42.512,28.902 42.256,29 42,29 M24,9 C24.256,9 24.512,9.098 24.708,9.292 L42,26.586 L44.586,24 L24,3.414 L3.414,24 L6,26.586 L23.292,9.292 C23.488,9.098 23.744,9 24,9"
        id="Fill-3"></path>
      <path d="M41,43 L6,43 C5.448,43 5,42.552 5,42 L5,27 L7,27 L7,41 L40,41 L40,27 L42,27 L42,42 C42,42.552 41.552,43 41,43" id="Fill-6"></path>
      <path d="M21,43 L13,43 C12.448,43 12,42.552 12,42 L12,27 C12,26.448 12.448,26 13,26 L21,26 C21.552,26 22,26.448 22,27 L22,42 C22,42.552 21.552,43 21,43 Z M14,41 L20,41 L20,28 L14,28 L14,41 Z"
        id="Fill-8"></path>
      <path d="M35,36 L25,36 C24.448,36 24,35.552 24,35 L24,27 C24,26.448 24.448,26 25,26 L35,26 C35.552,26 36,26.448 36,27 L36,35 C36,35.552 35.552,36 35,36 Z M26,34 L34,34 L34,28 L26,28 L26,34 Z"
        id="Fill-10"></path>
      <polygon id="Fill-13" points="0 43 48 43 48 41 0 41"></polygon>
    </g>
  </g>
</svg>