import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-estimate-progress-bar',
  templateUrl: './estimate-progress-bar.component.html',
  styleUrls: ['./estimate-progress-bar.component.scss']
})
export class EstimateProgressBarComponent {
  @Input() isListWithBackstop?: boolean;
}
