/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./logo.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./logo";
var styles_PerchLogoComponent = [i0.styles];
var RenderType_PerchLogoComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PerchLogoComponent, data: {} });
export { RenderType_PerchLogoComponent as RenderType_PerchLogoComponent };
export function View_PerchLogoComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(1, 0, null, null, 3, "a", [["class", "brand"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(3, 0, null, null, 0, "img", [["src", "https://res.cloudinary.com/dmxgostd9/image/upload/web-assets/orchard-logo-dark.svg"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵted(-1, null, ["\n  "]))], null, null); }
export function View_PerchLogoComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "perch-logo", [], null, null, null, View_PerchLogoComponent_0, RenderType_PerchLogoComponent)), i1.ɵdid(1, 49152, null, 0, i2.PerchLogoComponent, [], null, null)], null, null); }
var PerchLogoComponentNgFactory = i1.ɵccf("perch-logo", i2.PerchLogoComponent, View_PerchLogoComponent_Host_0, {}, {}, []);
export { PerchLogoComponentNgFactory as PerchLogoComponentNgFactory };
