<div class="step" *ngIf="offer" id="next-steps">
  <section class="section">
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-8 offset-sm-2 text-center section-body pb-4">
          <h1>Ready to accept this offer?</h1>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-12 col-md-8 offset-md-2">

          <div class="row next-step-step mb-4 no-gutters">
            <div class="col-md-2 text-md-center text-left">
              <app-step-icon [step]="1"></app-step-icon>
            </div>
            <div class="col-md-10 mt-3">
              <div class="row no-gutters">
                <div class="col-md-12">
                  <h4 class="mb-md-3">1. Let us know you'd like to accept</h4>
                </div>
              </div>
              <div class="row no-gutters">
                <div class="col-md-12">
                  <p>Just reply to the offer email we sent you or you can send an email to
                    <a href="mailto:support@orchard.com">support@orchard.com</a> and let us know you're interested in accepting the offer.</p>
                </div>
              </div>
            </div>
          </div>

          <div class="row next-step-step mb-4 no-gutters">
            <div class="col-md-2 text-md-center text-left">
              <app-step-icon [step]="2"></app-step-icon>
            </div>
            <div class="col-md-10 mt-3">
              <div class="row no-gutters">
                <div class="col-md-12">
                  <h4 class="mb-md-3">2. Sign the purchase agreement</h4>
                </div>
              </div>
              <div class="row no-gutters">
                <div class="col-md-12">
                  <p>We'll email you a purchase agreement for your review and signature. The agreement outlines the purchase
                    price and Orchard fee that you saw in your original offer.</p>
                </div>
              </div>
            </div>
          </div>

          <div class="row next-step-step mb-4 no-gutters">
            <div class="col-md-2 text-md-center text-left">
              <app-step-icon [step]="3"></app-step-icon>
            </div>
            <div class="col-md-10 mt-3">
              <div class="row no-gutters">
                <div class="col-md-12">
                  <h4 class="mb-md-3">3. We'll schedule a home walkthrough and assessment</h4>
                </div>
              </div>
              <div class="row no-gutters">
                <div class="col-md-12">
                  <p>We will conduct a complimentary Orchard assessment to confirm your home's condition and determine what repairs your home will need. After your Orchard assessment, you'll receive a summary of repairs required and the cost for Orchard to make them after you move. This amount will be deducted from your offer price. You have a few options here: you can accept the updated offer and Orchard will make the repairs after you move, or you can make the repairs yourself. If you do not want to move forward with repairs, you can cancel the sale without any cost or penalty.</p>
                </div>
              </div>
            </div>
          </div>

          <div class="row next-step-step no-gutters">
            <div class="col-md-2 text-md-center text-left">
              <app-step-icon [step]="4"></app-step-icon>
            </div>
            <div class="col-md-10 mt-3">
              <div class="row no-gutters">
                <div class="col-md-12">
                  <h4 class="mb-md-3">4. Close</h4>
                </div>
              </div>
              <div class="row no-gutters">
                <div class="col-md-12">
                  <p>Choose your closing date. Your Customer Experience Manager will work with you to schedule a date that works for your timeline.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-8 offset-md-2 pt-5">
          <p>If you're also buying your next home with Orchard, you'll be introduced to your Orchard Home Advisor to start your search.</p>
        </div>
      </div>
    </div>
  </section>

  <section class="section section-bg-secondary">
    <div class="container-fluid">
      <div class="col-sm-10 offset-sm-1 text-center section-body">
        <p class="sub-heading sentence text-center">Have questions?</p>
        <div class="row justify-content-center">
          <div class="col-md-8">
            <p class="lead">If you'd like more information about your offer or next steps, drop us a line at
              <a href="mailto:support@orchard.com">support@orchard.com</a> to reach our Customer Experience team.</p>
          </div>
        </div>
      </div>
    </div>
  </section>

  <div class="steps-footer">
    <div class="container-fluid">
      <a class="btn btn-sm btn-outline-primary" [routerLink]="['/offer/' + offer.token + '/offer']">Previous Step</a>
    </div>
  </div>
</div>
