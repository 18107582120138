/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../willow-layout/willow-layout.ngfactory";
import * as i2 from "../../willow-layout/willow-layout";
import * as i3 from "../../../icons/letter-dotted/letter-dotted.ngfactory";
import * as i4 from "../../../icons/letter-dotted/letter-dotted";
import * as i5 from "./not-found.component";
import * as i6 from "@angular/platform-browser";
import * as i7 from "@angular/router";
var styles_EstimateNotFoundComponent = [];
var RenderType_EstimateNotFoundComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_EstimateNotFoundComponent, data: {} });
export { RenderType_EstimateNotFoundComponent as RenderType_EstimateNotFoundComponent };
export function View_EstimateNotFoundComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 14, "willow-layout", [], null, null, null, i1.View_WillowLayoutComponent_0, i1.RenderType_WillowLayoutComponent)), i0.ɵdid(1, 49152, null, 0, i2.WillowLayoutComponent, [], null, null), (_l()(), i0.ɵted(-1, 2, ["\n  "])), (_l()(), i0.ɵeld(3, 0, null, 0, 1, "perch-icons-letter-dotted", [["role", "icon"]], null, null, null, i3.View_IconLetterDotted_0, i3.RenderType_IconLetterDotted)), i0.ɵdid(4, 49152, null, 0, i4.IconLetterDotted, [], null, null), (_l()(), i0.ɵted(-1, 2, ["\n  "])), (_l()(), i0.ɵeld(6, 0, null, 1, 1, "div", [["role", "header"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Estimate not found"])), (_l()(), i0.ɵted(-1, 2, ["\n  "])), (_l()(), i0.ɵeld(9, 0, null, 2, 4, "div", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n    This home sale estimate is no longer valid.\n    If you haven\u2019t received a new estimate, contact our Customer Experience Team at\n    "])), (_l()(), i0.ɵeld(11, 0, null, null, 1, "a", [["href", "mailto:support@orchard.com"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["support@orchard.com"])), (_l()(), i0.ɵted(-1, null, [".\n  "])), (_l()(), i0.ɵted(-1, 2, ["\n"])), (_l()(), i0.ɵted(-1, null, ["\n"]))], null, null); }
export function View_EstimateNotFoundComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-estimate-not-found", [], null, null, null, View_EstimateNotFoundComponent_0, RenderType_EstimateNotFoundComponent)), i0.ɵdid(1, 114688, null, 0, i5.EstimateNotFoundComponent, [i6.Title, i7.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EstimateNotFoundComponentNgFactory = i0.ɵccf("app-estimate-not-found", i5.EstimateNotFoundComponent, View_EstimateNotFoundComponent_Host_0, {}, {}, []);
export { EstimateNotFoundComponentNgFactory as EstimateNotFoundComponentNgFactory };
