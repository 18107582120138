import { Component, Input } from '@angular/core';
import { Address } from 'models';

@Component({
  selector: 'address',
  templateUrl: './address.html',
  styleUrls: ['./address.scss']
})
export class AddressComponent {
  @Input() address?: Address;
}
