import { Router } from '@angular/router';
var HomeDetailsComponent = /** @class */ (function () {
    function HomeDetailsComponent(router) {
        this.router = router;
    }
    Object.defineProperty(HomeDetailsComponent.prototype, "offer", {
        get: function () {
            return this._offer;
        },
        set: function (val) {
            if (val.valueAdjustments == null) {
                this.redirectToOfferStep(val.token);
                return;
            }
            this._offer = val;
        },
        enumerable: true,
        configurable: true
    });
    HomeDetailsComponent.prototype.redirectToOfferStep = function (token) {
        this.router.navigate(['offer', token, 'offer']);
    };
    return HomeDetailsComponent;
}());
export { HomeDetailsComponent };
