import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'perch-icons-baths',
  templateUrl: './baths.component.html'
})
export class Baths implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
