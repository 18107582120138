import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-bathroom-details',
  templateUrl: './bathroom-details.component.html',
})
export class BathroomDetailsComponent {

  @Input()
  half: number;

  @Input()
  full: number;

  @Input()
  fallback = '–';

  get showFallback(): boolean {
    return !this.half && !this.full;
  }

}
