import { OnInit } from '@angular/core';
import { HeapAnalyticsService } from "app/providers";
import { HEAP_CROSS_PROMOTE_EXPERIMENT_NAME, TEST_NAME } from "./constants";
var OfferDetailsComponent = /** @class */ (function () {
    function OfferDetailsComponent(heapAnalyticsService) {
        this.heapAnalyticsService = heapAnalyticsService;
    }
    OfferDetailsComponent.prototype.ngOnInit = function () {
        this.setTestGroup();
        this.reportTestGroup();
    };
    Object.defineProperty(OfferDetailsComponent.prototype, "previousStep", {
        get: function () {
            return this.offer.valueAdjustments != null ? 'home-details' : 'comps';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OfferDetailsComponent.prototype, "isTexas", {
        get: function () {
            return this.offer.subjectProperty.address.state === 'TX';
        },
        enumerable: true,
        configurable: true
    });
    OfferDetailsComponent.prototype.setTestGroup = function () {
        if (this.offer.testGroup == null || this.offer.testGroup.testName !== TEST_NAME) {
            this.testGroup = undefined;
            return;
        }
        this.testGroup = this.offer.testGroup;
    };
    OfferDetailsComponent.prototype.reportTestGroup = function () {
        if (this.testGroup != null) {
            this.heapAnalyticsService.track(HEAP_CROSS_PROMOTE_EXPERIMENT_NAME, { 'group': this.testGroup.name });
        }
    };
    return OfferDetailsComponent;
}());
export { OfferDetailsComponent };
