import { ContentChild, Input, Component } from '@angular/core';

import { PopoverInfoLabelComponent } from './popover-info-label.component';

type PopoverPlacement =
  | 'top'
  | 'right'
  | 'left'
  | 'bottom'
  | 'auto'
  ;

type PopoverColor = 'pink' | 'light';


type PopoverSize = 'sm' | 'md' | 'lg';

@Component({
  selector: 'popover-info',
  templateUrl: './popover-info.html',
  styleUrls: ['./popover-info.scss']
})
export class InfoPopoverComponent {
  @ContentChild(PopoverInfoLabelComponent) label?: PopoverInfoLabelComponent;

  @Input() placement: PopoverPlacement = 'auto';
  @Input() iconBgColor: PopoverColor = 'pink';
  @Input() iconSize: PopoverSize = 'sm';
}
