<section class="section">
  <div class="container">
    <div class="row">
      <header class="col-12">
        <h1 class="text-h3">Your recommended list price is:</h1>
        <div id="suggested-list-price" class="text-h1">
          <span>
            <sup class="dollar text-h3">&#36;</sup>{{offer.offerDetails['suggestedListPrice'] | number}}
          </span>
        </div>
      </header>
    </div>
    <div class="row rlp-detail-block">
      <div class="col-md-8 offset-md-2">
        <p class="text-body-lg">
          This reflects our current list price recommendation for your home.
          Prior to listing your home, your Listing Agent will run these numbers again to account for current market conditions.
        </p>
        <p class="text-body-lg">
          You always have the final say on your home’s list price.
        </p>
        <p class="text-body">
          Your Orchard guaranteed offer is <span id="guaranteed-sale-price">{{offer.amount | currency:'USD':'symbol-narrow':'1.0-0'}}</span>.
          Almost all Orchard customers sell their home for more than their guaranteed offer amount.
        </p>
        <p class="text-body">
          This offer price is valid through {{offer.expirationDate | date:'MM.dd.yyyy'}}.
        </p>
      </div>
    </div>
  </div>
</section>

<section id="control-timeline">
  <div class="container">
    <div class="row">
      <header class="col-12">
        <h2 class="text-h3">Our approach to selling homes</h2>
      </header>
    </div>
    <div class="row">
      <div class="col-md-7 col-12 content-container">
        <div class="timeline-section">
          <h4 class="timeline-subheader">Stress-free home prep</h4>
          <p class="text-body">We provide free professional photos and a 3D tour to help your home stand out and can even make value-boosting repairs at no upfront cost.</p>
        </div>
      </div>
      <div class="illustration-container col-md-5 col-12" >
        <img
          src="https://res.cloudinary.com/dmxgostd9/image/upload/v1664198457/web-illustrations/spot-paint-variant.png"
        >
      </div>
    </div>
    <div class="row">
      <div class="col-md-7 col-12 content-container">
        <div class="timeline-section">
          <h4 class="timeline-subheader">Listing with a guaranteed offer</h4>
          <p class="text-body">You get the best of both worlds: the ability to sell for top dollar while guaranteeing your home sale no matter what.</p>
        </div>
      </div>
      <div class="illustration-container col-md-5 col-12" >
        <img
          src="https://res.cloudinary.com/dmxgostd9/image/upload/v1632843107/web-illustrations/spot-success-v1.png"
        >
      </div>
    </div>
    <div class="row">
      <div class="col-md-7 col-12 content-container">
        <div class="timeline-section">
          <h4 class="timeline-subheader">No pressure, no downside</h4>
          <p class="text-body">There is no obligation to sell to Orchard at any time. If your home doesn’t sell on the market after 120 days, you can sell to us or we can remove your listing at no cost to you.</p>
        </div>
      </div>
      <div class="illustration-container col-md-5 col-12" >
        <img
          src="https://res.cloudinary.com/dmxgostd9/image/upload/v1632510872/web-illustrations/spot-sold.png"
        >
      </div>
    </div>
  </div>
</section>
<section id="costs">
  <div class="container">
    <div class="row">
      <header class="col-12">
        <h2 class="text-h3">Costs with Orchard</h2>
        <p class="text-body">
          No Hidden Fees
        </p>
      </header>
    </div>
    <div class="row">
      <div class="col-md-10 offset-md-1">
        <ul>
          <li class="text-body-lg">5.5% - 6% brokerage fee to list your home with Orchard (similar to a traditional agent)</li>
          <li class="text-body-lg">Standard closing costs (typically around 1% of your home's sale price)</li>
        </ul>
      </div>
    </div>
  </div>


</section>
<section id="get-started">
  <div class="container">
    <div class="row">
      <div class="col-md-10 offset-md-1">
        <header>
          <h2 class="text-h3">Ready to get started?</h2>
        </header>
      </div>
    </div>
    <div class="row">
      <div class="col-md-10 offset-md-1">
        <p class="text-body">
          Email us at <a href="mailto:support@orchard.com">support@orchard.com</a> or give us a call at <a href="tel:8448191373">(844) 819-1373</a>. We have a few documents for you to sign, then it’s time to start preparing your home to list.
        </p>
      </div>
    </div>
  </div>
</section>
