/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./address.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./address";
var styles_AddressComponent = [i0.styles];
var RenderType_AddressComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AddressComponent, data: {} });
export { RenderType_AddressComponent as RenderType_AddressComponent };
export function View_AddressComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "span", [["class", "text-label"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["data-testid", "streetAddress"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵted(-1, null, [", "])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵeld(7, 0, null, null, 11, "span", [["class", "d-block text-label"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(9, 0, null, null, 1, "span", [["data-testid", "city"]], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, ["", ""])), (_l()(), i1.ɵted(-1, null, [", "])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(13, 0, null, null, 1, "span", [["data-testid", "state"]], null, null, null, null, null)), (_l()(), i1.ɵted(14, null, ["\n    ", "\n  "])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(16, 0, null, null, 1, "span", [["data-testid", "zipcode"]], null, null, null, null, null)), (_l()(), i1.ɵted(17, null, ["\n    ", "\n  "])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.address.streetAddress; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.address.city; _ck(_v, 10, 0, currVal_1); var currVal_2 = _co.address.state; _ck(_v, 14, 0, currVal_2); var currVal_3 = _co.address.zipcode; _ck(_v, 17, 0, currVal_3); }); }
export function View_AddressComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "address", [], null, null, null, View_AddressComponent_0, RenderType_AddressComponent)), i1.ɵdid(1, 49152, null, 0, i2.AddressComponent, [], null, null)], null, null); }
var AddressComponentNgFactory = i1.ɵccf("address", i2.AddressComponent, View_AddressComponent_Host_0, { address: "address" }, {}, []);
export { AddressComponentNgFactory as AddressComponentNgFactory };
