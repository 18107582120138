import { Component, Input } from '@angular/core';

import { Offer } from 'models';

@Component({
  selector: 'app-next-steps-net-of-repairs',
  templateUrl: './next-steps-net-of-repairs.component.html',
  styleUrls: ['./next-steps-net-of-repairs.component.css']
})
export class NextStepsNetOfRepairsComponent {
  @Input()
  public offer: Offer;

  constructor() {}
}
