import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { AddressModule } from 'address';
import { LoadingModule } from 'app/loading';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { PopoverInfoModule } from 'popover-info';
import { PerchIconsModule } from '../icons';
import { PerchLogoModule } from '../logo';
import { SellOnlyOffersModule } from '../offers';
import { WillowDisclaimerComponent } from './disclaimer/willow-disclaimer';
import {
  EstimateDetailsV2Component,
  EstimateDetailsV3Component,
  EstimateDetailsWrapperComponent,
  EstimateProgressBarComponent
} from './estimates';
import { BuyAndSellOfferComponent } from './buy-and-sell-offer';
import { EstimateDetailsComponent } from './estimates/details/details.component';
import { EstimateIntroTabComponent } from './estimates/intro-tab/intro-tab.component';
import { EstimateNotFoundComponent } from './estimates/not-found/not-found.component';
import { GspOfferComponent, GspOfferCoverSheetComponent, GspOfferNotFoundComponent } from './offer/components';
import { WillowRoutingModule } from './routes.module';
import { WillowLayoutComponent } from './willow-layout/willow-layout';
import { ListWithBackstopOfferComponent } from 'app/components/willow/list-with-backstop/offer/components';


@NgModule({
  declarations: [
    BuyAndSellOfferComponent,
    WillowLayoutComponent,
    GspOfferComponent,
    WillowDisclaimerComponent,
    EstimateIntroTabComponent,
    EstimateDetailsComponent,
    EstimateDetailsWrapperComponent,
    EstimateDetailsV2Component,
    EstimateDetailsV3Component,
    EstimateNotFoundComponent,
    GspOfferNotFoundComponent,
    GspOfferCoverSheetComponent,
    EstimateProgressBarComponent,
    ListWithBackstopOfferComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    PopoverInfoModule,
    PerchIconsModule,
    SellOnlyOffersModule,
    WillowRoutingModule,
    LoadingModule,
    AddressModule,
    PerchLogoModule,
    BrowserAnimationsModule,
    AccordionModule.forRoot()
  ]
})
export class WillowModule {}
