import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ConfigService = /** @class */ (function () {
    function ConfigService(http) {
        this.http = http;
    }
    ConfigService.prototype.load = function () {
        var _this = this;
        return new Promise(function (resolve) {
            _this.http.get(environment.configFile)
                .subscribe(function (config) {
                _this.config = config;
                resolve(config);
            });
        });
    };
    ConfigService.prototype.getConfiguration = function () {
        return this.config;
    };
    ConfigService.ngInjectableDef = i0.defineInjectable({ factory: function ConfigService_Factory() { return new ConfigService(i0.inject(i1.HttpClient)); }, token: ConfigService, providedIn: "root" });
    return ConfigService;
}());
export { ConfigService };
