import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import * as Raven from 'raven-js';
import { AppComponent } from './app.component';
import { SellOnlyOffersModule, WillowModule } from './components';
import { LoadingModule } from './loading';
import { ConfigService } from './providers';
import { PerchOffersRoutingModule } from './routes.module';
import { NotFoundComponent, OfferComponent } from './views';

export class RavenErrorHandler implements ErrorHandler {
  handleError(err: any): void {
    console.error(err);
    Raven.captureException(err);
  }
}

// Load sentry.io
export function LoadSentry(config){
  Raven.config(config.SENTRY_DSN_OFFER_VIEW, {
    environment: config.SENTRY_ENVIRONMENT
  }).install();
}

// Load environment configs
export function ConfigLoader(configService: ConfigService) {
// Note: this factory needs to return a function (that returns a promise)
  return () => configService.load().then(config => {
    // Do stuff after retrieving environment variables
    LoadSentry(config);
  });
}

export function GetConfigs(configService: ConfigService) {
  return configService.getConfiguration();
}

@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent,
    OfferComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    LeafletModule.forRoot(),
    WillowModule,
    SellOnlyOffersModule,
    LoadingModule,
    PerchOffersRoutingModule, // this goes last
  ],
  providers: [
    {
      provide: 'CONFIGS',
      useFactory: GetConfigs,
      deps: [ConfigService]
    },
    {
      provide: APP_INITIALIZER,
      useFactory: ConfigLoader,
      deps: [ConfigService],
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  private static CONFIG: any;
}
