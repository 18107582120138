/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../step-one/step-one.component.ngfactory";
import * as i2 from "../step-one/step-one.component";
import * as i3 from "../step-two/step-two.component.ngfactory";
import * as i4 from "../step-two/step-two.component";
import * as i5 from "../step-three/step-three.component.ngfactory";
import * as i6 from "../step-three/step-three.component";
import * as i7 from "../step-four/step-four.component.ngfactory";
import * as i8 from "../step-four/step-four.component";
import * as i9 from "@angular/common";
import * as i10 from "./step-icon.component";
var styles_StepIconComponent = [];
var RenderType_StepIconComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_StepIconComponent, data: {} });
export { RenderType_StepIconComponent as RenderType_StepIconComponent };
function View_StepIconComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "perch-icons-step-one", [], null, null, null, i1.View_StepOneComponent_0, i1.RenderType_StepOneComponent)), i0.ɵdid(1, 114688, null, 0, i2.StepOneComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_StepIconComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "perch-icons-step-two", [], null, null, null, i3.View_StepTwoComponent_0, i3.RenderType_StepTwoComponent)), i0.ɵdid(1, 114688, null, 0, i4.StepTwoComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_StepIconComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "perch-icons-step-three", [], null, null, null, i5.View_StepThreeComponent_0, i5.RenderType_StepThreeComponent)), i0.ɵdid(1, 114688, null, 0, i6.StepThreeComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_StepIconComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "perch-icons-step-four", [], null, null, null, i7.View_StepFourComponent_0, i7.RenderType_StepFourComponent)), i0.ɵdid(1, 114688, null, 0, i8.StepFourComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_StepIconComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 14, null, null, null, null, null, null, null)), i0.ɵdid(1, 16384, null, 0, i9.NgSwitch, [], { ngSwitch: [0, "ngSwitch"] }, null), (_l()(), i0.ɵted(-1, null, ["\n  "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_StepIconComponent_1)), i0.ɵdid(4, 278528, null, 0, i9.NgSwitchCase, [i0.ViewContainerRef, i0.TemplateRef, i9.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i0.ɵted(-1, null, ["\n  "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_StepIconComponent_2)), i0.ɵdid(7, 278528, null, 0, i9.NgSwitchCase, [i0.ViewContainerRef, i0.TemplateRef, i9.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i0.ɵted(-1, null, ["\n  "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_StepIconComponent_3)), i0.ɵdid(10, 278528, null, 0, i9.NgSwitchCase, [i0.ViewContainerRef, i0.TemplateRef, i9.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i0.ɵted(-1, null, ["\n  "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_StepIconComponent_4)), i0.ɵdid(13, 278528, null, 0, i9.NgSwitchCase, [i0.ViewContainerRef, i0.TemplateRef, i9.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i0.ɵted(-1, null, ["\n"])), (_l()(), i0.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.step; _ck(_v, 1, 0, currVal_0); var currVal_1 = 1; _ck(_v, 4, 0, currVal_1); var currVal_2 = 2; _ck(_v, 7, 0, currVal_2); var currVal_3 = 3; _ck(_v, 10, 0, currVal_3); var currVal_4 = 4; _ck(_v, 13, 0, currVal_4); }, null); }
export function View_StepIconComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-step-icon", [], null, null, null, View_StepIconComponent_0, RenderType_StepIconComponent)), i0.ɵdid(1, 49152, null, 0, i10.StepIconComponent, [], null, null)], null, null); }
var StepIconComponentNgFactory = i0.ɵccf("app-step-icon", i10.StepIconComponent, View_StepIconComponent_Host_0, { step: "step" }, {}, []);
export { StepIconComponentNgFactory as StepIconComponentNgFactory };
