import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'perch-icons-bedrooms',
  templateUrl: './bedrooms.component.html'
})
export class IconBedrooms implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
